import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, IconButton, Link, Tooltip } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import * as Funciones from "../../../global/js/funciones";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AppBarModal from '../../general/AppBarModal';
import BuscarPersona from '../../general/BuscarPersona';
import SemillerosAddRol from './SemillerosAddRol';
import * as Helper from '../Hijos/Helper';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import { useDispatch } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  columna: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    padding: '5px', 
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse', 
  },
  td: {
    border: '1px solid #ddd',
    padding: '8px',         
    textalign: 'left',     
    verticalalign: 'middle',
    height: '100%'           
  },
  th: {
    border: '1px solid #ddd',
    padding: '8px',          
    textalign: 'left',     
    verticalalign: 'middle', 
    height: '100%'             
  },
  
  tr: {
    height: '100%'             
  },
  smallIcon: {
    fontSize: '2rem', 
  },
}));

export default function SemilleroTables({ semi }) {

  const classes = useStyles();

    let {
      nombre,
      solicitud,
      sigla,
      usuario_registro: { primer_nombre, primer_apellido, segundo_apellido },
    } = semi;
  
    return (
      <Table>
        <TableBody>
          <TableRow key={1}>
            <TableCell component="th" scope="row">Solicitante</TableCell>
            <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
          </TableRow>
          <TableRow key={2}>
            <TableCell component="th" scope="row">Nombre del Semillero</TableCell>
            <TableCell align="left">{nombre}</TableCell>
          </TableRow>
          <TableRow key={3}>
            <TableCell component="th" scope="row">Siglas</TableCell>
            <TableCell align="left">{sigla}</TableCell>
          </TableRow>
          <TableRow key={4}>
            <TableCell component="th" scope="row">Estado Actual</TableCell>
            <TableCell align="left">{solicitud.estado_actual.nombre}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  export function DetalleSolicitud({ datos }){
    const classes = useStyles();
    let {
      nombre,
      sigla,
      mision,
      vision,
      objetivo_general,
    } = datos.semillero[0]
    return (
      <Table>
        <TableBody>
          <TableRow key={1}>
            <TableCell component="th" scope="row">Nombre del Grupo de Semillero</TableCell>
            <TableCell align="left">{nombre}</TableCell>
          </TableRow>
          <TableRow key={2}>
            <TableCell component="th" scope="row">Sigla</TableCell>
            <TableCell align="left">{sigla}</TableCell>
          </TableRow>
          <TableRow key={3}>
            <TableCell component="th" scope="row">Objetivo General</TableCell>
            <TableCell align="left">{objetivo_general}</TableCell>
          </TableRow>
          <TableRow key={4}>
            <TableCell component="th" scope="row">Objetivos Especificos</TableCell>
            {datos.objetivos_especificos.map((objetivo, i)=>(
              <TableCell className={classes.columna} component="td" scope="row" key={i}>{objetivo.nombre}</TableCell>
            ))}
          </TableRow>
          <TableRow key={5}>
            <TableCell component="th" scope="row">Misión</TableCell>
            <TableCell align="left">{mision}</TableCell>
          </TableRow>
            <TableRow key={6}>
                <TableCell component="th" scope="row">Visión</TableCell>
                <TableCell align="left">{vision}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    )
  }

  export function DetalleEventos({ datos }){
    const classes = useStyles();
    const btnVer = archivo =>
        <Link href={`${archivo}`} target="_blank">
            <IconButton style={{ color: '#01579b' }} aria-label="delete">
                <VisibilityIcon fontSize="small" />
            </IconButton>
        </Link>
    let {
      anio,
      ambito,
      ciudad,
      departamento,
      fecha_de_fin,
      fecha_de_inicio,
      modalidad_de_participacion,
      nombre,
      adj_evento
    } = datos
    return (
      <Table>
        <TableBody>
          <TableRow key={1}>
            <TableCell component="th" scope="row">Nombre del Evento</TableCell>
            <TableCell align="left">{nombre}</TableCell>
          </TableRow>
          <TableRow key={2}>
            <TableCell component="th" scope="row">Año del Evento</TableCell>
            <TableCell align="left">{anio}</TableCell>
          </TableRow>
          <TableRow key={3}>
            <TableCell component="th" scope="row">Ciudad del Evento</TableCell>
            <TableCell align="left">{ciudad.nombre}</TableCell>
          </TableRow>
          <TableRow key={4}>
              <TableCell component="th" scope="row">Departamento del Evento</TableCell>
              <TableCell align="left">{departamento.nombre}</TableCell>
          </TableRow>
          <TableRow key={5}>
            <TableCell component="th" scope="row">Fecha de Inicio del Evento</TableCell>
            <TableCell align="left">{fecha_de_inicio}</TableCell>
          </TableRow>
          <TableRow key={6}>
            <TableCell component="th" scope="row">Fecha de Fin del Evento</TableCell>
            <TableCell align="left">{fecha_de_fin}</TableCell>
          </TableRow>
          <TableRow key={7}>
            <TableCell component="th" scope="row">Ambito del Evento</TableCell>
            <TableCell align="left">{ambito.nombre}</TableCell>
          </TableRow>
          <TableRow key={8}>
            <TableCell component="th" scope="row">Certificado Evento</TableCell>
            {/* <TableCell align="left">{btnVer(adj_evento)}</TableCell> */}
            {/* <TableCell align="left">{adj_evento && btnVer(adj_evento)}</TableCell> */}
            <TableCell align="left">{adj_evento ? btnVer(adj_evento) : <span>Sin certificado</span>}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  export function DetalleAutores({datos}){
    const classes = useStyles();
    const btnVer = archivo =>
        <Link href={`${archivo}`} target="_blank">
            <IconButton style={{ color: '#01579b' }} aria-label="delete">
                <VisibilityIcon fontSize="small" />
            </IconButton>
        </Link>
    return (
      <Table>
        <TableBody>
          {datos.map((item, i) => {
              return(
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">Participante {i+1}</TableCell>
                    <TableCell align="left">{`${item.participante.primer_nombre} ${item.participante.segundo_nombre}`}</TableCell>
                    <TableCell align="left">{`${item.participante.primer_apellido} ${item.participante.segundo_apellido}`}</TableCell>
                    <TableCell align="left">{`${item.participante.identificacion}`}</TableCell>
                    {item.modalidad_de_participacion != null ? <TableCell align="left">{`${item.modalidad_de_participacion.nombre}`}</TableCell> : ''}
                    {/* <TableCell align="left">{btnVer(item.adj_certificado)}</TableCell> */}
                    <TableCell align="left">{item.adj_certificado ? btnVer(item.adj_certificado) : <span>Sin certificado</span>}</TableCell>
                  </TableRow>
              )
          })}
        </TableBody>
      </Table>
    )
  }

  export function DetalleAutoresTecTecno({datos}){
    const classes = useStyles();
    return (
      <Table>
        <TableBody>
          {datos.map((item, i) => {
              return(
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">Participante {i+1}</TableCell>
                    <TableCell align="left">{`${item.participante.primer_nombre} ${item.participante.segundo_nombre}`}</TableCell>
                    <TableCell align="left">{`${item.participante.primer_apellido} ${item.participante.segundo_apellido}`}</TableCell>
                    <TableCell align="left">{`${item.participante.identificacion}`}</TableCell>
                    {item.rol != null ? <TableCell align="left">{`${item.rol.nombre}`}</TableCell> : ''}
                  </TableRow>
              )
          })}
        </TableBody>
      </Table>
    )
  }

  export function DetallesProyectos({ datos }){
    const classes = useStyles();
    let {
      anio,
      ambito,
      ciudad,
      departamento,
      fecha_de_fin,
      fecha_de_inicio,
      modalidad_de_participacion,
      nombre,
      descripcion_proyecto,
      asignatura_asociada,
      profesor


    } = datos
    // let nombrepropfesor = profesor.primer_nombre + ' ' + profesor.segundo_nombre + ' ' + profesor.primer_apellido + ' ' + profesor.segundo_apellido 
    let nombrepropfesor = profesor?.primer_nombre?.concat(" ", profesor?.segundo_nombre, " ", profesor?.primer_apellido, " ", profesor?.segundo_apellido);
    console.log(datos);
    return(
      <Table>
        <TableBody>
          <TableRow key={1}>
            <TableCell component="th" scope="row">Nombre del Proyecto</TableCell>
            <TableCell align="left">{nombre}</TableCell>
          </TableRow>
          <TableRow key={2}>
            <TableCell component="th" scope="row">Descripción del Proyecto</TableCell>
            <TableCell align="left">{descripcion_proyecto}</TableCell>
          </TableRow>
          <TableRow key={3}>
            <TableCell component="th" scope="row">Nombre de asignatura o área de conocimiento</TableCell>
            {/* <TableCell align="left">{asignatura_asociada}</TableCell> */}
            <TableCell align="left">{asignatura_asociada ? asignatura_asociada : <span>Sin asignar</span>}</TableCell>
          </TableRow>
          <TableRow key={4}>
            <TableCell component="th" scope="row">Profesor Asesor del Proyecto</TableCell>
            {/* <TableCell align="left">{nombrepropfesor}</TableCell> */}
            <TableCell align="left">{nombrepropfesor ? nombrepropfesor : <span>Sin asignar</span>}</TableCell>
          </TableRow>
          <TableRow key={5}>
            <TableCell component="th" scope="row">Fecha de Inicio</TableCell>
            <TableCell align="left">{fecha_de_inicio}</TableCell>
          </TableRow>
          <TableRow key={6}>
            <TableCell component="th" scope="row">Fecha de Fin</TableCell>
            <TableCell align="left">{fecha_de_fin}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  export function DetallesTecYTecno({datos, producto}){
    if(producto == "Inves_tipo_tec_pro_diseñoIndustrial" || producto == "Inves_tipo_tec_pro_software" || producto == "Inves_tipo_tec_pro_prototipo"
    || producto == "Inves_tipo_tec_pro_signo_distintivo" || producto == "Inves_tipo_tec_pro_empBaseTec" || producto == "Inves_tipo_tec_pro_innProProSer"
    || producto == "Inves_tipo_tec_pro_innGenGesEmp" ||  producto == "Inves_tipo_tec_pro_obrPro" || producto == "Inves_tipo_tec_pro_eveArt" 
    || producto == "Inves_tipo_tec_pro_talCre" || producto == "Inves_tipo_tec_pro_empCreaCul"){
      return(
        <Table>
          <TableBody>
            {datos.map((item, i)=>{
              if(item.valor == null && item.valorSelect == null){
                let anio = parseInt(moment(item.valorFecha).format('YYYY'))
                let mes = parseInt(moment(item.valorFecha).format('MM'))-1
                let dia = parseInt(moment(item.valorFecha).format('DD'))+1
                let fecha = new Date(anio, mes, dia)
                return(
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">{item.label}</TableCell>
                    <TableCell align="left">{moment(fecha).format('YYYY-MM-DD')}</TableCell>
                  </TableRow>
                )
              }else if(item.valor == null){
                return(
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">{item.valorSelect.valora}</TableCell>
                    <TableCell align="left">{item.valorSelect.nombre}</TableCell>
                  </TableRow>
                )
              }
              else{
                return(
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">{item.label}</TableCell>
                    {item.nombre == "fechaObtencionRegistroSoftware" || item.nombre == "fechaRegistroDiseñoPrototipoTiene" || item.nombre == "fechaFinalEventoArtistico" || item.nombre == "fechaFinalTalleresCreacion" ? <TableCell align="left">{moment(item.valor).format('YYYY-MM-DD')}</TableCell> 
                    : <TableCell align="left">{item.valor}</TableCell>}
                  </TableRow>
                )
              }
            })}
          </TableBody>
        </Table>
      )  
    }
  }

  export function DetallesProduBiblio({datos}){
    const classes = useStyles();
    if(datos.tipo_de_producto.codigo == "Inves_tipo_biblio_libro"){
      if(datos.tipo_libro.codigo == "Inves_tipo_libro_otro"){
        let {
          anio,
          editorial,
          idioma,
          isbn,
          no_edicion,
          nombre,
          pais,
          tipo_libro,
          tipo_libro_otro
        } = datos

        return(
          <Table>
            <TableBody>
              <TableRow key={1}>
                <TableCell component="th" scope="row">Nombre del Libro</TableCell>
                <TableCell align="left">{nombre}</TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell component="th" scope="row">Año del Libro</TableCell>
                <TableCell align="left">{anio}</TableCell>
              </TableRow>
              <TableRow key={3}>
                <TableCell component="th" scope="row">Idioma del Libro</TableCell>
                <TableCell align="left">{idioma.nombre}</TableCell>
              </TableRow>
              <TableRow key={4}>
                <TableCell component="th" scope="row">ISBN del Libro</TableCell>
                <TableCell align="left">{isbn}</TableCell>
              </TableRow>
              <TableRow key={5}>
                <TableCell component="th" scope="row">No. de Edicion</TableCell>
                <TableCell align="left">{no_edicion}</TableCell>
              </TableRow>
              <TableRow key={6}>
                <TableCell component="th" scope="row">Editorial del Libro</TableCell>
                <TableCell align="left">{editorial}</TableCell>
              </TableRow>
              <TableRow key={7}>
                <TableCell component="th" scope="row">Pais del Libro</TableCell>
                <TableCell align="left">{pais.nombre}</TableCell>
              </TableRow>
              <TableRow key={8}>
                <TableCell component="th" scope="row">Tipo de Libro</TableCell>
                <TableCell align="left">{tipo_libro.nombre}</TableCell>
              </TableRow>
              <TableRow key={10}>
                <TableCell component="th" scope="row">Tipo de Libro Otro</TableCell>
                <TableCell align="left">{tipo_libro_otro}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )
      }else{
        let {
          anio,
          editorial,
          idioma,
          isbn,
          no_edicion,
          nombre,
          pais,
          tipo_libro,
          tipo_libro_otro,
        } = datos
        
        return(
          <Table>
            <TableBody>
              <TableRow key={1}>
                <TableCell component="th" scope="row">Nombre del Libro</TableCell>
                <TableCell align="left">{nombre}</TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell component="th" scope="row">Año del Libro</TableCell>
                <TableCell align="left">{moment(anio).format('YYYY')}</TableCell>
              </TableRow>
              <TableRow key={3}>
                <TableCell component="th" scope="row">Idioma del Libro</TableCell>
                <TableCell align="left">{idioma.nombre}</TableCell>
              </TableRow>
              <TableRow key={4}>
                <TableCell component="th" scope="row">ISBN del Libro</TableCell>
                <TableCell align="left">{isbn}</TableCell>
              </TableRow>
              <TableRow key={5}>
                <TableCell component="th" scope="row">No. de Edicion</TableCell>
                <TableCell align="left">{no_edicion}</TableCell>
              </TableRow>
              <TableRow key={6}>
                <TableCell component="th" scope="row">Editorial del Libro</TableCell>
                <TableCell align="left">{editorial}</TableCell>
              </TableRow>
              <TableRow key={7}>
                <TableCell component="th" scope="row">Pais del Libro</TableCell>
                <TableCell align="left">{pais.nombre}</TableCell>
              </TableRow>
              <TableRow key={8}>
                <TableCell component="th" scope="row">Tipo de Libro</TableCell>
                <TableCell align="left">{tipo_libro.nombre}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )
      }
    }else if(datos.tipo_de_producto.codigo == "Inves_tipo_biblio_articulo"){
      if(datos.tipo_de_articulo.codigo == "Inves_tipo_articulo_otro"){
        let {
          anio,
          doi,
          enlace,
          idioma,
          indexacion,
          issn,
          no_revista,
          nombre,
          nombre_de_revista,
          numero_de_paginas,
          pais,
          tipo_de_articulo,
          tipo_de_articulo_otro
        } = datos

        return(
          <Table>
            <TableBody>
              <TableRow key={1}>
                <TableCell component="th" scope="row">Nombre del Articulo</TableCell>
                <TableCell align="left">{nombre}</TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell component="th" scope="row">Año del Articulo</TableCell>
                <TableCell align="left">{moment(anio).format('YYYY')}</TableCell>
              </TableRow>
              <TableRow key={3}>
                <TableCell component="th" scope="row">Idioma del Articulo</TableCell>
                <TableCell align="left">{idioma.nombre}</TableCell>
              </TableRow>
              <TableRow key={4}>
                <TableCell component="th" scope="row">Pais del Articulo</TableCell>
                <TableCell align="left">{pais.nombre}</TableCell>
              </TableRow>
              <TableRow key={5}>
                <TableCell component="th" scope="row">DOI del Articulo</TableCell>
                <TableCell align="left">{doi}</TableCell>
              </TableRow>
              <TableRow key={6}>
                <TableCell component="th" scope="row">Enlace del Articulo</TableCell>
                <TableCell align="left">{enlace}</TableCell>
              </TableRow>
              <TableRow key={7}>
                <TableCell component="th" scope="row">Indexacion del Articulo</TableCell>
                <TableCell align="left">{indexacion.nombre}</TableCell>
              </TableRow>
              <TableRow key={8}>
                <TableCell component="th" scope="row">No. de Revista</TableCell>
                <TableCell align="left">{no_revista}</TableCell>
              </TableRow>
              <TableRow key={9}>
                <TableCell component="th" scope="row">ISSN</TableCell>
                <TableCell align="left">{issn}</TableCell>
              </TableRow>
              <TableRow key={10}>
                <TableCell component="th" scope="row">Nombre de la Revista</TableCell>
                <TableCell align="left">{nombre_de_revista}</TableCell>
              </TableRow>
              <TableRow key={11}>
                <TableCell component="th" scope="row">Numero de Paginas</TableCell>
                <TableCell align="left">{numero_de_paginas}</TableCell>
              </TableRow>
              <TableRow key={12}>
                <TableCell component="th" scope="row">Tipo de Articulo</TableCell>
                <TableCell align="left">{tipo_de_articulo.nombre}</TableCell>
              </TableRow>
              <TableRow key={13}>
                <TableCell component="th" scope="row">Tipo de Articulo Otro</TableCell>
                <TableCell align="left">{tipo_de_articulo_otro}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )

      }else{
        let {
          anio,
          doi,
          enlace,
          idioma,
          indexacion,
          issn,
          no_revista,
          nombre,
          nombre_de_revista,
          numero_de_paginas,
          pais,
          tipo_de_articulo
        } = datos

        return(
          <Table>
            <TableBody>
              <TableRow key={1}>
                <TableCell component="th" scope="row">Nombre del Articulo</TableCell>
                <TableCell align="left">{nombre}</TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell component="th" scope="row">Año del Articulo</TableCell>
                <TableCell align="left">{moment(anio).format('YYYY')}</TableCell>
              </TableRow>
              <TableRow key={3}>
                <TableCell component="th" scope="row">Idioma del Articulo</TableCell>
                <TableCell align="left">{idioma.nombre}</TableCell>
              </TableRow>
              <TableRow key={4}>
                <TableCell component="th" scope="row">Pais del Articulo</TableCell>
                <TableCell align="left">{pais.nombre}</TableCell>
              </TableRow>
              <TableRow key={5}>
                <TableCell component="th" scope="row">DOI del Articulo</TableCell>
                <TableCell align="left">{doi}</TableCell>
              </TableRow>
              <TableRow key={6}>
                <TableCell component="th" scope="row">Enlace del Articulo</TableCell>
                <TableCell align="left">{enlace}</TableCell>
              </TableRow>
              <TableRow key={7}>
                <TableCell component="th" scope="row">Indexacion del Articulo</TableCell>
                <TableCell align="left">{indexacion.nombre}</TableCell>
              </TableRow>
              <TableRow key={8}>
                <TableCell component="th" scope="row">No. de Revista</TableCell>
                <TableCell align="left">{no_revista}</TableCell>
              </TableRow>
              <TableRow key={9}>
                <TableCell component="th" scope="row">ISSN</TableCell>
                <TableCell align="left">{issn}</TableCell>
              </TableRow>
              <TableRow key={10}>
                <TableCell component="th" scope="row">Nombre de la Revista</TableCell>
                <TableCell align="left">{nombre_de_revista}</TableCell>
              </TableRow>
              <TableRow key={11}>
                <TableCell component="th" scope="row">Numero de Paginas</TableCell>
                <TableCell align="left">{numero_de_paginas}</TableCell>
              </TableRow>
              <TableRow key={12}>
                <TableCell component="th" scope="row">Tipo de Articulo</TableCell>
                <TableCell align="left">{tipo_de_articulo.nombre}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )
      }
    }else if(datos.tipo_de_producto.codigo == "Inves_tipo_biblio_patente"){
      if(datos.tipo_patente.codigo == "Inves_tipo_patente_otro"){
        let {
          anio,
          gaceta_industrial,
          nombre,
          numero_patente,
          pais,
          tipo_patente,
          tipo_patente_otro,
          institucion
        } = datos

        return(
          <Table>
            <TableBody>
              <TableRow key={1}>
                <TableCell component="th" scope="row">Titulo de la Patente</TableCell>
                <TableCell align="left">{nombre}</TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell component="th" scope="row">Año</TableCell>
                <TableCell align="left">{moment(anio).format('YYYY')}</TableCell>
              </TableRow>
              <TableRow key={3}>
                <TableCell component="th" scope="row">Gaceta Industrial</TableCell>
                <TableCell align="left">{gaceta_industrial}</TableCell>
              </TableRow>
              <TableRow key={4}>
                <TableCell component="th" scope="row">Numero de la Patente</TableCell>
                <TableCell align="left">{numero_patente}</TableCell>
              </TableRow>
              <TableRow key={5}>
                <TableCell component="th" scope="row">Pais</TableCell>
                <TableCell align="left">{pais.nombre}</TableCell>
              </TableRow>
              <TableRow key={8}>
                <TableCell component="th" scope="row">Institución</TableCell>
                <TableCell align="left">{institucion}</TableCell>
              </TableRow>
              <TableRow key={6}>
                <TableCell component="th" scope="row">Tipo de Patente</TableCell>
                <TableCell align="left">{tipo_patente.nombre}</TableCell>
              </TableRow>
              <TableRow key={7}>
                <TableCell component="th" scope="row">Tipo de Patente Otro</TableCell>
                <TableCell align="left">{tipo_patente_otro}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )

      }else{
        let {
          anio,
          gaceta_industrial,
          nombre,
          numero_patente,
          pais,
          tipo_patente,
        } = datos

        return(
          <Table>
            <TableBody>
              <TableRow key={1}>
                <TableCell component="th" scope="row">Nombre de la Patente</TableCell>
                <TableCell align="left">{nombre}</TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell component="th" scope="row">Año</TableCell>
                <TableCell align="left">{moment(anio).format('YYYY')}</TableCell>
              </TableRow>
              <TableRow key={3}>
                <TableCell component="th" scope="row">Gaceta Industrial</TableCell>
                <TableCell align="left">{gaceta_industrial}</TableCell>
              </TableRow>
              <TableRow key={4}>
                <TableCell component="th" scope="row">Numero de la Patente</TableCell>
                <TableCell align="left">{numero_patente}</TableCell>
              </TableRow>
              <TableRow key={5}>
                <TableCell component="th" scope="row">Pais</TableCell>
                <TableCell align="left">{pais.nombre}</TableCell>
              </TableRow>
              <TableRow key={6}>
                <TableCell component="th" scope="row">Tipo de Patente</TableCell>
                <TableCell align="left">{tipo_patente.nombre}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )
      }
    }else if(datos.tipo_de_producto.codigo == "Inves_tipo_biblio_capituloLib"){
      if(datos.tipo_libro.codigo == "Inves_tipo_capLibro_otro"){
        let {
          anio,
          editorial,
          idioma,
          isbn,
          no_edicion,
          nombre,
          pais,
          titulo_del_libro,
          tipo_libro,
          tipo_libro_otro
        } = datos

        return(
          <Table>
            <TableBody>
              <TableRow key={1}>
                <TableCell component="th" scope="row">Tipo de Libro</TableCell>
                <TableCell align="left">{tipo_libro.nombre}</TableCell>
              </TableRow>
              <TableRow key={7}>
                <TableCell component="th" scope="row">Tipo de Libro Otro</TableCell>
                <TableCell align="left">{tipo_libro_otro}</TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell component="th" scope="row">Titulo del Capitulo</TableCell>
                <TableCell align="left">{nombre}</TableCell>
              </TableRow>
              <TableRow key={3}>
                <TableCell component="th" scope="row">Titulo del Libro</TableCell>
                <TableCell align="left">{titulo_del_libro}</TableCell>
              </TableRow>
              <TableRow key={4}>
                <TableCell component="th" scope="row">ISBN</TableCell>
                <TableCell align="left">{isbn}</TableCell>
              </TableRow>
              <TableRow key={5}>
                <TableCell component="th" scope="row">Editorial</TableCell>
                <TableCell align="left">{editorial}</TableCell>
              </TableRow>
              <TableRow key={6}>
                <TableCell component="th" scope="row">Año</TableCell>
                <TableCell align="left">{moment(anio).format('YYYY')}</TableCell>
              </TableRow>
              <TableRow key={8}>
                <TableCell component="th" scope="row">Pais</TableCell>
                <TableCell align="left">{pais.nombre}</TableCell>
              </TableRow>
              <TableRow key={9}>
                <TableCell component="th" scope="row">Idioma</TableCell>
                <TableCell align="left">{idioma.nombre}</TableCell>
              </TableRow>
              <TableRow key={10}>
                <TableCell component="th" scope="row">No. Edicion</TableCell>
                <TableCell align="left">{no_edicion}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )
        
      }else{
        let {
          anio,
          editorial,
          idioma,
          isbn,
          no_edicion,
          nombre,
          pais,
          titulo_del_libro,
          tipo_libro
        } = datos
        return(
          <Table>
            <TableBody>
              <TableRow key={1}>
                <TableCell component="th" scope="row">Tipo de Libro</TableCell>
                <TableCell align="left">{tipo_libro.nombre}</TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell component="th" scope="row">Titulo del Capitulo</TableCell>
                <TableCell align="left">{nombre}</TableCell>
              </TableRow>
              <TableRow key={3}>
                <TableCell component="th" scope="row">Titulo del Libro</TableCell>
                <TableCell align="left">{titulo_del_libro}</TableCell>
              </TableRow>
              <TableRow key={4}>
                <TableCell component="th" scope="row">ISBN</TableCell>
                <TableCell align="left">{isbn}</TableCell>
              </TableRow>
              <TableRow key={5}>
                <TableCell component="th" scope="row">Editorial</TableCell>
                <TableCell align="left">{editorial}</TableCell>
              </TableRow>
              <TableRow key={6}>
                <TableCell component="th" scope="row">Año</TableCell>
                <TableCell align="left">{moment(anio).format('YYYY')}</TableCell>
              </TableRow>
              <TableRow key={8}>
                <TableCell component="th" scope="row">Pais</TableCell>
                <TableCell align="left">{pais.nombre}</TableCell>
              </TableRow>
              <TableRow key={9}>
                <TableCell component="th" scope="row">Idioma</TableCell>
                <TableCell align="left">{idioma.nombre}</TableCell>
              </TableRow>
              <TableRow key={10}>
                <TableCell component="th" scope="row">No. Edicion</TableCell>
                <TableCell align="left">{no_edicion}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )

      }
    }
  }

  export function DetalleSolicitud2Parte({ datos }){
    const classes = useStyles();
    return (
      <Table>
        <TableBody>
          <TableRow key={1}>
            <TableCell component="th" scope="row">Programa y Departamento</TableCell>
            {datos.programa_departamento.map((proDepa, i)=>(
              <TableCell className={classes.columna} component="td" scope="row" key={i}>{proDepa.nombre}</TableCell>
            ))}
          </TableRow>
          <TableRow key={2}>
            <TableCell component="th" scope="row">Linea y Sublinea</TableCell>
            {datos.linea_sublinea.map((linSub, i)=>(
              <TableCell className={classes.columna} component="td" scope="row" key={i}>{linSub.nombre}</TableCell>
            ))}
          </TableRow>
          <TableRow key={3}>
            <TableCell style={{ whiteSpace: 'nowrap' }} component="th" scope="row">Grupo de Investigación Asociado</TableCell>
            {datos.grupos.map((gru, i)=>(
              <TableCell className={classes.columna} component="td" scope="row" key={i}>{gru.nombreGrupo}</TableCell>
            ))}
          </TableRow>
          {/* <TableRow key={3}>
            <TableCell component="th" scope="row">Grupo de Investigación</TableCell>
            <TableCell align="left">{datos.semillero[0].grupo_investigacion.nombre}</TableCell>
          </TableRow> */}
          {/* <TableRow key={4}>
              <TableCell component="th" scope="row">Area de Investigación</TableCell>
              <TableCell align="left">{datos.semillero[0].area_investigacion.nombre}</TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    )
  }

  export function DetalleHistoria({ data }) {
    const classes = useStyles();

    if (!Array.isArray(data)) {
        return <div>No hay datos disponibles</div>;
    }

    return (
      <Table className={classes.table}>
          <TableHead>
              <TableRow aling = "center">
                  <TableCell>Nombre del usuario</TableCell>
                  <TableCell>Datos  que fueron Modificados</TableCell>
                  <TableCell>Fecha de Modificación</TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
              {data.map((item, index) => (
                  <TableRow key={item.id || index}>
                
                      <TableCell align="center">
                          {item.usuario_registro.primer_nombre} {item.usuario_registro.primer_apellido}
                      </TableCell>
                      <TableCell align="center">{item.HistorialActualizaciones}</TableCell>
                      <TableCell align="center">{item.fecha_registro.split("T")[0]}</TableCell>
                  </TableRow>
              ))}
          </TableBody>
      </Table>
  );
  }  

  export function DetalleSolicitud3Parte({ datos,gestor, setOpenModalInfo,setAccion }){
    const [openModalAdd, setOpenModalAdd] = React.useState(false);
    const [rolSelect, setRolSelect] = React.useState([]);
    const [modalRol, setModalRol] = React.useState(false);
    const [objRol, setObjRol] = React.useState({});
    const [liderId, setliderId] = React.useState(0);
    const [encargadoId, setsencargadoId] = React.useState(0);
    const [rol, setRol] = React.useState([]);
    const [errores, setErrores] = React.useState([]);
    let data = {};
    const classes = useStyles();
    const dispatch = useDispatch();
    React.useEffect( () => {
      obtenerRoles()
    }, [])

    const obtenerRoles = async () => {
      const data = await Funciones.obtenerValores(Helper.SEMI_ROLES)
      setRol(data)
    }

    const getError = (llave, errores) => {
      let existe = errores.find(e => e.llave === llave);
      return existe ? existe.mensaje : '';
    }

    const validateInputs = (campos = []) => {
      return new Promise((resolve) => {
        let errores = [];
        function isObjEmpty(obj) {

          if(typeof obj === 'object'){
              for (var prop in obj) {
                  if (obj.hasOwnProperty(prop)) return false;
                }
              
                return true;
          }else if(typeof obj === 'string'){
              return true
          }else{
              return false;
          }
          
      }
        campos.map(({ value, name, err, value2 }) => {
          if (err.empty && isObjEmpty(value) && err.obj){
            errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })
        } 
        });
        
        resolve(errores);
      });
    }

    const ActualizarLider = (id) => {
      const lider = datos.lideres.find(l => l.id === id);
      if (lider.rol.codigo === 'Semi_rol3') {
      setOpenModalAdd(true);
      setliderId(id)
      console.log(lider);
      setsencargadoId(lider.encargado_id)
      } else {
      console.error('Solo se pueden actualizar líderes con el rol Semi_rol3');
      }

    };
  const onClickChange = async () =>{
    if(rolSelect.length == 0){
      let array = await validateInputs([
        { value: rolSelect, name: 'rolSelect', err: { empty: true, obj: true } },
      ])
      setErrores(array)
    }else{
      if (encargadoId === objRol.id) {
        dispatch(
            actualizarMensaje({ 
                titulo: "La persona ya está agregada como líder.", 
                tipo: "info", 
                mostrar: true, 
                tiempo: 6000 
            })
        );
        return;
    }
      data = {
        liderId:liderId,
        liderNuevoId:objRol.id,
        nombre:objRol.nombre,
        rol: rolSelect.codigo,
     }
     let url = "api/v1.0/investigacion/actualizarlider"
     const callback = (error, estado, resp) => {
      let titulo = "Ha ocurrido un error, contacte con el administrador."
      let tipo = "error"

      if (!error) {
          if (estado === 200) {
              
              tipo = "success"
              titulo = resp.titulo
              setModalRol(false);
              setOpenModalAdd(false);
              setOpenModalInfo(false)
              setAccion("Procesos")
              
          } else {
              tipo = "info"
              titulo = resp.titulo
          }
      }

      dispatch(
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
      );
      
  }
  const msg = await Funciones.consulta(url, data, 'POST', callback)
      
    }
  }

    return (
      <Table>
        <TableBody>
          <TableRow key={1}>
            <TableCell component="th" scope="row">Lideres del Semillero</TableCell>
            {datos.lideres.map((lider, i) => (
              console.log('estos son los datos del lider',lider),
              <>
            {lider.rol.codigo !== 'Semi_rol2' && (
            <TableCell className={classes.columna} component="td" scope="row" key={i}>
              {`${lider.nombre} `}<br />{`${lider.rol.nombre} `}<br />{`${lider.encargado_id.correo}`}<br />
              { gestor && (
                <>
                  {`${lider.encargado_id.identificacion}`}<br />{`${lider.encargado_id.telefono ||  ''}`}<br />
                  {datos.programas_de_los_lideres
                  .filter(programa => programa.persona.id === lider.encargado_id.id) 
                  .map((programa, j) => (
                    <>
                    {programa.relacion.nombre || ''}<br />
                      </>
                      ))
                    }
                    </>
                  )}
                  {lider.rol.codigo === 'Semi_rol3' && gestor && (
                    <Tooltip title="Actualizar Lider" arrow>
                    <IconButton aria-label="primary" className={classes.iconButton} onClick={() => ActualizarLider(lider.id)}>
                    <AutorenewIcon className={classes.smallIcon} />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              )}
                <div>
                {openModalAdd == true && (
                  <Dialog  fullWidth={true}  open={openModalAdd} onClose={() => setOpenModalAdd(false)}>
                  <AppBarModal titulo={'¡ Buscar Profesor Lider !'} mostrarModal={() => setOpenModalAdd(false)} />
                  <DialogContent style={{ padding: '0', minHeight: '70vh' }} className='scroll'>
                      <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido, identificacion }) => {
                          setModalRol(true)
                          setObjRol({id: id, nombre:  `${primer_nombre} ${primer_apellido} ${segundo_apellido}`})
                          // props.setProfesor({id: id, nombre:  `${primer_nombre} ${primer_apellido} ${segundo_apellido}`, identificacion: identificacion})
                      }} />
                  </DialogContent>
                  <DialogActions>
                          <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={() => setOpenModalAdd(false)}>
                              Cancelar
                          </Button>
                  </DialogActions>
                  </Dialog>
                )}
                <SemillerosAddRol 
                      modalRol={modalRol}
                      setModalRol={setModalRol}
  
                      rol={rol}
                      setRol={setRol}
                      gestor={gestor}
                      rolSelect={rolSelect}
                      setRolSelect={setRolSelect}
  
                      onClickChange={onClickChange}
  
                      getError={getError}
                      errores={errores}
                      setErrores={setErrores}
  
                      validateInputs={validateInputs}
                  />
                </div>
              </>
            ))}
          </TableRow>
          <TableRow key={2}>
            <TableCell component="th" scope="row">Profesores Asesores</TableCell>
            {datos.lideres.map((lider, i)=>(
               <>
               {lider.rol.codigo == 'Semi_rol2' ? 
               <TableCell className={classes.columna} component="td" scope="row" key={i}>
                {`${lider.nombre}`} <br />  {`${lider.encargado_id.correo}`}
                {gestor && (
                <>
                  <br />{`${lider.encargado_id.identificacion}`}<br />{`${lider.encargado_id.telefono||  ''}`}<br />
                  {datos.programas_de_los_lideres
                  .filter(programa => programa.persona.id === lider.encargado_id.id)
                  .map((programa, j) => (
                    <>
                      {programa.relacion.nombre  || ''}
                    </>
                  ))
                }
                </>
              )}
              </TableCell>
               :''}
               </>
            ))}
          </TableRow>
          </TableBody>
      </Table>
    )
  }

  export function SemilleroTableParticipante({ semi }) {

    const classes = useStyles();
  
      let {
        estado_actual,
        semillero,
        usuario_registro: { primer_nombre, primer_apellido, segundo_apellido },
      } = semi;
      return (
        <Table>
          <TableBody>
            <TableRow key={1}>
              <TableCell component="th" scope="row">Solicitante</TableCell>
              <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
            </TableRow>
            <TableRow key={2}>
              <TableCell component="th" scope="row">Nombre del Semillero</TableCell>
              <TableCell align="left">{semillero.nombre}</TableCell>
            </TableRow>
            <TableRow key={3}>
              <TableCell component="th" scope="row">Siglas</TableCell>
              <TableCell align="left">{semillero.sigla}</TableCell>
            </TableRow>
            <TableRow key={4}>
              <TableCell component="th" scope="row">Estado Actual</TableCell>
              <TableCell align="left">{estado_actual.nombre}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    }