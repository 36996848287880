// Local
// export const OPCIONES_CONOCISTE_INSTITUCION = 6;
// export const CARRERAS = 5;
// export const TIPOS_IDENTIFICACION = 2;
// export const DEPARTAMENTOS = 7;
// export const CIUDADES = 8;
// export const ESTRATOS = 9;
// export const RAZON_ELIGIO_CARRERA = 118;
// export const ESTADO_CIVIL = 121;
// export const TIEMPO_TRABAJO = 122;
// export const RANGO_INGRESOS = 123;
// export const ESTADOS = 124;
// export const MAESTRIA_EDUCACION = 3122;
// export const ESTADO_CIVIL_OTRO = 84;
// export const PAISES = 119;
// export const GENERO = 69;
// export const DISCAPACIDADES_INSCRIPCIONES = 45;
// export const VALIDACION_DOCUMENTOS_INSCRIPCIONES = 48;
// export const EPS = 2152;
// export const EPS_OTRO = 8538;

// export const PERMISOS_PROGRAMAS = 42;
// export const PERMISOS_ESTADOS = 43;

// Producción
export const OPCIONES_CONOCISTE_INSTITUCION = 6;
export const CARRERAS = 5;
export const TIPOS_IDENTIFICACION = 2;
export const DEPARTAMENTOS = 7;
export const CIUDADES = 8;
export const ESTRATOS = 9;
export const RAZON_ELIGIO_CARRERA = 248;
export const ESTADO_CIVIL = 249;
export const TIEMPO_TRABAJO = 250;
export const RANGO_INGRESOS = 215;
export const ESTADOS = 251;
export const MAESTRIA_EDUCACION = 2731;
export const MAESTRIA_GERENCIA_PROYECTOS= 29673;
export const ESTADO_CIVIL_OTRO = 23296;
export const PAISES = 195;
export const GENERO = 69;
export const DISCAPACIDADES_INSCRIPCIONES = 45;
export const VALIDACION_DOCUMENTOS_INSCRIPCIONES = 241;
export const EPS = 267;
export const EPS_OTRO = 24723;

export const PERMISOS_PROGRAMAS = 42;
export const PERMISOS_ESTADOS = 43;

// Emma test
// export const OPCIONES_CONOCISTE_INSTITUCION = 6;
// export const CARRERAS = 5;
// export const TIPOS_IDENTIFICACION = 2;
// export const DEPARTAMENTOS = 7;
// export const CIUDADES = 8;
// export const ESTRATOS = 9;
// export const RAZON_ELIGIO_CARRERA = 5145;
// export const ESTADO_CIVIL = 5146;
// export const ESTADO_CIVIL_OTRO = 6602;
// export const TIEMPO_TRABAJO = 5147;
// export const RANGO_INGRESOS = 4065;
// export const ESTADOS = 5149;
// export const MAESTRIA_EDUCACION = 4650;
// export const PAISES = 4102;
// export const GENERO = 4080;
// export const DISCAPACIDADES_INSCRIPCIONES = 5150;
// export const VALIDACION_DOCUMENTOS_INSCRIPCIONES = 5151;

// export const PERMISOS_PROGRAMAS = 42;
// export const PERMISOS_ESTADOS = 43;
