import React from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import { EPS_OTRO } from "../helper";

export function InscripcionDetalleDatos({ inscripcion }) {
  let {
    estudiante,
    estudiante_11,
    jornada,
    linea_edu,
    nombre_colegio,
    otro_jornada,
    celular1,
    nivel_educacion,
    telefono1,
    fecha_registro,
    estado_actual,
    gestor,
    costear_estudio,
    otro_costear,
    carrera_pre_universitario,
    carrera_pre_universitario_2,
    edad,
    carrera,
  } = inscripcion;
  let fecha = moment(fecha_registro).format("YYYY-MM-DD, h:mm a");
  return (
    <Table>
      <TableBody>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Nombre Completo</TableCell>
          <TableCell align="left">{`${estudiante.primer_nombre.toUpperCase()} ${
            estudiante.segundo_nombre
              ? estudiante.segundo_nombre.toUpperCase()
              : ""
          } ${estudiante.primer_apellido.toUpperCase()} ${
            estudiante.segundo_apellido
              ? estudiante.segundo_apellido.toUpperCase()
              : ""
          }`}</TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell component="th" scope="row">Correo</TableCell>
          <TableCell align="left">{estudiante?.correo ?? ""}</TableCell>
        </TableRow>
        <TableRow key={3}>
          <TableCell component="th" scope="row">Identificación</TableCell>
          <TableCell align="left">{estudiante?.identificacion ?? ""}</TableCell>
        </TableRow>
        {
          edad && (
            <TableRow key={3}>
              <TableCell component="th" scope="row">Edad</TableCell>
              <TableCell align="left">{edad ? edad.toUpperCase() : ""}</TableCell>
            </TableRow>
          )
        }
        <TableRow key={4}>
          <TableCell component="th" scope="row">Telefono - Celular</TableCell>
          <TableCell align="left">{`${telefono1} - ${celular1}`}</TableCell>
        </TableRow>
        <TableRow key={5}>
          <TableCell component="th" scope="row">Estudiante Grado 11</TableCell>
          <TableCell align="left">{estudiante_11?.toUpperCase() ?? ""}</TableCell>
        </TableRow>
        <TableRow key={6}>
          <TableCell component="th" scope="row">Nombre Colegio</TableCell>
          <TableCell align="left">{nombre_colegio?.toUpperCase() ?? ""}</TableCell>
        </TableRow>
        {
          linea_edu && (
            <TableRow key={7}>
              <TableCell component="th" scope="row">Linea EDU.co</TableCell>
              <TableCell align="left">{linea_edu?.nombre ?? ""}</TableCell>
            </TableRow>
          )
        }
        <TableRow key={8}>
          <TableCell component="th" scope="row">Carrera Pre-Seleccionada</TableCell>
          <TableCell align="left">{carrera_pre_universitario?.toUpperCase() ?? ""}</TableCell>
        </TableRow>
        <TableRow key={8}>
          <TableCell component="th" scope="row">Carrera Pre-Seleccionada-2</TableCell>
          <TableCell align="left">
            {carrera_pre_universitario_2
              ? carrera_pre_universitario_2.toUpperCase()
              : ""}
          </TableCell>
        </TableRow>
        {carrera != null && (
          <TableRow key={9}>
            <TableCell component="th" scope="row">Carrera Seleccionada</TableCell>
            <TableCell align="left">{carrera?.nombre.toUpperCase() ?? ""}</TableCell>
          </TableRow>
        )}
        {
          nivel_educacion && (
            <TableRow key={10}>
              <TableCell component="th" scope="row">Nivel Estudio</TableCell>
              <TableCell align="left">{nivel_educacion?.toUpperCase() ?? ""}</TableCell>
            </TableRow>
          )
        }
        {
          jornada && (
            <TableRow key={11}>
              <TableCell component="th" scope="row">Jornada</TableCell>
              <TableCell align="left">{jornada?.nombre ?? ""}</TableCell>
            </TableRow>
          )
        }
        {otro_jornada ? (
          <TableRow key={12}>
            <TableCell component="th" scope="row">Otra Jornada</TableCell>
            <TableCell align="left">{otro_jornada}</TableCell>
          </TableRow>
        ) : (
          <TableRow key={12}></TableRow>
        )}
        {
          costear_estudio && (
            <TableRow key={13}>
              <TableCell component="th" scope="row">Costear Estudio</TableCell>
              <TableCell align="left">{costear_estudio?.nombre ?? ""}</TableCell>
            </TableRow>
          )
        }
        {otro_costear ? (
          <TableRow key={14}>
            <TableCell component="th" scope="row">Otra Costear Estudio</TableCell>
            <TableCell align="left">{otro_costear}</TableCell>
          </TableRow>
        ) : (
          <TableRow key={14}></TableRow>
        )}

        {gestor ? (
          <TableRow key={15}>
            <TableCell component="th" scope="row">Gestor</TableCell>
            <TableCell align="left">{`${gestor.primer_nombre} ${gestor.primer_apellido} ${gestor.segundo_apellido}`}</TableCell>
          </TableRow>
        ) : (
          <TableRow key={15}></TableRow>
        )}
        {gestor ? (
          <TableRow key={16}>
            <TableCell component="th" scope="row">Correo Gestor</TableCell>
            <TableCell align="left">{gestor?.correo ?? ""}</TableCell>
          </TableRow>
        ) : (
          <TableRow key={16}></TableRow>
        )}
        <TableRow key={17}>
          <TableCell component="th" scope="row">Estado Actual</TableCell>
          <TableCell align="left">{estado_actual?.nombre.toUpperCase() ?? ""}</TableCell>
        </TableRow>
        <TableRow key={18}>
          <TableCell component="th" scope="row">Fecha Registro</TableCell>
          <TableCell align="left">{fecha}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export function InscripcionDetalleContacto({ inscripcion }) {
  let {
    departamento,
    ciudad,
    estrato,
    barrio,
    direccion,
    nombre_madre,
    celular_madre,
    nombre_padre,
    celular_padre,
    tiene_eps,
    nombre_eps,
    nombre_otro_eps,
    contacto_emergencia,
    celular_emergencia,

  } = inscripcion;

  console.log("inscripcion");
  console.log(inscripcion);
  return (
    <Table>
      <TableBody>
        {
          departamento && (
            <TableRow key={1}>
              <TableCell component="th" scope="row">Departamento</TableCell>
              <TableCell align="left">{departamento?.nombre ?? ""}</TableCell>
            </TableRow>
          )
        }
        {
          ciudad && (
            <TableRow key={2}>
              <TableCell component="th" scope="row">Ciudad/Municipio</TableCell>
              <TableCell align="left">{ciudad?.nombre ?? ""}</TableCell>
            </TableRow>
          )
        }
        {
          barrio && (
            <TableRow key={3}>
              <TableCell component="th" scope="row">Barrio</TableCell>
              <TableCell align="left">{barrio}</TableCell>
            </TableRow>
          )
        }
        {
          direccion && (
            <TableRow key={4}>
              <TableCell component="th" scope="row">Dirección</TableCell>
              <TableCell align="left">{direccion}</TableCell>
            </TableRow>
          )
        }
        <TableRow key={5}>
          <TableCell component="th" scope="row">Estrato</TableCell>
          <TableCell align="left">{estrato?.nombre ?? ""}</TableCell>
        </TableRow>
        {
          tiene_eps && (
            <TableRow key={6}>
              <TableCell component="th" scope="row">Tiene EPS</TableCell>
              <TableCell align="left">{tiene_eps?.toUpperCase() ?? ""}</TableCell>
            </TableRow>
          )
        }
       {nombre_eps && tiene_eps === 'si' && <TableRow key={7}>
          <TableCell component="th" scope="row">Nombre EPS</TableCell>
          <TableCell align="left">{nombre_eps?.nombre.toUpperCase() ?? ""}</TableCell>
        </TableRow>}
       {nombre_otro_eps && tiene_eps === 'si' && nombre_eps.id === EPS_OTRO && <TableRow key={8}>
          <TableCell component="th" scope="row">Otro EPS</TableCell>
          <TableCell align="left">{nombre_otro_eps?.toUpperCase() ?? ""}</TableCell>
        </TableRow>}
        <TableRow key={9}>
          <TableCell component="th" scope="row">Nombre Madre</TableCell>
          <TableCell align="left">{nombre_madre?.toUpperCase() ?? ""}</TableCell>
        </TableRow>
        <TableRow key={10}>
          <TableCell component="th" scope="row">Telefono Madre</TableCell>
          <TableCell align="left">{celular_madre}</TableCell>
        </TableRow>
        <TableRow key={11}>
          <TableCell component="th" scope="row">Nombre Padre</TableCell>
          <TableCell align="left">{nombre_padre?.toUpperCase() ?? ""}</TableCell>
        </TableRow>
        <TableRow key={12}>
          <TableCell component="th" scope="row">Telefono Padre</TableCell>
          <TableCell align="left">{celular_padre}</TableCell>
        </TableRow>
        {
          contacto_emergencia && (
            <TableRow key={13}>
              <TableCell component="th" scope="row">Contacto de emergencia</TableCell>
              <TableCell align="left">{contacto_emergencia}</TableCell>
            </TableRow>
          )
        }
        {
          celular_emergencia && (
            <TableRow key={14}>
              <TableCell component="th" scope="row">Celular de emergencia</TableCell>
              <TableCell align="left">{celular_emergencia}</TableCell>
            </TableRow>
          )
        }
      </TableBody>
    </Table>
  );
}
