import React, { useState } from "react";

// Redux
import { useDispatch } from "react-redux";
import { actualizarMensaje } from "../../redux/actions/actGlobal";
import { coloresEmma } from "../../global/js/funciones";
// Material UI
import {
  makeStyles,
  CssBaseline,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Grid,
  Hidden,
} from "@material-ui/core/";

// Components
import InscripcionPosgradoDatos from "./InscripcionPosgradoDatos";
import InscripcionPosgradoContacto from "./InscripcionPosgradoContacto";
import InscripcionPosgradoCarrera from "./InscripcionPosgradoCarrera";

// General components
import Copyright from "../general/app/Copyright";

// Images
import emma_logo from "../../global/imagenes/logo_agrupado_sinfondo.png";
import cuc from "../../global/imagenes/logo_cuc.png";
import MensajeContacto from "../general/MensajeContacto";

import formatoMaestriaEducacionDoc from "../../global/archivos/formatoMaestriaEducacion.docx";
import formatoGerenciaDeProyectos from "../../global/archivos/formatoMaestríaenGP.docx";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    Padding: '5px',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 650,
      marginLeft: 'auto',
      marginRight: 'auto'
      
    },
  },
  paper: {
    borderRadius: theme.spacing(2),
    background: "white",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  stepper: {
    backgroundColor: "transparent",
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    backgroundColor: "white",
    color: coloresEmma.secondarycolor,
  },
  logo_emma: {
    minWidth: "200px",
    width: "10%",
  },
  div_logo_cuc: {
    width: "100%",
    textAlign: "center",
  },
  titulo: {
    borderRadius: "20px",
    backgroundColor: coloresEmma.secondarycolor,
    color: "white",
    padding: "2%",
  },
  div_imagen: {
    position: "fixed",
    width: "90%",
    height: "400px",
    textAlign: "left",
    zIndex: "-1",
  },
  div_imagen_fin: {
    width: "90%",
    textAlign: "center",
  },
  emma_fin: {
    width: "90%",
    maxWidth: "400px",
  },

  btn_salir: {
    marginTop: theme.spacing(4),
    minWidth: "300px",
    width: "25%",
    borderRadius: "50px",
    color: "white",
  },
  div_message: {
    zIndex: "1",
    position: "fixed",
    textDecoration: "none",
    top: "0%",
    left: "75%",
    fontSize: "1em",
    lineHeight: "normal",
  },
  div_message2: {
    marginBottom: "10px",
    fontSize: "1em",
    lineHeight: "normal",
  },
  logo: {
    minWidth: "300px",
    width: "28%",
  },
  div_logo: {
    padding: theme.spacing(8, 0, 0, 0),
    textAlign: "center",
  },
  logo_cuc: {
    minWidth: "240px",
    width: "20%",
    marginTop: "20px",
  },
  mensaje_final: {
    margin: "auto",
    marginTop: "15px",
    width: "300px",
  },
}));

const steps = ["Mis datos", "Mi contacto", "Mi posgrado"];

const InscripcionPosgradoFormulario = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [enlaces, setEnlaces] = useState([
    {
      link: "https://www.cuc.edu.co/wp-content/uploads/2022/07/Formato_de_Hoja_de-Vida_Dpto_Posgrados.doc",
      title: "Formato hoja de vida",
    },
    {
      link: formatoMaestriaEducacionDoc,
      title: "Formato maestría en Educación",
    },
    {
      link: formatoGerenciaDeProyectos,
      title: "Formato maestría en Gerencia de Proyectos",
    }
  ]);

  const dispatch = useDispatch();

  let { guardado } = props;

  const siguiente = () => {
    let { validarFormulario, terminarProceso } = props;
    let errorHelperText = validarFormulario(activeStep);
    if (errorHelperText) {
      dispatch(
        actualizarMensaje({
          titulo:
            "Debes diligenciar todos los campos obligatorios para continuar.",
          tipo: "info",
          mostrar: true,
          tiempo: 6000,
        })
      );
    } else {
      props.setError({});
      if (activeStep < 2) {
        setActiveStep(activeStep + 1);
      } else {
        dispatch(terminarProceso(true));
      }
    }
  };

  const anterior = () => {
    setActiveStep(activeStep - 1);
  };

  const getStepContent = (step, props) => {
    switch (step) {
      case 0:
        return <InscripcionPosgradoDatos propsDatos={props} />;
      case 1:
        return <InscripcionPosgradoContacto propsDatos={props} />;
      case 2:
        return <InscripcionPosgradoCarrera propsDatos={props} />;
      default:
        throw new Error("Unknown step");
    }
  };

  const renderContenido = (guardado) => {
    if (!guardado) {
      return (
        <Paper className={classes.paper}>
          <Typography
            className={classes.titulo}
            component="h1"
            variant="h5"
            align="center"
          >
            <Hidden mdUp>INSCRIPCIONES POSGRADOS</Hidden>
            <Hidden smDown>INSCRIPCIONES POSGRADOS UNICOSTA</Hidden>
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {getStepContent(activeStep, props)}
          <div className={classes.buttons}>
            {activeStep !== 0 && (
              <Button onClick={anterior} className={classes.button}>
                Regresar
              </Button>
            )}
            <Button onClick={siguiente} className={classes.button}>
              Siguiente
            </Button>
          </div>
        </Paper>
      );
    } else {
      return (
        <Grid container className={classes.div_logo}>
          <Grid item xs={12}>
            <img
              src={emma_logo}
              alt="Logo logo Emma"
              className={classes.logo}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              color="textSecondary"
              component="p"
              align="justify"
              className={classes.mensaje_final}
            >
              Hola <strong>{props.primerNombre}</strong>, ya diste el primer
              paso para ser parte de la Universidad de la Costa, recibirás en tu
              correo electrónico registrado el volante y opciones para el pago
              de tu inscripción.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              aria-label="add"
              className={classes.btn_salir}
              href="/inscripcion_posgrados"
            >
              Salir
            </Button>
          </Grid>
          <Grid item xs={12}>
            <img src={cuc} alt="Logo cuc" className={classes.logo_cuc} />
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <>
      <CssBaseline />
      <main mb={2} className={classes.layout}>
        {!guardado && (
          <section className={classes.div_logo_cuc}>
            <img
              src={emma_logo}
              alt="Logo Emma plataforma universidad de la costa"
              className={classes.logo_emma}
            />
          </section>
        )}
        {renderContenido(guardado)}
        {!guardado && (
          <section>
            <Hidden mdDown>
              <div className={classes.div_message}>
                <MensajeContacto
                  title={"Dudas"}
                  content={
                    "Si tienes alguna duda del proceso puedes comunicarte a los correos lorellan@cuc.edu.co o svargas18@cuc.edu.co"
                  }
                  enlaces={[]}
                  typeIcon={"duda"}
                />
                <MensajeContacto
                  title={"Descargar formatos"}
                  content={
                    " Para completar el proceso, descarga los siguientes formatos:"
                  }
                  enlaces={enlaces}
                  typeIcon={"descarga"}
                />
              </div>
            </Hidden>
            <Hidden lgUp>
              <div className={classes.div_message2}>
                <MensajeContacto
                  // clase={classes.div_message2}
                  title={"Dudas"}
                  content={
                    "Si tienes alguna duda del proceso puedes comunicarte a los correos lorellan@cuc.edu.co o svargas18@cuc.edu.co"
                  }
                  enlaces={[]}
                />
                <MensajeContacto
                  title={"Descargar formatos"}
                  content={
                    " Para completar el proceso, descarga los siguientes formatos:"
                  }
                  enlaces={enlaces}
                  typeIcon={"descarga"}
                />
              </div>
            </Hidden>
          </section>
        )}
      </main>
      <Copyright />
    </>
  );
};

export default InscripcionPosgradoFormulario;
