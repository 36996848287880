import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Avatar, Grid, Typography, } from '@material-ui/core/';
import dinero_renovacion from '../../../global/imagenes/dinero_renovacion.png';
import dinero from '../../../global/imagenes/dinero.png';
import certificate from '../../../global/imagenes/certificate.png';
import salary from '../../../global/imagenes/salary.png';
import dinero_marron from '../../../global/imagenes/dinero_marron.png';
import moneyfrozen from '../../../global/imagenes/money-frozen.png';
import devolucio_dinero from '../../../global/imagenes/garantia-de-devolucion-del-dinero.png';
import consultar_estado_finan from '../../../global/imagenes/estado-financiero-consultar.png';
import entidad_financiera from '../../../global/imagenes/entidad-financiera.png';
import pazysalvo_entidad from '../../../global/imagenes/pazysalvoentidad.png';
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%",
    minHeight: "50px",
    height: "300px",
  },
  image: {
    width: '100px',
    maxWidth: '100px',
    minWidth: '100px',
    height: "100px",
  },
  content_text: {
    padding: "0px 0px 0px 10px",
  },
  content_img: {
    textAlign: 'center',
    height: "150px",
  },

}));

export default function CreditoActivo({ titulo, fecha, descripcion, estado, categoria, tipo }) {
  let fecha_r = moment(fecha).format('YYYY-MM-DD, h:mm a');
  let imagen = ''
  const classes = useStyles();

  if(tipo == 'sol_cred'){
    imagen = categoria === '1' ? dinero : dinero_renovacion
  }else if(tipo == 'sol_lega'){
    imagen = certificate
  }else if(tipo == 'sol_desc'){
    imagen = salary
  }else if(tipo == 'sol_directo'){
    imagen = dinero_marron
  }else if(tipo == 'sol_cong'){
    imagen = moneyfrozen
  } else if (tipo == 'sol_dev') {
    imagen = devolucio_dinero
  } else if (tipo == 'sol_conestfin') {
    imagen = consultar_estado_finan
  } else if (tipo == 'sol_enti') {
    imagen = entidad_financiera
  } else if (tipo == 'sol_pazsalvo') {
    imagen = pazysalvo_entidad
  }

  const obtenerColor = (codigo) => {
    let color = 'white';
    const colores = {
      'Cre_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
      'Cre_Rev': { 'backgroundColor': '#ffc107', color },
      'Cre_Rec': { 'backgroundColor': '#b71c1c', color },
      'Cre_Car': { 'backgroundColor': '#009688', color },
      'Cre_Val': { 'backgroundColor': '#0d47a1', color },
      'Cre_Pen': { 'backgroundColor': '#02A6F2', color },
      'Cre_Ege': { 'backgroundColor': '#e65100', color },
      'Cre_Pag': { 'backgroundColor': '#6200ea', color },
      'Cre_Ges': { 'backgroundColor': '#bdbdbd', color },
      'Cre_Pfac': { 'backgroundColor': '#F5951B', color },
      'Cre_Rech': { 'backgroundColor': '#b71c1c', color },
      'Cre_Afac': { 'backgroundColor': '#1B8FF5', color },
      'Cre_ProRea': { 'backgroundColor': '#06df3e', color }
    }
    return (colores[codigo])
  }


  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<Avatar aria-label="recipe" style={obtenerColor(estado.codigo)}> {estado.valora} </Avatar>}
        title={titulo}
        subheader={fecha_r}
      />
      <CardContent>
        <Grid container direction={'row'} justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} className={classes.content_img}>
            <img src={imagen} className={classes.image} alt='Imagen Tipo' />
            <Typography variant="body2" color="textSecondary" component="p" align='justify'>
              {descripcion}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
