import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import moment from 'moment'


export function CreditoDetalleSimple({ credito }) {
  let {
    categoria,
    programa,
    entidad,
    estado_actual,
    gestor,
    usuario_registro: { primer_nombre, primer_apellido, segundo_apellido },
    tipo,
    Programa_cre_d
  } = credito;
  return (
    
    <Table>
      <TableBody>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Estudiante</TableCell>
          <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
        </TableRow>
        {tipo.codigo != 'sol_directo' &&
          <TableRow key={2}>
            <TableCell component="th" scope="row">Programa</TableCell>
            <TableCell align="left">{programa.nombre}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={2}>
            <TableCell component="th" scope="row">Programa</TableCell>
            <TableCell align="left">{Programa_cre_d}</TableCell>
          </TableRow>
        }
        <TableRow key={3}>
          <TableCell component="th" scope="row">Tipo de Solicitud</TableCell>
          <TableCell align="left">{tipo.nombre}</TableCell>
        </TableRow>
        {entidad && entidad.codigo != 'Ent_Def' && entidad.codigo != 'Ent_Na' &&
          <TableRow key={4}>
            <TableCell component="th" scope="row">Entidad Financiera</TableCell>
            <TableCell align="left">{entidad.nombre}</TableCell>
          </TableRow>
        }
        {categoria != '-1' ?
          <TableRow key={5}>
            <TableCell component="th" scope="row">Categoria</TableCell>
            <TableCell align="left">{categoria === '1' ? 'Nuevo' : 'Renovación'}</TableCell>
          </TableRow>
          : <TableRow key={5}></TableRow>
        }
        {
          gestor ?
            <TableRow key={6}>
              <TableCell component="th" scope="row">Asesor</TableCell>
              <TableCell align="left">{`${gestor.primer_nombre} ${gestor.primer_apellido} ${gestor.segundo_apellido}`}</TableCell>
            </TableRow>
            : <TableRow key={6}></TableRow>
        }
        {
          gestor ?
            <TableRow key={7}>
              <TableCell component="th" scope="row">Correo Asesor</TableCell>
              <TableCell align="left">{`${gestor.correo}`}</TableCell>
            </TableRow>
            : <TableRow key={7}></TableRow>
        }
        <TableRow key={8}>
          <TableCell component="th" scope="row">Estado Actual</TableCell>
          <TableCell align="left">{estado_actual.nombre}</TableCell>
        </TableRow>
      </TableBody>

    </Table>
  )
}



export function CreditoDetalleCompleto({ credito }) {
  let {
    fecha_registro,
    categoria,
    programa,
    mensaje,
    entidad,
    estado_actual,
    motivo_negado,
    requiere_asesoria,
    usuario_registro: { primer_nombre, primer_apellido, segundo_apellido, correo, telefono, celular, identificacion, lugar_expedicion, lugar_residencia },
    gestor,
    creditos_matricula,
    promedio_acumulado,
    promedio_nivel,
    primer_semestre,
    valor_aprobado,
    tipo,
    tipo_pago,
    subtipo_pago,
    solicitud_descongela,
    origen_descongela,
    asignatura_descongela,
    modalidad_congela,
    Programa_cre_d,
    direccion_estu,
    lugar_recidencia,
    Valor_solicitado,
    CreditoPara,
    Valor_de_la_matricula,
    lugar_expe_documento,
    documento_re,
    fecha_expedicion_re,
    telefono_re,
    direccion_re,
    correo_re,
    documento,
    fecha_expedicion,
    nombre_completo,
    telefono_estu,
    correo_estu,
    documento_co,
    fecha_expedicion_co,
    nombre_completo_co,
    telefono_co,
    direccion_co,
    lugar_resi_co,
    ocupacion_co,
    ingreso_co,
    empresa_co,
    telefono_empresa_co,
    direccion_empresa_co,
    ciudad_empresa_co,
    nombre_completo_re,
    correo_co,
    nombre_titular_dev,
    numero_documento_dev,
    solicitud_dev,
    entidades_dev,
    origen_dinero_dev
    
  } = credito;
  let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');
  return (

    <Table>
      <TableBody>
      {console.log("tipo:", tipo)}
        {primer_semestre != '-1' ?
          <TableRow key={20}>
            <TableCell component="th" scope="row">Primer Semestre</TableCell>
            <TableCell align="left">{primer_semestre.toUpperCase()}</TableCell>
          </TableRow>
          : <TableRow key={20}></TableRow>
        }
      {tipo.codigo != 'sol_enti' && 
          <TableRow key={1}>
          <TableCell component="th" scope="row">Estudiante</TableCell>
          <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
        </TableRow>
      }
        <TableRow key={16}>
          <TableCell component="th" scope="row">Identificación</TableCell>
          <TableCell align="left">{identificacion}</TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell component="th" scope="row">Correo</TableCell>
          <TableCell align="left">{correo}</TableCell>
        </TableRow>
        <TableRow key={3}>
          <TableCell component="th" scope="row">Telefono - Celular</TableCell>
          <TableCell align="left">{`${telefono} - ${celular}`}</TableCell>
        </TableRow>
        {tipo.codigo != 'sol_directo' && tipo.codigo != 'sol_enti' &&
          <TableRow key={4}>
            <TableCell component="th" scope="row">Programa</TableCell>
            <TableCell align="left">{programa.nombre}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={2}>
            <TableCell component="th" scope="row">Programa</TableCell>
            <TableCell align="left">{Programa_cre_d}</TableCell>
          </TableRow>
        }
        <TableRow key={20}>
          <TableCell component="th" scope="row">Tipo de Proceso Financiero</TableCell>
          <TableCell align="left">{tipo.nombre}</TableCell>
        </TableRow>
        {tipo.codigo != "sol_desc" && tipo.codigo != "sol_cong" && tipo.codigo != 'sol_conestfin' &&
          <TableRow key={5}>
            <TableCell component="th" scope="row">Entidad Financiera</TableCell>
            <TableCell align="left">{entidad.nombre}</TableCell>
          </TableRow>
        }
        {tipo.codigo == "sol_lega" && tipo_pago ?
          <TableRow key={20}>
            <TableCell component="th" scope="row">Tipo de Pago</TableCell>
            <TableCell align="left">{tipo_pago.nombre}</TableCell>
          </TableRow>
          : <TableRow key={20}></TableRow>
        }
        {tipo.codigo == "sol_lega" && subtipo_pago ?
          <TableRow key={21}>
            <TableCell component="th" scope="row">Subtipo de Pago</TableCell>
            <TableCell align="left">{subtipo_pago.nombre}</TableCell>
          </TableRow>
          : <TableRow key={21}></TableRow>
        }
        {solicitud_descongela ?
          <TableRow key={22}>
            <TableCell component="th" scope="row">Tipo de Solicitud</TableCell>
            <TableCell align="left">{solicitud_descongela.nombre}</TableCell>
          </TableRow>
          : <TableRow key={22}></TableRow>
        }
        {modalidad_congela ?
          <TableRow key={25}>
            <TableCell component="th" scope="row">Modalidad</TableCell>
            <TableCell align="left">{modalidad_congela.nombre}</TableCell>
          </TableRow>
          : <TableRow key={25}></TableRow>
        }
        {origen_descongela ?
          <TableRow key={23}>
            <TableCell component="th" scope="row">Origen Dineros Congelados</TableCell>
            <TableCell align="left">{origen_descongela.nombre}</TableCell>
          </TableRow>
          : <TableRow key={23}></TableRow>
        }
        {asignatura_descongela ?
          <TableRow key={24}>
            <TableCell component="th" scope="row">Asignatura</TableCell>
            <TableCell align="left">{asignatura_descongela}</TableCell>
          </TableRow>
          : <TableRow key={24}></TableRow>
        }
        {categoria != '-1' ?
          <TableRow key={6}>
            <TableCell component="th" scope="row">Categoria</TableCell>
            <TableCell align="left">{categoria === '1' ? 'Nuevo' : 'Renovación'}</TableCell>
          </TableRow>
          : <TableRow key={6}></TableRow>
        }
        {(promedio_acumulado && promedio_nivel) ?
          <TableRow key={7}>
            <TableCell component="th" scope="row">Promedios</TableCell>
            <TableCell align="left">{`Nivel : ${promedio_nivel} - Acumulado : ${promedio_acumulado}`}</TableCell>
          </TableRow>
          : <TableRow key={7}></TableRow>
        }
        {
          creditos_matricula ?
            <TableRow key={8}>
              <TableCell component="th" scope="row">#Creditos</TableCell>
              <TableCell align="left">{creditos_matricula}</TableCell>
            </TableRow>
            : <TableRow key={8}></TableRow>
        }
        {
          valor_aprobado ?
            <TableRow key={18}>
              <TableCell component="th" scope="row">Valor Aprobado</TableCell>
              <TableCell align="left">{valor_aprobado}</TableCell>
            </TableRow>
            : <TableRow key={18}></TableRow>
        }
        {
          gestor ?
            <TableRow key={9}>
              <TableCell component="th" scope="row">Asesor</TableCell>
              <TableCell align="left">{`${gestor.primer_nombre} ${gestor.primer_apellido} ${gestor.segundo_apellido}`}</TableCell>
            </TableRow>
            : <TableRow key={9}></TableRow>
        }
        {
          gestor ?
            <TableRow key={10}>
              <TableCell component="th" scope="row">Correo Asesor</TableCell>
              <TableCell align="left">{`${gestor.correo}`}</TableCell>
            </TableRow>
            : <TableRow key={10}></TableRow>
        }
        <TableRow key={11}>
          <TableCell component="th" scope="row">Estado Actual</TableCell>
          <TableCell align="left">{estado_actual.nombre}</TableCell>
        </TableRow>
        {
          motivo_negado ?
            <TableRow key={12}>
              <TableCell component="th" scope="row">Motivo Rechazo</TableCell>
              <TableCell align="left">{motivo_negado.nombre}</TableCell>
            </TableRow>
            : <TableRow key={12}></TableRow>
        }
        {
          requiere_asesoria === '1' ?
            <TableRow key={13}>
              <TableCell component="th" scope="row">Asesoría</TableCell>
              <TableCell align="left">SI</TableCell>
            </TableRow>
            : <TableRow key={13}></TableRow>
        }
        {
          tipo.codigo === 'sol_dev' ?
            <TableRow key={14}>
              <TableCell component="th" scope="row">Tipo de devolición</TableCell>
              <TableCell align="left">{solicitud_dev.nombre}</TableCell>
            </TableRow>
            : <TableRow key={14}></TableRow>
        }
        {
          tipo.codigo == 'sol_dev' ?
          <TableRow key={15}>
          <TableCell component="th" scope="row">Origen de devolición</TableCell>
              <TableCell align="left">{origen_dinero_dev.nombre}</TableCell>
          </TableRow> 
          : <TableRow key={15}></TableRow>
        }
        {
          tipo.codigo == 'sol_dev' && entidades_dev !== null ?
          <TableRow key={16}>
              <TableCell component="th" scope="row">Entidad</TableCell>
              <TableCell align="left">{entidades_dev.nombre}</TableCell>
            </TableRow>:
            <TableRow key={16}></TableRow> 
            
        }
        {
          tipo.codigo == 'sol_dev' && nombre_titular_dev ?
            <TableRow key={17}>
              <TableCell component="th" scope="row">Nombre del Titular</TableCell>
              <TableCell align="left">{nombre_titular_dev}</TableCell>
            </TableRow> :
            <TableRow key={17}></TableRow> 
        }
        {
          tipo.codigo === 'sol_dev' && numero_documento_dev ?
            <TableRow key={18}>
              <TableCell component="th" scope="row">Numero de Documento</TableCell>
              <TableCell align="left">{numero_documento_dev}</TableCell>
            </TableRow> : <TableRow key={18}></TableRow> 
        }
        {
          mensaje ?
            <TableRow key={19}>
              <TableCell component="th" scope="row">Mensaje</TableCell>
              <TableCell align="left">{mensaje}</TableCell>
            </TableRow>
            : <TableRow key={19}></TableRow>
        }
        
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Direccion</TableCell>
            <TableCell align="left">{direccion_estu}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Lugar de residencia</TableCell>
            <TableCell align="left">{lugar_residencia}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Valor solicitado</TableCell>
            <TableCell align="left">{Valor_solicitado}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Credito para</TableCell>
            <TableCell align="left">{CreditoPara}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Valor de la matricula</TableCell>
            <TableCell align="left">{Valor_de_la_matricula}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Lugar de expedicion del documento</TableCell>
            <TableCell align="left">{lugar_expedicion}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' && documento &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Documento de identidad referencia del estudiante</TableCell>
            <TableCell align="left">{documento}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' && fecha_expedicion &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Fecha de expedicion referencia del estudiante</TableCell>
            <TableCell align="left">{fecha_expedicion}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Nombre completo referencia del estudiante</TableCell>
            <TableCell align="left">{nombre_completo}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Telefono de contacto referencia del estudiante</TableCell>
            <TableCell align="left">{telefono_estu}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Direccion referencia del estudiante</TableCell>
            <TableCell align="left">{direccion_estu}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Correo referencia del estudiante</TableCell>
            <TableCell align="left">{correo_estu}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Documento de identidad codeudor</TableCell>
            <TableCell align="left">{documento_co}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Fecha de expedicion codeudor</TableCell>
            <TableCell align="left">{fecha_expedicion_co}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Nombre completo codeudor</TableCell>
            <TableCell align="left">{nombre_completo_co}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Telefono de contacto codeudor</TableCell>
            <TableCell align="left">{telefono_co}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Direccion codeudor</TableCell>
            <TableCell align="left">{direccion_co}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Lugar de residencia codeudor</TableCell>
            <TableCell align="left">{lugar_resi_co}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Ocupacion codeudor</TableCell>
            <TableCell align="left">{ocupacion_co}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Ingreso mensual codeudor</TableCell>
            <TableCell align="left">{ingreso_co}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Empresa donde labora codeudor</TableCell>
            <TableCell align="left">{empresa_co}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Telefono de contacto de la empresa codeudor</TableCell>
            <TableCell align="left">{telefono_empresa_co}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Direccion de la empresa codeudor</TableCell>
            <TableCell align="left">{direccion_empresa_co}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Ciudad de la empresa codeudor</TableCell>
            <TableCell align="left">{ciudad_empresa_co}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Correo codeudor</TableCell>
            <TableCell align="left">{correo_co}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' && documento_re &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Documento de identidad referencia codeudor</TableCell>
            <TableCell align="left">{documento_re}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' && fecha_expedicion_re &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Fecha de expedicion referencia codeudor</TableCell>
            <TableCell align="left">{fecha_expedicion_re}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Nombre completo referencia codeudor</TableCell>
            <TableCell align="left">{nombre_completo_re}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Telefono de contacto referencia codeudor</TableCell>
            <TableCell align="left">{telefono_re}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Direccion referencia codeudor</TableCell>
            <TableCell align="left">{direccion_re}</TableCell>
          </TableRow>
        }
        {tipo.codigo === 'sol_directo' &&
          <TableRow key={20}>
            <TableCell component="th" scope="row">Correo referencia codeudor</TableCell>
            <TableCell align="left">{correo_re}</TableCell>
          </TableRow>
        }
        <TableRow key={20}>
          <TableCell component="th" scope="row">Fecha Registro</TableCell>
          <TableCell align="left">{fecha}</TableCell>
        </TableRow>
      </TableBody>

    </Table>
  )
}// fin CreditoDetalleCompleto

