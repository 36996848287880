// React
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

// Material UI Components
import {
  IconButton,
  Tooltip,
  Chip,
  Button,
  Paper,
  InputBase,
  Box
} from '@material-ui/core/';
import { coloresEmma } from '../../global/js/funciones';
// Material UI Icons
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import DeleteIcon from '@material-ui/icons/Delete';
import FolderIcon from '@material-ui/icons/Folder';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import DoneIcon from '@material-ui/icons/Done';
import SearchIcon from '@material-ui/icons/Search';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ApartmentIcon from '@material-ui/icons/Apartment';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckIcon from '@material-ui/icons/Check';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SendIcon from '@material-ui/icons/Send';
import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';

// Íconos Extensiones
import BLANK from './../../global/imagenes/extensiones/blank.png';
import CSV from './../../global/imagenes/extensiones/csv.png';
import DOC from './../../global/imagenes/extensiones/doc.png';
import SVG from './../../global/imagenes/extensiones/svg.png';
import DOCX from './../../global/imagenes/extensiones/docx.png';
import JPG from './../../global/imagenes/extensiones/jpg.png';
import PDF from './../../global/imagenes/extensiones/pdf.png';
import PNG from './../../global/imagenes/extensiones/png.png';
import PPT from './../../global/imagenes/extensiones/ppt.png';
import PPTX from './../../global/imagenes/extensiones/pptx.png';
import RAR from './../../global/imagenes/extensiones/rar.png';
import TXT from './../../global/imagenes/extensiones/txt.png';
import XLS from './../../global/imagenes/extensiones/xls.png';
import XLSX from './../../global/imagenes/extensiones/xlsx.png';
import ZIP from './../../global/imagenes/extensiones/zip.png';
import PanToolIcon from '@material-ui/icons/PanTool';
import HomeIcon from '@material-ui/icons/Home';


const useStyles = makeStyles(theme => ({
  buttonContainer: {
    width: "100%", // Mismo ancho que el botón
    height: "48px", // Ajusta según el padding del botón
    backgroundColor: (props) => props.backgroundColor || 'red',
    color: "#fff", // Color del texto
    marginTop: "25px",
    borderRadius: "10px",
    padding: "12px",
    fontSize: "15px",
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    overflow: "hidden",
  },
  loadingEffect: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "linear-gradient(90deg, rgba(183, 14, 12, 0.2) 0%, rgba(144, 8, 7, 0.5) 30%, rgba(183, 14, 12, 0.4) 70%)",
    backgroundSize: "200% 100%",
    animation: "$loadingAnimation 1.5s infinite",
  },
  "@keyframes loadingAnimation": {
    "0%": { backgroundPosition: "100% 0" },
    "100%": { backgroundPosition: "-100% 0" },
  },

  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: '1px 0px 1px 0px ',
    position: 'relative',
  },
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '52%',
    left: '5%',
    // marginTop: -12,

  },
  paper: {
    margin: '10px',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

function BtnDepartamento({ callback, title = "Departamento", color = '#2196f3' }) {
  return <Tooltip title={title} aria-label="departamento" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="departamento"><ApartmentIcon fontSize="small" /> </IconButton></Tooltip>
}
function BtnPendiente({ callback, title = "Permisos", color = '#009688' }) {
  return <Tooltip title={title} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><PanToolIcon fontSize="small" /> </IconButton></Tooltip>
}
function BtnReset({ callback, title = "Rotar HV", color = '#009688' }) {
  return <Tooltip title={title} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><RotateLeftIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnAprobar({callback, texto='Aprobar', color = '#2ECC71'}){
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><CheckIcon font></CheckIcon></IconButton></Tooltip>
}

function BtnCancelar({ callback, texto = 'Cancelar', color = coloresEmma.secondarycolor }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color }} aria-label="delete"><ClearIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnPermiso({ callback, texto = "Permisos", color = "#009688" }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color }} aria-label="delete"><BeenhereIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnSeleccion({ callback, texto = 'Seleccionar', color = "#009688" }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color }} aria-label="delete"><DoneIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnAgregar({ callback, texto = 'Agregar interes', color = "#009688" }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color }} aria-label="delete"><AddIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnEditar({ callback, texto = 'Editar', color = "#2196f3" }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color }} aria-label="update"><EditIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnValidar({ callback, texto = 'Validar', color = "#2196f3" }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color }} aria-label="update"><DoneOutlineIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnEliminar({ callback, texto = 'Eliminar', color= coloresEmma.secondarycolor}) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color }} aria-label="delete"><DeleteIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnCerrado() {
  return <Tooltip title="Sin Acción" aria-label="add" ><IconButton style={{ color: "#9e9e9e" }} aria-label="delete"><ToggleOffIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnApagar({ callback, texto = 'Apagar' }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: coloresEmma.secondarycolor }} aria-label="delete"><ToggleOnIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnEncender({ callback, texto = 'Encender' }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: "#9e9e9e" }} aria-label="delete"><ToggleOffIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnGuardar({ callback, texto = 'Nuevo' }) {
  return <Chip label={texto} style={{ backgroundColor: '#eeeeee', color: coloresEmma.secondarycolor }} onClick={callback.bind(this)} />
}

function BtnDetalle({ callback, color = '#01579b', texto = 'Abrir', href, target }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete" href={href} target={target}><ImportContactsIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnDuplicar({ callback, color = '#01579b', texto = 'Abrir', href, target }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete" href={href} target={target}><FileCopyIcon fontSize="small" /> </IconButton></Tooltip>
}
function BtnSyllabus({color = '#01579b', texto = 'Syllabus' }) {
  return <Tooltip title={texto} aria-label="add" ><IconButton style={{ color: color }} aria-label="delete"><ImportContactsIcon fontSize="small" /> </IconButton></Tooltip>
}
function BtnFolder({ callback, color = '#01579b', texto = 'Documentos' }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete" ><FolderIcon fontSize="small" /> </IconButton></Tooltip>
}

function Btnestados({ callback, color = '#01579b', texto = 'historial de estados' }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete" ><FolderIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnGestionar({ callback, color = '#424242', texto = 'Gestionar' }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><VerifiedUserIcon fontSize="small" /> </IconButton></Tooltip>
}
function BtnArchivo({ callback, color = '#424242' }) {
  return <Tooltip title='Adjuntar Archivo' aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><AttachFileIcon fontSize="small" /> </IconButton></Tooltip>
}
function BtnTelefonoDis({ callback, color = '#424242', texto = '', }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><PhoneDisabledIcon fontSize="small" /> </IconButton></Tooltip>
}
function BtnPersonas({ callback, color = '#424242', texto = '', }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><SupervisorAccountIcon fontSize="small" /> </IconButton></Tooltip>
}
function BtnLocalizacionOff({ callback, color = '#424242', texto = '', }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><LocationOffIcon fontSize="small" /> </IconButton></Tooltip>
}
function BtnLocalizacionOn({ callback, color = '#424242', texto = '', }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><LocationOnIcon fontSize="small" /> </IconButton></Tooltip>
}
function BtnVer({ callback, color = '#01579b', texto = '', }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><VisibilityIcon fontSize="small" /> </IconButton></Tooltip>
}
function BtnAcciones({ callback, color = '#01579b', texto = 'Acciones', }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><TouchAppIcon fontSize="small" /> </IconButton></Tooltip>
}
function BtnRegresar({ callback, color = coloresEmma.secondarycolor, texto = 'Regresar', }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><ArrowBackIosIcon fontSize="small" /> </IconButton></Tooltip>
}
function BtnRegresarinicio({ callback, color = coloresEmma.secondarycolor, texto = 'Regresar al Inicio', }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><HomeIcon fontSize="small" /> </IconButton></Tooltip>
}
function BtnNotificacion({ callback, color = '#607d8b', texto = 'Notifica', }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><NotificationsActiveIcon fontSize="small" /> </IconButton></Tooltip>
}
function BtnPosponer({ callback, color = '#607d8b', texto = 'Posponer', }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><AccessTimeIcon fontSize="small" /> </IconButton></Tooltip>
}
function BtnCargando({ callback, backgroundColor = coloresEmma.primarycolor, color = 'white', colorTexto = 'white', texto = 'Guardar', cargando = false, variant = 'variant', fullWidth = false, tipo = 'submit' }) {
  const classes = useStyles({ backgroundColor });
  return (
    <div className={classes.wrapper}>
      {cargando ?

        <Box className={classes.buttonContainer}>
          <Box className={classes.loadingEffect} />
          {texto}
        </Box> : <Button
          type='submit'
          variant="contained"
          style={{ backgroundColor, color, marginTop: "25px", borderRadius: "10px", padding: "12px", textTransform: 'capitalize', fontSize: '15px' }}
          disabled={cargando}
          fullWidth={fullWidth}
          onClick={callback.bind(this)}
        >
          {texto}
        </Button>
      }
    </div>

  );
}

function BtnCargar({ callback, color = '#009688', titulo = 'Subir Archivo' }) {
  return <Tooltip title={titulo} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><PublishIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnDescargar({ callback, color = '#2196f3', titulo = 'Descargar Archivo' }) {
  return <Tooltip title={titulo} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><GetAppIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnForm({ callback, texto = 'Nuevo' }) {
  return <Button color="primary" style={{ color: coloresEmma.secondarycolor }} onClick={callback.bind(this)}> {texto} </Button>
}

function BtnFormPage({ callback, texto = 'Nuevo' }) {
  return <Button color="primary" style={{ color: coloresEmma.secondarycolor }} onClick={callback}> {texto} </Button>
}

function BtnBuscar({ callback, texto = 'Buscar', id = 'btn_buscar' }) {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <InputBase className={classes.input} placeholder={texto} inputProps={{ 'arial-label': 'Buscar' }} onChange={callback.bind(this)} />
      <IconButton className={classes.iconButton} arial-label="buscar" disabled={true}>
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}
function BtnEnviar({ callback, color = '#424242', texto = 'Enviar' }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color: color }} aria-label="delete"><SendIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnHabilitar({ callback, texto = 'Editar', color = "#2196f3" }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color }} aria-label="update"><KeyboardArrowUpIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnDeshabilitar({ callback, texto = 'Editar', color = "#2196f3" }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color }} aria-label="update"><KeyboardArrowDownIcon fontSize="small" /> </IconButton></Tooltip>
}

function BtnBuscar2({ callback, texto = 'Buscar', color = "#ae2012" }) {
  return <Tooltip title={texto} aria-label="add" onClick={callback.bind(this)}><IconButton style={{ color }} aria-label="update"><SearchIcon fontSize="small" /> </IconButton></Tooltip>
}

function ImagenExt(archivo, width) {
  const ext = archivo.split('.').pop();
  let imagen = '';
  if (ext === 'pdf') imagen = PDF;
  else if (ext === 'doc') imagen = DOC;
  else if (ext === 'docx') imagen = DOCX;
  else if (ext === 'csv') imagen = CSV;
  else if (ext === 'xls') imagen = XLS;
  else if (ext === 'xlsx') imagen = XLSX;
  else if (ext === 'ppt') imagen = PPT;
  else if (ext === 'pptx') imagen = PPTX;
  else if (ext === 'rar') imagen = RAR;
  else if (ext === 'zip') imagen = ZIP;
  else if (ext === 'txt') imagen = TXT;
  else if (ext === 'png') imagen = PNG;
  else if (ext === 'svg') imagen = SVG;
  else if (ext === 'jpg' || ext === 'jpeg') imagen = JPG;
  else imagen = BLANK;
  return <img src={imagen} alt={`Archivo ${ext}`} style={{ width: `${width}%` }} />;
}

export {
  BtnCancelar,
  BtnEditar,
  BtnEliminar,
  BtnCerrado,
  BtnGuardar,
  BtnDetalle,
  BtnCargando,
  BtnForm,
  BtnPermiso,
  BtnSeleccion,
  BtnAgregar,
  BtnBuscar,
  BtnGestionar,
  BtnArchivo,
  ImagenExt,
  BtnTelefonoDis,
  BtnPersonas,
  BtnLocalizacionOff,
  BtnLocalizacionOn,
  BtnCargar,
  BtnDescargar,
  BtnFolder,
  BtnAcciones,
  BtnVer,
  BtnRegresar,
  BtnNotificacion,
  BtnPendiente,
  BtnPosponer,
  BtnFormPage,
  BtnDepartamento,
  BtnValidar,
  BtnAprobar,
  BtnDuplicar,
  BtnSyllabus,
  BtnEnviar,
  BtnReset,
  Btnestados,
  BtnHabilitar,
  BtnDeshabilitar,
  BtnRegresarinicio,
  BtnApagar,
  BtnEncender,
  BtnBuscar2
  }
