
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Paper, Grid, Button, Typography } from '@material-ui/core'
import { red } from '@material-ui/core/colors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { consulta, mostrarError, obtenerValoresFiltros, generarFiltros, formulario, crear_form_data } from "../../global/js/funciones";
import { actualizarMensaje, mostrarModulo } from "../../redux/actions/actGlobal";
import { mostrarModalAddCredito, mostrarModalDetalleCredito, mostrarModalAdjuntoCredito,mostrarModalFormuDirecto, mostrarModalEncuestaCredito } from "../../redux/actions/actCreditos";
import { MenuEstudiante } from "./creditos/CreditoMenu";
import Credito from "./creditos/Credito";
import { CreditoDetalleSimple } from "./creditos/CreditoDetalle";
import CreditoAgregar from "./creditos/CreditoAgregar";
import CreditoFormuDirecto from "./creditos/CreditoFormuDirecto";
import CreditoFormAgregar from "./creditos/CreditoFormAgregar";
import CreditoOpcionesTipo from "./creditos/CreditoOpcionesTipo";
import TareasTerminadas from "../general/TareasTerminadas";
import ListarDatos from "../general/ListarDatos";
import { BtnDetalle } from "../general/BotonesAccion";
import TituloAccion from "../general/TituloAccion";
import BarraProceso from "../general/BarraProceso";
import AccionesRapidas from "../general/AccionesRapidas";
import CreditoActivo from "./creditos/CreditoActivo";
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s from '../../global/imagenes/emma_s1.png';
import dinero from '../../global/imagenes/dinero.png';
import files from '../../global/imagenes/files.png';
import revisar from '../../global/imagenes/revisar.png';
import test from '../../global/imagenes/test.png';
import moment from 'moment'
import { TIPOS_FINANCIEROS } from "./helper";

class CreditosEstudiantes extends Component {

  constructor(props) {
    super(props)
    this.state = {
      seleccion: 'proceso',
      cargando: true,
      creditos_activos: [],
      linea_estados: [],
      historial: [],
      enListado: true,
      periodo: '',
      id_credito: 0,//se obtiene de los procesos activos
      credito: null,//cuando le da en el detalle del proceso activo
      vistaEncuesta: 'encuesta',
      vistaDetalle: 'credito',
      menu: true,
      tipos: [],
      tipoSolicitud: {},
      tipo_proceso_actual: '',
      estado_actual_credito: '',
      estudiante: false
    }
  }
  
  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/procesos_financieros', nombre: 'Mis Procesos Financieros' })
      this.obtenerDatosIniciales();
      this.esEstudiante()
  }

  
  async componentDidUpdate({ }, { seleccion, id_credito }) {
    let { seleccion: seleccionNew, id_credito: id_creditoNew } = this.state;
    if (id_credito !== id_creditoNew && seleccionNew === 'proceso') this.obtenerDataProcesoActual(id_creditoNew);
    if (seleccion !== seleccionNew) {
      if (seleccionNew === 'proceso') this.obtenerDatosIniciales();
      else if (seleccionNew === 'historial') this.obtenerHistorial();
      else if (seleccionNew === 'agregar') this.setState({ menu: true })
    }
  }
  
  async obtenerDatosIniciales() {
    this.setState({ cargando: true, enListado: true, })
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Cre' }]);
    let creditos_activos = await this.obtenerCreditos([{ 'llave': 'periodo', 'valor': periodo.length > 0 ? periodo[0].nombre : '' }]);
    let tipos = await obtenerValoresFiltros([{ llave: 'generica', valor: TIPOS_FINANCIEROS }, { llave: 'estado', valor: '1' } ])

    let { usuario } = this.props;

    let tiposFiltrados = tipos;

    if (usuario && usuario.perfil.codigo === "Per_enti_finan") {
      tiposFiltrados = tipos.filter(tipo => tipo.codigo === "sol_enti");
    } else if (usuario && (usuario.perfil.codigo !== "Per_cred_n1" || usuario.perfil.codigo !== "Per_Nor") && usuario.perfil.codigo !== "Per_Adm") {
      tiposFiltrados = tipos.filter(tipo => tipo.codigo !== "sol_enti");
    }

    this.setState({ periodo: periodo.length > 0 ? periodo[0].nombre : '', creditos_activos, tipos : tiposFiltrados.sort((a, b) => a.valora - b.valora), cargando: false })
    }
    
  async esEstudiante() {
    let {usuario} = this.props;
    if (usuario && usuario.perfil && !(usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "Per_cred_n1")) {
      this.setState({ estudiante: true })
    }
  }


  async obtenerHistorial() {
    this.setState({ cargando: true })
    let historial = await this.obtenerCreditos();
    this.setState({ historial, cargando: false })
  }

  async obtenerDataProcesoActual(id = 0) {
    this.setState({ cargando: true })
    let data = await this.obtenerCredito(id);
    if (data) this.setState({ linea_estados: data.linea, credito: data.credito });
    this.setState({ cargando: false });
  }

  async obtenerCredito(id = 0) {
    return new Promise(resolve => {
      consulta(`api/v1.0/creditos/${id}/detalle`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async obtenerCreditos(filtros = []) {
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`api/v1.0/creditos/0?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  guardarCredito = async (data, callback) => {
    let { tipoSolicitud } = this.state;
    let { mostrarModalAddCredito, actualizarMensaje } = this.props;
    let form = await crear_form_data(data);
    form.append(`total`, data.archivos.length);
    form.append(`tipo`, tipoSolicitud.id);
    data.archivos.map((file, index) => form.append(`archivo${index}`, file));
    formulario(`api/v1.0/creditos/crear`, form, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalAddCredito(false);
          this.setState({ seleccion: 'proceso' });
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      callback()
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }

  guardarCreditodirecto = async (data, callback) => {
    let { tipoSolicitud } = this.state;
    let { actualizarMensaje,mostrarModalFormuDirecto} = this.props;
    let form = await crear_form_data(data);
    form.append(`total`, data.archivo.length);
    form.append(`tipo`, tipoSolicitud.id);
    data.archivo.map(({archivo}, index) => form.append(`archivo${index}`, archivo));
    formulario(`api/v1.0/creditos/directocrear`, form, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalFormuDirecto(false);
          callback()
          this.setState({ seleccion: 'proceso' });
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
    
  }

  pintarProcesosActivos() {
    let { creditos_activos } = this.state;
    if (creditos_activos.length === 0) return <div className='pointer' onClick={() => this.setState({ seleccion: 'agregar' })}><TareasTerminadas mensaje='No tienes créditos activos, clic aquí para solicitar tu crédito' marginTop='7%' imagen={emma_s} widthImg="7%" /></div>;
    return (
      <div style={{ padding: '2% 2% 0% 2%' }} >
        <Grid container direction={'row'} spacing={1} >
          <TituloAccion principal='' alterno='¡ Estas son tus solicitudes para este periodo !' />
          {
            creditos_activos.map((data, i) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                  <Paper elevation={1} onClick={() => this.setState({ enListado: false, id_credito: data.id, tipo_proceso_actual : data.tipo.codigo, estado_actual_credito: data.estado_actual.codigo })} className='pointer'>
                    <CreditoActivo categoria={data.categoria} titulo={data.tipo.nombre} fecha={data.fecha_registro} descripcion={`Tu proceso de ${(data.tipo.nombre).toLowerCase()} se encuentra ${data.estado_actual.codigo === 'Cre_ProRea' ? ' en' : ''} ${(data.estado_actual.nombre).toLowerCase()}. Clic aquí para obtener mas información.`} estado={data.estado_actual} tipo={data.tipo.codigo}/>
                  </Paper>
                </Grid>
              )
            })
          }
        </Grid>
      </div>
    )
  }

  pintarHistorial() {
    let { historial } = this.state;
    let { mostrarModalDetalleCredito } = this.props;
    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Cre_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Cre_Rev': { 'backgroundColor': '#ffc107', color },
        'Cre_Rec': { 'backgroundColor': '#b71c1c', color },
        'Cre_Car': { 'backgroundColor': '#009688', color },
        'Cre_Val': { 'backgroundColor': '#0d47a1', color },
        'Cre_Pen': { 'backgroundColor': '#02A6F2', color },
        'Cre_Ege': { 'backgroundColor': '#e65100', color },
        'Cre_Pag': { 'backgroundColor': '#6200ea', color },
        'Cre_Ges': { 'backgroundColor': '#bdbdbd', color },
        'Cre_Pfac': { 'backgroundColor': '#F5951B', color },
        'Cre_Rech': { 'backgroundColor': '#b71c1c', color },
        'Cre_Afac': { 'backgroundColor': '#1B8FF5', color },
        'Cre_ProRea': { 'backgroundColor': '#06df3e', color }
      }
      return (colores[codigo])
    }

    const acciones = (data) => {
      const onClickDetalle = ({ id, tipo, estado_actual }) => {
        this.setState({ id_credito: id, vistaDetalle: 'credito', tipo_proceso_actual : tipo.codigo, estado_actual_credito: estado_actual.codigo })
        mostrarModalDetalleCredito(true)
      }
      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
      return detalle;
    }

    return (
      <ListarDatos
        datos={historial}
        titulo='Historial de Procesos Financieros'
        id='tbl_historial_credito'
        opciones={true}
        acciones={(data) => acciones(data)}
        buscar={true}
        avatar={({ estado_actual }) => estado_actual.valora}
        color_avatar={({ estado_actual }) => obtenerColor(estado_actual.codigo)}
        fila_principal={({ tipo }) => tipo.nombre}
        filas={[
          { 'id': 'entidad', 'mostrar': ({ entidad }) => entidad && entidad.codigo != 'Ent_Def' && entidad.codigo != 'Ent_Na' ? entidad.nombre : '' },
          { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha Registro : ' },
          { 'id': 'estado_actual', 'mostrar': ({ estado_actual }) => estado_actual.nombre },
        ]}
      />
    )
  }

  pintarProcesoSeleccionado = () => {
    let { mostrarModalDetalleCredito,  mostrarModalEncuestaCredito } = this.props;
    let { credito, tipo_proceso_actual, encuesta } = this.state;

    const ejecutarAccion = vistaDetalle => {
      this.setState({ vistaDetalle })
      mostrarModalDetalleCredito(true);
    }

    const ejecutarEncuesta = vistaEncuesta => {
      this.setState({vistaEncuesta})
      mostrarModalEncuestaCredito(true);
    }
    if (credito) {
      return (
        <div className='' >
          <Button onClick={() => this.setState({ enListado: true, tipo_proceso_actual : '', estado_actual_credito: '' })} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon /> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Atras </Typography> </Button>
          <div style={{ padding: '0% 2% 0% 2%' }} >
            <Grid container direction={'row'} spacing={1} >
              <Grid item xs={12} sm={12} md={6} >
                <TituloAccion principal='' alterno='¡ Información Básica !' />
                <Paper elevation={0} square className="scroll">
                  <CreditoDetalleSimple credito={credito} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
                <Paper elevation={0} square className="scroll">
                  <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                    <Grid item xs={12} sm={12} md={12} >
                      <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAccion('credito')}>
                        <AccionesRapidas imagen={revisar} titulo='Más Información !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes ver toda la información de tu proceso.' />
                      </Paper>
                    </Grid>
                    {
                      tipo_proceso_actual && tipo_proceso_actual !== 'sol_dev' && tipo_proceso_actual !== 'sol_conestfin' &&
                      <Grid item xs={12} sm={12} md={12} >
                        <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAccion('adjuntos')}>
                          <AccionesRapidas imagen={files} titulo='Mis Documentos !' sub_titulo='Listo' des_sub_titulo=', Aquí puedes descargar y adjuntar tus documentos.' />
                        </Paper>
                      </Grid>
                    }
                    { credito.tipo.codigo === 'sol_cred' && credito.tipo.codigo !== 'sol_conestfin' &&
                    <Grid item xs={12} sm={12} md={12} >
                      <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAccion('pagos')}>
                        <AccionesRapidas imagen={dinero} titulo='Pagos Solicitados !' sub_titulo='Excelente' des_sub_titulo=', verifica aquí los pagos solicitados a la entidad financiera.' />
                      </Paper>
                    </Grid>
                    }{ credito.estado_actual.codigo === 'Cre_Car' &&
                    <Grid item xs={12} sm={12} md={12}>
                      <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarEncuesta('encuestas')}>
                        <AccionesRapidas 
                          imagen={test} 
                          titulo='Encuestas !' 
                          sub_titulo='Realiza!' 
                          des_sub_titulo=', tu encuesta de satisfacción'
                        />
                      </Paper>
          </Grid> }
                    {/* <Grid item xs={12} sm={12} md={12} >
                      <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAccion('estados')}>
                        <AccionesRapidas imagen={email} titulo='¿ Tienes alguna duda ?' sub_titulo='No te preocupes' des_sub_titulo=', enviale un mensaje a tu asesor de crédito.' />
                      </Paper>
                    </Grid> */}
                  </Grid>
                </Paper>
              </Grid>
              {
                credito.estado_actual.codigo !== 'Cre_Rec' &&
                <Grid item xs={12} sm={12} md={12} >
                  <TituloAccion principal='' alterno='¿ Como va mi proceso ?' />
                  {this.pintarTiempo()}
                </Grid>
              }
            </Grid>
          </div >
        </div>
      )
    }
  }

  pintarTiempo = () => {
    let { cargando, linea_estados, credito } = this.state;
    if (!cargando && credito && linea_estados.length > 0) {
      let activeStep = 0;
      let steps = [];
      linea_estados.map(({ secundario }, i) => {
        if (secundario.id === credito.estado_actual.id) activeStep = i;
        steps.push({ label: secundario.nombre })
      })
      activeStep = credito.estado_actual.codigo === 'Cre_Car' ? linea_estados.length + 1 : activeStep;
      return <BarraProceso steps={steps} activeStep={activeStep} />;
    }
    return '';
  }

  pintarOpcionesMenu = () => {
    let { actualizarMensaje, modalAddCredito, mostrarModalAddCredito,mostrarModalFormuDirecto } = this.props;
    let { tipos, tipoSolicitud } = this.state;

    const tipoSeleccion = tipoSolicitud => {
      this.setState({ tipoSolicitud })
      
      switch (tipoSolicitud.codigo) {
        case 'sol_cred':
          this.setState({ menu: false });
          break;
        case 'sol_lega':
          mostrarModalAddCredito(true);
          break;
        case 'sol_desc':
          mostrarModalAddCredito(true);
          break;
        case 'sol_cong':
          mostrarModalAddCredito(true);
          break;
        case 'sol_enti':
          mostrarModalAddCredito(true);
            break;
        case 'sol_directo':
          this.setState({ menu: false });
          //mostrarModalFormuDirecto(true);
          break;
        case 'sol_dev':
          mostrarModalAddCredito(true);
          break;
        case 'sol_pazsalvo':
          mostrarModalAddCredito(true);
          break;
        case 'sol_conestfin':
          mostrarModalAddCredito(true);
          break;
      }
    }

    return (
      <>
        <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 70 }}  >
          {tipos.length > 0 ?
            tipos.map((tipo) => (
              <Grid item xs={12} md={6} lg={4} style={{ padding: '2% 2% 0% 2%' }} key={tipo.codigo}>
                <Paper elevation={8} square onClick={() => tipoSeleccion(tipo)} >
                  <CreditoOpcionesTipo
                    // imagecss={{ width: '170px', maxWidth: '200px', minWidth: '150px', height: "170px" }}
                    avatarcss={{ backgroundColor: red[500] }}
                    data={tipo}componentDidUpdate
                    image={tipo.archivo}
                  />
                </Paper>
              </Grid>
            ))
            : <TareasTerminadas mensaje="Cargando ..." marginTop="7%" imagen={emma_w} widthImg="7%" />}
        </Grid>
        
        {tipos.length > 0 &&
          <CreditoFormAgregar
            actualizarMensaje={actualizarMensaje}
            guardarCredito={this.guardarCredito}
            categoria={''}
            tipo={tipoSolicitud.valorc}
            titulo={`¡ ${tipoSolicitud.nombre ? tipoSolicitud.nombre.charAt(0) + tipoSolicitud.nombre.slice(1).toLowerCase() : ''} !`}
            modalAddCredito={modalAddCredito}
            mostrarModalAddCredito={mostrarModalAddCredito}
            tipoSolicitud={tipoSolicitud} />
        }
      </>
    );
  };

  pintarMenuInicial() {
    let { menu } = this.state;
    return menu ? this.pintarOpcionesMenu() : this.pintarAgregar();
  }

  pintarProcesoActual() {
    let { enListado } = this.state;
    return enListado ? this.pintarProcesosActivos() : this.pintarProcesoSeleccionado();
  }

  pintarAgregar() {
    let { tipoSolicitud} = this.state;
    let { mostrarModalAddCredito, modalAddCredito, actualizarMensaje} = this.props;
    return (
      <div>
        <CreditoAgregar 
          mostrarModalAddCredito={mostrarModalAddCredito} 
          modalAddCredito={modalAddCredito} 
          guardarCredito={this.guardarCredito}
          guardarCreditodirecto={this.guardarCreditodirecto} 
          actualizarMensaje={actualizarMensaje} 
          setMenu={() => this.setState({menu : true})} 
          tipoSolicitud={tipoSolicitud}
        />
      </div>
    )
            
  }

  render() {
    let { seleccion, cargando, id_credito,vistaEncuesta, vistaDetalle, tipo_proceso_actual, estado_actual_credito, estudiante } = this.state;
    let { modalDetalleCredito, modalEncuestaCredito, mostrarModalEncuestaCredito, mostrarModalDetalleCredito, modalAddAdjuntosCredito, mostrarModalAdjuntoCredito, actualizarMensaje} = this.props;
    return (
      <div>
        <MenuEstudiante
          seleccionar={(seleccion) => this.setState({ seleccion })}
          sele={seleccion}
        />
        <div className='contenido_modulo'>
          <Credito 
            actualizarMensaje={actualizarMensaje} 
            modalAddAdjuntosCredito={modalAddAdjuntosCredito} 
            mostrarModalAdjuntoCredito={mostrarModalAdjuntoCredito} 
            modalDetalleCredito={modalDetalleCredito} 
            modalEncuestaCredito={modalEncuestaCredito}
            mostrarModalEncuestaCredito ={mostrarModalEncuestaCredito}
            mostrarModalDetalleCredito={mostrarModalDetalleCredito} 
            credito_id={id_credito}
            vistaDetalle={vistaDetalle} 
            vistaEncuesta ={vistaEncuesta}
            tipo_proceso_actual={tipo_proceso_actual} 
            estado_actual_credito={estado_actual_credito}
            estudiante={estudiante}        
            />
          {
            !cargando
              ?
              <div>
                {seleccion === 'proceso' && this.pintarProcesoActual()}
                {seleccion === 'agregar' && this.pintarMenuInicial()}
                {seleccion === 'historial' && this.pintarHistorial()}
              </div>
              : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
        </div>
      </div>
    )
  }
}


//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let {usuario} = state.redGlobal;
  let { modalAddCredito, modalDetalleCredito, modalEncuestaCredito, modalAddAdjuntosCredito,ModalFormuDirecto } = state.redCreditos;
  return {
    modalAddCredito,
    modalDetalleCredito,
    modalEncuestaCredito,
    modalAddAdjuntosCredito,
    ModalFormuDirecto,
    usuario
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModulo,
  mostrarModalAddCredito,
  mostrarModalDetalleCredito,
  mostrarModalEncuestaCredito,
  mostrarModalAdjuntoCredito,
  mostrarModalFormuDirecto
}


export default connect(mapStateToProps, mapDispatchToProps)(CreditosEstudiantes);