import { Avatar, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Tooltip, Typography } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
//FUNCIONES GENERALES
import { api, coloresEmma, consulta, crear_form_data, formulario, generarFiltros, guardarPermisoValorGenerica, guardarValorGenerica, modificarValorGenerica, modificarValorGenericaAlter, mostrarError, obtenerValores, obtenerValoresFiltros, obtenerValoresFiltrosContenido, validarNIT } from "../../../global/js/funciones";
//COMPONENTES DEL MODULO
import PracticasAccionesMasivas from '../PracticasAccionesMasivas';
import PracticasAdjuntos from "../PracticasAdjuntos";
import PracticaInformeEmpresa from './PracticaInformeEmpresa';
import PracticasFiltrar from "./PracticasFiltrar";
import PracticasGestionar from "./PracticasGestionar";
import PracticasListar from "./PracticasListar";
import PracticasMasivoFiltrar from "./PracticasMasivoFiltrar";
//ACCIONES DEL MODULO
import { orange } from '@material-ui/core/colors';
import moment from 'moment';
import sucursalesIcon from '../../../global/imagenes/architecture-and-city.png';
import fechaIcon from '../../../global/imagenes/calendar.png';
import mensajesIcon from '../../../global/imagenes/email.png';
import emma_s1 from '../../../global/imagenes/emma_s1.png';
import emma_w from '../../../global/imagenes/emma_w.png';
import vistaIcon from '../../../global/imagenes/hourglass.png';
import permisosIcon from '../../../global/imagenes/lightbulb.png';
import NotificaIcon from '../../../global/imagenes/noti1.png';
import NotificaIcon2 from '../../../global/imagenes/noti2.png';
import NotificaIcon3 from '../../../global/imagenes/noti3.png';
import NotificaIcon4 from '../../../global/imagenes/noti4.png';
import NotificaIcon5 from '../../../global/imagenes/noti5.png';
import reportIcon from '../../../global/imagenes/report.png';
import trasladoIcon from '../../../global/imagenes/switch.png';
import empresasIcon from '../../../global/imagenes/work.png';
import { mostrarModalAdd } from "../../../redux/actions/actGenericas";
import { actualizarMensaje, mostrarModalAdjFaltantes, mostrarModulo } from "../../../redux/actions/actGlobal";
import { actualizarDataAdjuntosPractica, actualizarDataEmpresa, actualizarDataEmpresas, actualizarDataEstado, actualizarDataEstados, actualizarDataPractica, actualizarDataPracticas, actualizarDataSucursal, actualizarDataSucursales, actualizarRenderPracticas, mostrarModalAccionesMasivasPractica, mostrarModalAddAdjunto, mostrarModalAddEmpresa, mostrarModalAddEmpresaProceso, mostrarModalAddFiltros, mostrarModalAddFiltrosHv, mostrarModalAddSucursal, mostrarModalAddValoresSucursal, mostrarModalAdmAjuntosPractica, mostrarModalAdmBuscarEmpresa, mostrarModalAdmBuscarEmpresaotravez, mostrarModalAyuda, mostrarModalCanPracticas, mostrarModalDelEmpresa, mostrarModalDelSucursal, mostrarModalDetallePractica, mostrarModalEmpresasHV, mostrarModalEnviarHV, mostrarModalFiltroId, mostrarModalGestionarPractica, mostrarModalInforme, mostrarModalIniActa, mostrarModalMasivoFiltro, mostrarModalModEmpresa, mostrarModalModPeriodo, mostrarModalModSucursal, mostrarModalPermisoEstado, mostrarModalPermisoModalidad, mostrarModalProParticipante } from "../../../redux/actions/actPracticas";
import { actualizarDataPermisos, actualizarDataValor, mostrarModalAddPermisoValores, mostrarModalAddValores, mostrarModalDetValores } from "../../../redux/actions/actValores";
import AccionesRapidas from '../../general/AccionesRapidas';
import AppBarModal from '../../general/AppBarModal';
import { BtnCargar, BtnDescargar, BtnEditar, BtnForm, BtnRegresar, BtnSeleccion, BtnVer } from "../../general/BotonesAccion";
import BuscarPersona from '../../general/BuscarPersona';
import ConfirmarAccion from "../../general/ConfirmarAccion";
import EnviarMensajeFecha from "../../general/EnviarMensajeFecha";
import EnviarMultiplesArchivos from "../../general/EnviarMultiplesArchivos";
import InputBuscar from '../../general/InputBuscar';
import InputBuscarText from '../../general/InputBuscarText';
import ListarDatos from '../../general/ListarDatos';
import ListarTarjetas from '../../general/ListarTarjetas';
import TareasTerminadas from '../../general/TareasTerminadas';
import TituloAccion from '../../general/TituloAccion';
import PracticaEmpresa from "../PracticaEmpresa";
import PracticaIniciarActa from "../PracticaIniciarActa";
import PracticasPendientes from "../PracticasPendientes";
import PracticasEmpresas from "../empresas/PracticasEmpresas";
import { GRUPO_PRACTICAS, TIPO_INFORME_PRACTICAS } from '../helper';
import PracticasHV from "../hojas_vida/PracticasHV";
import PracticasSucursales from "../sucursales/PracticasSucursales";
import MenuPracticas from "./MenuPracticas";
import Practica from "./Practica";
import PracticaInforme from "./PracticaInforme";
import PracticaInformeSeguimiento from './PracticaInformeSeguimiento';
import PracticaTraslado from "./PracticaTraslado";
import PracticasCoordinadores from "./PracticasCoordinadores";
import PracticasEstadosPermisos from "./PracticasEstadosPermisos";
import PracticasEstudiantes from "./PracticasEstudiantes";
import PracticasModalidadPermisos from "./PracticasModalidadPermisos";
import PracticasProgramas from "./PracticasProgramas";

export class PracticasAdministrar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      input_empresa: '',
      input_sucursal: '',
      administrador: false,
      enNotificacion: '',
      adj_faltantes: [],
      notificaciones: [],
      reportes: [],
      tenviado: 0,
      trevision: 0,
      tvisitas: 0,
      taceptados: 0,
      data_confirmar: {
        titulo: '',
        ejecutarAccion: () => alert("Acción"),
      },
      dato_buscar: '',
      periodo: '',
      fecha_periodo: '',
      filtros_practica: [],
      cargando: true,
      accion: 'procesos',
      accionAdm: 'inicio',
      seleccion: 0,
      coordinadores: [],
      estudiantes: [],
      estudianteshv: [],
      filtro_alt: [],
      coordinador: {
        id: 0
      },
      acta: {
        id: 0,
        nombre: '',
      },
      datos_acuerdos: [],
      accionProceso: 'inicio',
      estados_procesos : [],
      filtrarPeriodo : false,
      periodo_filtro : null,
      periodos : [],
      cargandoPeriodo: true,
      cargarFiltroP : true,
      cargandoPendientes: true,
      cargandoNotif: true,
      informes : [],
      informe: {'nombre' : '', 'codigo' : ''},
      modalTraslado: false,
      seleccionMasiva: [],
      estadoSeleccion: false,
      estadoGestionar:0,
      inputidentificacion:'',
      buscarEstudiante: false,
      estudiante: {
        id: 0,
        nombre: ''
      },
      grupos_practicas: [],
      seleccionProc: '',
      tipoSolicitud: '',
      tipoSolicitudFiltro: '',
    }
  }


  async componentDidUpdate({ modalAdjFaltantes,modalfiltroid }, { accion, seleccion, accionAdm, accionProceso }) {
    let { accion: accionNew, seleccion: seleccionNew, periodo, enNotificacion: enNotificacionNew, accionAdm: accionAdmNew, accionProceso: accionProcesoNew, periodo_filtro, tipoSolicitud } = this.state;
    let { modalAdjFaltantes: modalAdjFaltantesNew,modalfiltroid:modalfiltroidNew } = this.props;
    if (accion !== accionNew) {
      this.cambiarEstado({ cargando: true, periodo_filtro: periodo })
      if (accionNew === 'procesos' && enNotificacionNew !== 'si') this.obtenerEstadoSolicitudes(periodo);
      if (accionNew === 'estudiantes') this.actualizarDataEstudiantes();
      if (accionNew === 'hojas') this.actualizarDataHV();
      if (accionNew === 'administrar') this.setState({ cargando: false });
      this.setState({accionProceso: 'inicio', seleccionProc: '', filtros_practica: [] })
    }
    if (accionAdm !== accionAdmNew) {
      this.cambiarEstado({ cargando: true })
      if (accionAdmNew === 'permisos') this.actualizarDataCoordinadores();
      if (accionAdmNew === 'empresas') this.obtenerEmpresas(this.state.input_empresa);
      if (accionAdmNew === 'sucursales') this.obtenerSucursales(this.state.input_sucursal);
      if (accionAdmNew === 'vista') this.obtenerReportes();
      if (accionAdmNew === 'informes') this.obtenerInformes()
      if (accionAdmNew === 'traslado') this.actualizarDataCoordinadores();
      if (accionAdmNew === 'inicio') this.setState({ cargando: false });

    }

    if (seleccion !== seleccionNew) this.cambiarEstado({ 'accion': 'en_curso' })
    if (modalAdjFaltantesNew !== modalAdjFaltantes && modalAdjFaltantesNew) this.obtenerAdjFaltantes();
    if (modalfiltroid !== modalfiltroidNew ) this.setState({estudiante: {id: 0,nombre: ''}})

    if (accionProceso !== accionProcesoNew) {
      if (accionProcesoNew != 'Tod_Pra' && accionProcesoNew !== 'inicio') {
        this.cambiarEstado({ cargando: true })
        let aux = (tipoSolicitud == 'grupo_cuc' ? 0 : 1)
        this.setState({ tipoSolicitudFiltro: aux })
        if (accionProcesoNew == tipoSolicitud) this.obtenerEstadoSolicitudes(periodo_filtro, aux)
        else if (accionProcesoNew !== tipoSolicitud && accionProcesoNew !== 'inicio') this.obtenerPracticas([{ 'llave': 'estado_actual__codigo', 'valor': accionProcesoNew }, { 'llave': 'periodo', 'valor': periodo_filtro }, {'llave': 'tipoSolicitud', 'valor': aux}]);
      }
    }
  }


  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/practicas', nombre: 'practicas' })
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Pra' }]);
    let grupos_practicas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': GRUPO_PRACTICAS }]);
    this.cambiarEstado({ 'periodo': periodo[0].nombre, 'fecha_periodo': periodo[0].valora, 'periodo_filtro': periodo[0].nombre, grupos_practicas: grupos_practicas.sort((a, b) => a.valora - b.valora ) })
    this.obtenerAlertas(periodo[0].nombre, periodo[0].valora);
    this.esAdministrador();
    let datos = await this.obtenerDatosAcuerdos();
    this.obtenerEstadoSolicitudes(periodo[0].nombre);
    this.setState({ datos_acuerdos: datos.acuerdos });
  }


  obtenerDatosAcuerdos = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/practica/acuerdos`, null, null, (error, estado, resp) => {
        resolve(resp)
      })
    })
  }

  obtenerValoresFiltrosPracticas = async (filtros = []) => {
    // Fragmento de la cadena de consulta para cada filtro
    const construirParametro = (llave, valor) => {
        if (Array.isArray(valor)) {
            // Si valor es un array, convertirlo en múltiples parámetros
            return valor.map(v => `${llave}=${encodeURIComponent(v)}`).join('&');
        } else {
            return `${llave}=${encodeURIComponent(valor)}`;
        }
    };
    // Construir la cadena de consulta completa
    const queryString = filtros.map(({ llave, valor }) => construirParametro(llave, valor)).join('&');
    return new Promise((resolve, reject) => {
        consulta(
            `api/v1.0/practicas/valores/filtros/?${queryString}`,
            null,
            null,
            (error, estado, resp) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(resp);
                }
            },
            false
        );
    });
};
  obtenerEstadoSolicitudes = async (periodo, tipo) => {
    console.log(periodo);
    console.log(tipo);
    let url = `api/v1.0/practicas/estados/${periodo}`;
    if (tipo !== undefined) {
      url += tipo;
    }
    consulta(url, null, null, (error, estado, resp) => {
      if (estado === 200) {
        let { general, extra } = resp;
        if (extra.length > 0) {
          extra.map(({ solicitudes, nombre, codigo, orden }) => {
            let reporte = {
              'estado_actual__codigo': codigo,
              'estado_actual__nombre': nombre,
              'num_sol': solicitudes,
              'orden': orden
            };
            general.push(reporte);
          });
        }
        this.cambiarEstado({ estados_procesos: general.sort((a, b) => a.orden - b.orden ), cargando: false });
      }
    });
  };
  

  esAdministrador() {
    let { usuario } = this.props;
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "Per_Pra_n1" || usuario.perfil.codigo === "Per_Pra_Adm")) {
      this.setState({ administrador: true })
    }
  }

  configurarAccionConf = data_confirmar => this.setState({ data_confirmar });

  async obtenerReportes() {
    let { general, empresarial, trabajan, empresa } = await this.obtenerTotales();
    if (trabajan.length > 0) {
      trabajan.map(({ trabaja, count }) => {
        let reporte = {
          'estado_actual__valorc': trabaja === '0' ? 'N' : 'S',
          'estado_actual__codigo': trabaja === '0' ? 'noUbicar' : 'ubicar',
          'tipo__nombre': trabaja === '0' ? 'NO TRABAJAN' : 'TRABAJAN',
          'estado_actual__nombre': 'Total',
          count
        }
        general.push(reporte);
      })
    }

    if (empresarial.length > 0) {
      empresarial.map(({ busca_empresa, count }) => {
        let reporte = {
          'estado_actual__valorc': busca_empresa === '0' ? 'N' : 'S',
          'estado_actual__codigo': busca_empresa === '0' ? 'noUbicar' : 'ubicar',
          'tipo__nombre': busca_empresa === '0' ? 'NO REQUIEREN UBICACIÓN' : ' REQUIEREN UBICACIÓN',
          'estado_actual__nombre': 'Total',
          count
        }
        general.push(reporte);
      })
    }

    if (empresa.length > 0) {
      empresa.map(({ count }) => {
        let reporte = {
          'estado_actual__valorc': 'S',
          'estado_actual__codigo': 'ubicar',
          'tipo__nombre': 'ESTUDIANTES POR UBICAR',
          'estado_actual__nombre': 'Total',
          count
        }
        general.push(reporte);
      })
    }

    this.setState({ cargando: false, reportes: general });
  }

  obtenerInformes = async () => {
    let { usuario } = this.props
    let informes = []
    this.setState({cargando : true})
    if(usuario.perfil.codigo == 'Per_Adm' || usuario.perfil.codigo == 'Per_Pra_Adm') {
      let informes1 = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPO_INFORME_PRACTICAS }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'admin'}]);
      let informes2 = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPO_INFORME_PRACTICAS }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'todos'}]);
      informes = informes1.concat(informes2);
    }else{
      informes = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPO_INFORME_PRACTICAS }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'todos'}]);
    }
    this.setState({informes, cargando : false})
  }

  obtenerPracticas = async (filtros_practica = []) => {
    let { actualizarDataPracticas, actualizarRenderPracticas,actualizarMensaje } = this.props;
    console.log(filtros_practica)
    let f = await generarFiltros(filtros_practica);
    // this.cambiarEstado({ cargando: true });
    this.setState({cargando: true })
    consulta(`api/v1.0/practicas/listar/1?${f}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          actualizarDataPracticas(resp);
          actualizarRenderPracticas(true);
          this.cambiarEstado({ cargando: false, filtros_practica });
        }
      }
    );
  }
  obtenerPracticasSinCancelado = async (filtros_practica = []) => {
    let { actualizarDataPracticas, actualizarRenderPracticas,actualizarMensaje } = this.props;
    console.log(filtros_practica)
    let f = await generarFiltros(filtros_practica);
    this.cambiarEstado({ cargando: true });
    consulta(`api/v1.0/practicas/listarsincancelado/1?${f}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          actualizarDataPracticas(resp);
          actualizarRenderPracticas(true);
          this.cambiarEstado({ cargando: false, filtros_practica });
        }
      }
    );
  }
  async obtenerPersonaIdentificacion(identi) {
    return new Promise(resolve => {
      consulta(`api/v1.0/practicas/persona/${identi}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }
  obtenerColor = (codigo) => {
    let color = 'white';
    const colores = {
      'Est_Pra_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
      'Est_Pra_Ini': { 'backgroundColor': '#01579b', color },
      'Est_Pra_Rec': { 'backgroundColor': '#b71c1c', color },
      'Est_Pra_Fin': { 'backgroundColor': '#009688', color },
      'Est_Pra_Rev': { 'backgroundColor': '#ffc107', color },
      'Est_Pra_Acep': { 'backgroundColor': '#1a237e', color },
      'Est_Pra_Vis': { 'backgroundColor': '#6200ea', color },
      'Est_Pra_Vif': { 'backgroundColor': '#6200ea', color },
      'Est_Pra_Vio': { 'backgroundColor': '#6200ea', color },
      'ubicar': { 'backgroundColor': '#e65100', color },
      'noUbicar': { 'backgroundColor': '#0d47a1', color },
    }
    return (colores[codigo])
  }


  modificarPractica = (id, data, callback) => {
    let { actualizarMensaje } = this.props;
    consulta(`api/v1.0/practicas/${id}`, data, "patch", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          callback();
          this.obtenerPracticas(this.state.filtros_practica);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);;
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }
  modificarPracticaotravez = (id, data, callback) => {
    let { actualizarMensaje } = this.props;
    consulta(`api/v1.0/practicas/modificar/${id}`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          callback();
          this.obtenerPracticas(this.state.filtros_practica);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);;
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }

  modificarPracticaEmpresa = (data) => {
    let { actualizarMensaje, practica, mostrarModalAddEmpresaProceso } = this.props;
    formulario(`api/v1.0/practicas/${practica.id}/empresa`, data, "patch", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalAddEmpresaProceso(false);
          // this.obtenerPracticas(this.state.filtros_practica);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);;
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }


  obtenerAdjFaltantes = () => {
    let { practica } = this.props;
    consulta(`api/v1.0/practicas/${practica.id}/adjuntos/faltantes`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          this.trasnformarDataAdj(resp);
          this.setState({ cargandoPendientes: false })
        }
      }
    );
  }

  trasnformarDataAdj = (data) => {
    let adj_faltantes = [];
    data.map(({ secundario: { nombre, descripcion, id, archivo, valorc, valord, valorf, valorg } }) => {
      adj_faltantes.push({ nombre, descripcion: descripcion.split(';')[0], codigo: id, archivo, tipo: valorc, estudiante: valord, valorf, formatos: valorg });
      return true;
    })
    this.setState({ adj_faltantes });
  }

  gestionarPractica = async (id, data, callback) => {
    let { mostrarModalGestionarPractica, actualizarMensaje, mostrarModalAdmAjuntosPractica } = this.props
    let form = await crear_form_data(data);
    data.archivos_adicional.map((file, index) => form.append(`archivo${index}`, file));
    formulario(`api/v1.0/practicas/${id}/gestionar`, form, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalAdmAjuntosPractica(false);
          mostrarModalGestionarPractica(false);
          this.obtenerPracticas(this.state.filtros_practica);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      callback();
      this.setState({estadoGestionar:estado})
    })
  }

  gestionarEstadoMasivos = async (data, callback) => {
    let { mostrarModalGestionarPractica, actualizarMensaje, mostrarModalAdmAjuntosPractica } = this.props
    let form = await crear_form_data(data);
    data.archivos_adicional.map((file, index) => form.append(`archivo${index}`, file));
    formulario(`api/v1.0/practicas/masivo/gestionar`, form, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalAdmAjuntosPractica(false);
          mostrarModalGestionarPractica(false);
          this.obtenerPracticas(this.state.filtros_practica);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      callback();
    })
  }

  guardarAdjuntosNuevos = (archivos, callback) => {
    let { actualizarMensaje, practica } = this.props;
    const data = new FormData();
    archivos.map(({ archivo, codigo }, index) => {
      data.append(`archivo${index}`, archivo);
      data.append(`codigo${index}`, codigo);
      return true;
    });
    data.append('total', archivos.length);
    formulario(`api/v1.0/practicas/${practica.id}/adjuntos/nuevos`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.obtenerAdjuntos();
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      callback();
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }

  gestionarMasivos = (tipo, adjuntos, motivo = '', callback) => {
    let { actualizarMensaje, practica } = this.props
    consulta(`api/v1.0/practica/${practica.id}/adjuntos/validar`, { adjuntos, tipo, motivo }, "put",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
          this.obtenerAdjuntos();
          callback();
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      })
  }


  obtenerAdjuntos = async (validacion = null) => {
    let { actualizarDataAdjuntosPractica, practica } = this.props;
    let f = [{ 'llave': 'estado_pr__practica__id', 'valor': practica.id }];
    if (validacion !== null) f.push({ 'llave': 'validacion', 'valor': validacion });
    let filtros = await generarFiltros(f);
    this.setState({ cargandoPendientes: true })
    consulta(`api/v1.0/practicas/adjuntos?${filtros}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          actualizarDataAdjuntosPractica(resp);
          this.obtenerAdjFaltantes();
        }
      }
    );
  }

  obtenerTotales = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/practicas/totales`, null, null,
        (error, estado, resp) => {
          resolve(resp);
        }
      )
    })
  }

  obtenerEstadosPractica = (callback = () => { }) => {
    let { actualizarDataEstados, practica } = this.props;
    consulta(`api/v1.0/practicas/estados?practica=${practica.id}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          actualizarDataEstados(resp);
          callback();
        }
      }
    );
  }

  obtenerHV = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/practicas/estudianteshv`, null, null,
        (error, estado, resp) => {
          resolve(resp);
        }
      )
    })
  }

  obtenerCoordinadores = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/practicas/gestores`, null, null,
        (error, estado, resp) => {
          resolve(resp);
        }
      )
    })
  }
  validarVisitasProgramadas = (practica) => {
    return new Promise(resolve => {
      consulta(`api/v1.0/practicas/${practica}/visitas`, null, null,
        (error, estado, resp) => {
          resolve(resp);
        }
      )
    })
  }

  actualizarDataCoordinadores = async () => {
    let coordinadores = await this.obtenerCoordinadores();
    this.cambiarEstado({ coordinadores, cargando: false });
  }

  actualizarDataHV = async () => {
    const transformar = data => {
      return data.map(e => ({
        id: e.practica__usuario_registro,
        identificacion: e.practica__usuario_registro__identificacion,
        primer_nombre: e.practica__usuario_registro__primer_nombre,
        primer_apellido: e.practica__usuario_registro__primer_apellido,
        segundo_apellido: e.practica__usuario_registro__segundo_apellido,
        celular: e.practica__usuario_registro__celular,
        empresas: e.count,
        programa: e.practica__programa__nombre,
      }));
    }
    this.setState({cargando: true})
    let estudianteshv = await this.obtenerHV();
    this.cambiarEstado({ estudianteshv: transformar(estudianteshv), cargando: false });
    this.props.mostrarModalAddFiltrosHv(false)
  }


  actualizarDataEstudiantes = async () => {
    let { matriculados } = await this.matriculados();
    this.cambiarEstado({ estudiantes: matriculados, cargando: false });
  }

  obtenerEmpresas = async (nombre) => {
    this.cambiarEstado({ cargando: true });
    let empresas = []
    if (nombre) empresas = await obtenerValoresFiltrosContenido(nombre, [{ 'llave': 'generica', 'valor': 14 }])
    this.props.actualizarDataEmpresas(empresas);
    this.cambiarEstado({ cargando: false });

  }

  obtenerSucursales = async (nombre) => {
    this.cambiarEstado({ cargando: true });
    let sucursales = [];
    if (nombre) sucursales = await obtenerValoresFiltrosContenido(nombre, [{ 'llave': 'generica', 'valor': 17 }])
    this.props.actualizarDataSucursales(sucursales);
    this.cambiarEstado({ cargando: false });
  }

  matriculados = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/practicas/estudiantes/matriculados`, null, null, (error, estado, resp) => {
        resolve(resp);
      })
    })
  }

  guardarEmpresaFormatoSeleccion = async (codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo, callback = () => { }) => {
    let { actualizarMensaje, mostrarModalAddValores, mostrarModalAddEmpresa } = this.props;
    let nitValido = validarNIT(codigo);
    if (nitValido) {
      let data = await crear_form_data({ codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo });
      formulario(`api/v1.0/practicas/empresa/crear`, data, "post",
        (error, estado, resp) => {
          let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
          if (!error) {
            if (estado === 200) {
              tipo = 'success';
              titulo = resp.titulo;
              mostrarModalAddValores(false);
              mostrarModalAddEmpresa(false)
              callback(resp.empresa, resp.sucursal);
            } else {
              tipo = 'info';
              titulo = resp.titulo ? resp.titulo : mostrarError(resp)
            }
          }
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        });
    } else actualizarMensaje({ titulo: 'NIT invalido, debe tener el siguientes formato 890104530-9', tipo: 'info', mostrar: true, tiempo: 6000 });

  }

  guardarEmpresa = async (codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo) => {
    let { actualizarMensaje, mostrarModalAddEmpresa } = this.props;
    let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
    let nitValido = validarNIT(codigo);
    if (nitValido) {
      let { error, estado, resp } = await guardarValorGenerica(14, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo)
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.setState({ input_empresa: nombre })
          this.obtenerEmpresas(this.state.input_empresa);
          mostrarModalAddEmpresa(false);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp)
        }
      }
    } else {
      titulo = 'NIT invalido, debe tener el siguientes formato 890104530-9';
      tipo = 'info';
    }
    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
  }

  modificarEmpresa = async (id, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo) => {
    let { actualizarMensaje, mostrarModalModEmpresa } = this.props;
    let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
    let nitValido = validarNIT(codigo);
    if (nitValido) {
      let { error, estado, resp } = await modificarValorGenerica(id, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo)
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.obtenerEmpresas(this.state.input_empresa);
          mostrarModalModEmpresa(false);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp)
        }
      }
    } else {
      titulo = 'NIT invalido, debe tener el siguientes formato 890104530-9';
      tipo = 'info';
    }
    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
  }


  eliminarEmpresa = id => {
    let { actualizarMensaje, mostrarModalDelEmpresa } = this.props;
    consulta(`api/v1.0/valores/${id}/eliminar`, {}, "put",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.obtenerEmpresas(this.state.input_empresa);
            mostrarModalDelEmpresa(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp)
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  guardarComentario = (data) => {
    let { actualizarMensaje } = this.props;
    consulta("api/v1.0/comentario/crear", data, "post",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp)
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  enviarActa = (data, callback) => {
    let { actualizarMensaje, practica, mostrarModalIniActa } = this.props;
    let { acta } = this.state;
    consulta(`api/v1.0/practica/${practica.id}/acta/${acta.id}/enviar`, data, "post",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.obtenerAdjuntos();
            mostrarModalIniActa(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp)
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        callback()
      }
    );
  }

  enviarHV = (data) => {
    let { actualizarMensaje, mostrarModalEnviarHV, mostrarModalCanPracticas } = this.props;
    consulta(`api/v1.0/practicas/enviarhv`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.actualizarDataHV();
          mostrarModalEnviarHV(false);
          mostrarModalCanPracticas(false);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp)
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    }
    );
  }


  gestionarHV = (id, data, cargando_modal_emp, callback) => {
    let { actualizarMensaje, mostrarModalCanPracticas, mostrarModalEmpresasHV } = this.props;
    cargando_modal_emp(true)
    consulta(`api/v1.0/practicas/gestionarhv/${id}`, data, "put", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          if (data.contratado == "2") {
            this.actualizarDataHV();
            mostrarModalEmpresasHV(false);
            cargando_modal_emp(false)
          } else callback();
          mostrarModalCanPracticas(false);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp)
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    }
    );
  }

  guardarSucursal = async (codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo) => {
    let { actualizarMensaje, mostrarModalAddSucursal } = this.props;
    let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
    let { error, estado, resp } = await guardarValorGenerica(17, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo)
    if (!error) {
      if (estado === 200) {
        tipo = 'success';
        titulo = resp.titulo;
        mostrarModalAddSucursal(false);
        this.obtenerSucursales(this.state.input_sucursal);
      } else {
        tipo = 'info';
        titulo = resp.titulo ? resp.titulo : mostrarError(resp)
      }
    }
    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
  }


  modificarSucursal = async (id, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo) => {
    let { actualizarMensaje, mostrarModalModSucursal } = this.props;
    let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
    let { error, estado, resp } = await modificarValorGenerica(id, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo)
    if (!error) {
      if (estado === 200) {
        tipo = 'success';
        titulo = resp.titulo;
        this.obtenerSucursales(this.state.input_sucursal);
        mostrarModalModSucursal(false);
      } else {
        tipo = 'info';
        titulo = resp.titulo ? resp.titulo : mostrarError(resp)
      }
    }
    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
  }

  modificarPeriodo = async (periodo, fecha_periodo) => {
    let { actualizarMensaje, mostrarModalModPeriodo } = this.props;
    if (periodo && fecha_periodo) {
      let p = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Pra' }]);
      if (p.length > 0) {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        let { error, estado, resp } = await modificarValorGenericaAlter(p[0].id, { 'nombre': periodo, 'valora': fecha_periodo })
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            mostrarModalModPeriodo(false);
            this.cambiarEstado({ periodo, fecha_periodo, periodo_filtro : periodo});
            this.obtenerAlertas(periodo, fecha_periodo);
            this.setState({ cargando :true})
            this.obtenerEstadoSolicitudes(periodo)
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp)
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      } else actualizarMensaje({ titulo: 'error al cargar el periodo actual', tipo: 'error', mostrar: true, tiempo: 6000 });
    } else actualizarMensaje({ titulo: 'Todos los campos son obligatorios', tipo: 'info', mostrar: true, tiempo: 6000 });
  }

  eliminarSucursal = id => {
    let { actualizarMensaje, mostrarModalDelSucursal } = this.props;
    consulta(`api/v1.0/valores/${id}/eliminar`, {}, "put",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.obtenerSucursales(this.state.input_sucursal);
            mostrarModalDelSucursal(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp)
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  guardarSucursalPermiso = async (codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo) => {
    let { empresa, actualizarMensaje } = this.props;
    let { error, estado, resp } = await guardarValorGenerica(17, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo)
    if (!error) {
      if (estado === 200) {
        this.guardarPermisoSucursalEmpresa(empresa.id, resp.valor_id)
      }else {
        let tipo = 'info';
        let titulo = resp.titulo ? resp.titulo : mostrarError(resp)
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    }
  }
   configSucursal = () => {
    return {
      inputCodigo: { 'label': 'NIT(Ejemplo : 890104530-9)', 'mostrar': false, 'tipo': 'text', 'requerido': false },
      inputNombre: { 'label': 'Direccion', 'mostrar': true, 'tipo': 'text', 'requerido': true },
      inputDescripcion: { 'label': 'Actividad Económica', 'mostrar': false, 'tipo': 'text', 'requerido': false },
      inputValora: { 'label': 'Barrio', 'mostrar': true, 'tipo': 'text', 'requerido': true },
      inputValorb: { 'label': 'Ciudad', 'mostrar': true, 'tipo': 'text', 'requerido': true },
      inputValorc: { 'label': 'Departamento', 'mostrar': true, 'tipo': 'text', 'requerido': true },
      inputValord: { 'label': 'Telefono', 'mostrar': true, 'tipo': 'text', 'requerido': true },
      inputValore: { 'label': 'Fax', 'mostrar': true, 'tipo': 'text', 'requerido': true },
      inputValorf: { 'label': 'Correo', 'mostrar': true, 'tipo': 'text', 'requerido': true },
      inputValorg: { 'label': 'Programa - Area de la empresa', 'mostrar': true, 'tipo': 'text', 'requerido': true },
      inputArchivo: { 'label': 'Adjuntar certificado (Camara de comercio)', 'mostrar': false, 'tipo': 'file', 'requerido': false },
    }
  }
  guardarPermisoSucursalEmpresa = async (empresa, sucursal) => {
    let { actualizarMensaje, mostrarModalAddSucursal } = this.props;
    let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
    let { error, estado, resp } = await guardarPermisoValorGenerica(empresa, sucursal)
    if (!error) {
      if (estado === 200) {
        tipo = 'success';
        titulo = 'Sucursal Agregada';
        mostrarModalAddSucursal(false);
      } else {
        tipo = 'info';
        titulo = resp.Titulo ? resp.Titulo : mostrarError(resp)
      }
    }
    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
  }

  ObtenerNotificacionesProceso = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/practicas/notificaciones`, null, null,
        (error, estado, resp) => {
          resolve(resp);
        }
      )
    })
  }

  async obtenerAlertas(periodo, fecha_periodo) {
    // let mensajes = await this.obtenerDataMensajes();
    this.setState({ cargandoNotif: true })
    let mensajes = [];
    let sol_vencidas = await this.obtenerPracticasAlerta();
    let periodo_noti = await this.notificacionPeriodo(periodo, fecha_periodo);
    let noti_proceso = await this.ObtenerNotificacionesProceso()
    let notificaciones = [];

    const accionesMensajes = (data) => {
      const onClickSeleccionar = ({ id }) => {
        this.gestionarMensaje(id);
      }

      const onClickAbrir = ({ practica }) => {
        this.setState({ accion: 'procesos', accionProceso: 'Tod_Pra' });
        this.obtenerPracticas([{ 'llave': 'id', 'valor': practica }]);
      }

      return <><BtnSeleccion callback={() => onClickSeleccionar(data)} texto='Leído' /> <BtnVer callback={() => onClickAbrir(data)} texto='Ver' /></>;
    }

    const accionesSolicitudes = (data) => {
      const onClickAbrir = ({ id }) => {
        this.setState({ accion: 'procesos', accionProceso: 'Tod_Pra' });
        this.obtenerPracticas([{ 'llave': 'id', 'valor': id }]);
      }
      return <BtnVer callback={() => onClickAbrir(data)} texto='Ver' />;
    }

    if (periodo_noti) notificaciones.push(periodo_noti);

    mensajes.map((data) => {
      let { mensaje, fecha_registro, usuario_registro } = data;
      let notificacion = {
        icono: mensajesIcon,
        titulo: '¡ Nuevo Mensaje !',
        mensaje: `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} dice : ${mensaje}`,
        fecha: `Registrado el ${moment(fecha_registro).format('YYYY-MM-DD, h:mm a')}`,
        tipo: 'mensaje',
        acciones: () => accionesMensajes(data),
      }
      notificaciones.push(notificacion)
    });

    sol_vencidas.map((data) => {
      let { fecha_limite, usuario_registro } = data;
      let notificacion = {
        icono: fechaIcon,
        titulo: `¡ Buscar Empresa !`,
        mensaje: `El tiempo asignado para que el estudiante ${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} encontrara sus practicas ha caducado `,
        fecha: `Disponible hasta ${moment(fecha_limite).format('YYYY-MM-DD, h:mm a')}`,
        tipo: 'mensaje',
        acciones: () => accionesSolicitudes(data),
      }
      notificaciones.push(notificacion)
    })

    //this.setState({ notificaciones });
    noti_proceso.mitad.map((data) => {
      let { usuario_registro } = data;
      let notificacion = {
        icono: NotificaIcon,
        titulo: `¡ Mitad del Proceso !`,
        mensaje: `El estudiante ${usuario_registro.primer_apellido} ${usuario_registro.primer_nombre} se encuentra cerca de la mitad de su proceso de práctica.`,
        tipo: 'mensaje',
        acciones: () => accionesSolicitudes(data),
      }
      notificaciones.push(notificacion)
    })

    noti_proceso.final.map((data) => {
      let { usuario_registro } = data;
      let notificacion = {
        icono: NotificaIcon2,
        titulo: `¡ Final del Proceso !`,
        mensaje: `El estudiante ${usuario_registro.primer_apellido} ${usuario_registro.primer_nombre} se encuentra cerca del final de su proceso de práctica.`,
        tipo: 'mensaje',
        acciones: () => accionesSolicitudes(data),
      }
      notificaciones.push(notificacion)
    })

    noti_proceso.doc_reemp.map((data) => {
      let { estado_pr__practica__usuario_registro__primer_apellido: apellido, estado_pr__practica__usuario_registro__primer_nombre: nombre, estado_pr__practica: id } = data;
      let notificacion = {
        icono: NotificaIcon3,
        titulo: `¡ Documento Reemplazado !`,
        mensaje: `El proceso del estudiante ${apellido} ${nombre} cuenta con documentos reemplazados pendientes por revisión.`,
        tipo: 'mensaje',
        acciones: () => accionesSolicitudes({id}),
      }
      notificaciones.push(notificacion)
    })

    noti_proceso.for_comp.map((data) => {
      let { usuario_registro } = data;
      let notificacion = {
        icono: NotificaIcon4,
        titulo: `¡ Formatos Completos !`,
        mensaje: `El proceso del estudiante ${usuario_registro.primer_apellido} ${usuario_registro.primer_nombre} cuenta con todos los formatos completos.`,
        tipo: 'mensaje',
        acciones: () => accionesSolicitudes(data),
      }
      notificaciones.push(notificacion)
    })

    noti_proceso.doc_env.map((data) => {
      let { estado_pr__practica__usuario_registro__primer_apellido: apellido, estado_pr__practica__usuario_registro__primer_nombre: nombre, estado_pr__practica: id } = data;
      let notificacion = {
        icono: NotificaIcon5,
        titulo: `¡ Documentos Enviados !`,
        mensaje: `El proceso del estudiante ${apellido} ${nombre} cuenta con documentos enviados pendientes por revisión.`,
        tipo: 'mensaje',
        acciones: () => accionesSolicitudes({id}),
      }
      notificaciones.push(notificacion)
    })
    
    this.setState({ notificaciones, cargandoNotif: false });
  }

  notificacionPeriodo(periodo, fecha_periodo) {
    let { administrador } = this.state;
    let notificacion = null;
    if (fecha_periodo) {
      let fecha1 = moment();
      let fecha2 = moment(fecha_periodo);
      let dias = fecha2.diff(fecha1, 'days');
      if (dias < 15) {
        let mensaje = dias < 0 ? `El período actual ${periodo} se ha cerrado, tener en cuenta que los estudiantes no podrán realizar solicitudes. ${!administrador ? 'Por favor, contacta al administrador del modulo para que actualice el periodo.' : ''}` : `El periodo actual ${periodo} se cerrara ${dias === 0 ? 'el día de hoy' : `en ${dias} días`}, una vez el periodo se cierre los estudiantes no podrán realizar solicitudes.`
        notificacion = {
          icono: vistaIcon,
          titulo: '¡ Actualizar Período !',
          mensaje,
          tipo: 'fecha_periodo',
          acciones: () => administrador && <BtnEditar texto='Actualizar Periodo' callback={() => this.props.mostrarModalModPeriodo(true)} color='#01579b' />,
        }
      }
    }
    return notificacion;
  }

  gestionarMensaje = (id, data = { leido: 1 }) => {
    let { actualizarMensaje, } = this.props;
    let { periodo, fecha_periodo } = this.state;
    consulta(`api/v1.0/practicas/comentario/${id}`, data, "patch", async (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.obtenerAlertas(periodo, fecha_periodo);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp)
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    }
    );
  }

  obtenerPracticasAlerta = async (filtros = [{ 'llave': 'limite', 'valor': moment().format('YYYY-MM-DD') }, { 'llave': 'sin_empresa', 'valor': '1' }, { 'llave': 'busca_empresa', 'valor': '0' }, { 'llave': 'estado_actual__valorb', 'valor': '1' }]) => {
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`api/v1.0/practicas/listar/1?${f}`, null, null,
        (error, estado, resp) => {
          resolve(resp);
        }
      )
    })
  }

  obtenerDataMensajes = async () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/practicas/comentarios?leido=0`, null, null,
        (error, estado, resp) => {
          resolve(resp);
        }
      )
    })
  }


  obtenerMensajeAccion(accion) {
    const nombre = {
      'filtros': 'Filtros Generales',
      'Est_Pra_Ini': 'Aceptadas',
      'Est_Pra_Rec': 'Filtros Generales',
      'Est_Pra_Fin': 'Finalizadas',
      'Est_Pra_Rev': 'En revisión',
      'Est_Pra_Acep': 'Filtros Generales',
      'Est_Pra_Vis': 'Visitas',
      'Est_Pra_Env': 'Solicitadas',
      'mensajes': 'Mensajes',
      'estudiantes': 'Matriculados',
    }
    return (nombre[accion])
  }
  pintar = () => {
    let { accion, cargando, administrador, notificaciones, periodo, fecha_periodo, accionProceso, cargandoNotif, estadoSeleccion } = this.state;
    let { modalModPeriodo, mostrarModalModPeriodo, modalAddFiltros, mostrarModalAddFiltros, actualizarMensaje, modalMasivoFiltro, mostrarModalMasivoFiltro } = this.props;
    return (
      <div >
        
        <MenuPracticas
          administrador={administrador}
          notificaciones={notificaciones}
          seleccionar={
            (accion) => {
              if (accion === 'periodo') {
                this.setState({ enNotificacion: 'no' });
                mostrarModalModPeriodo(true);
              } else if (accion === 'notificacion') {
                this.setState({ enNotificacion: 'si' });
                this.obtenerAlertas(periodo, fecha_periodo);
              } else {console.log('entre aqui sapo') 
              this.setState({ enNotificacion: 'no', accion })}
            }
          }
          sele={accion}
          cargandoNotif={cargandoNotif}
        />
        <PracticasFiltrar
          modalAddFiltros={modalAddFiltros}
          mostrarModalAddFiltros={mostrarModalAddFiltros}
          actualizarMensaje={actualizarMensaje}
          limpiar={() => this.obtenerPracticas(this.configFiltros(accionProceso))}
          filtrar={(filtros) => { this.obtenerPracticas(this.configFiltros(accionProceso).concat(filtros));  this.setState({seleccionProc: 'filtros'})}}
          accionProceso={accionProceso}
          mostrarTodos = {(accion) => this.setState({ accionProceso : accion})}
          obtenerValoresFiltrosPracticas={this.obtenerValoresFiltrosPracticas}
        />

        <PracticasMasivoFiltrar
          modalMasivoFiltro={modalMasivoFiltro}
          mostrarModalMasivoFiltro={mostrarModalMasivoFiltro}
          actualizarMensaje={actualizarMensaje}
          filtrar={(filtros) => {this.obtenerPracticas(this.configFiltros(accionProceso).concat(filtros))}}
          accionProceso={accionProceso}
          mostrarTodos = {(accion) => this.setState({ accionProceso : accion})}
          estadoSeleccion={estadoSeleccion}
          estadoSeleccione = {(sw) => this.setState({ estadoSeleccion: sw})}
        />

        <div className='contenido_modulo'>
          {!cargando ?
            <div >
              {accion === 'procesos' && this.pintarInicioProceso()}
              {accion === 'estudiantes' && this.renderEstudiantes()}
              {accion === 'hojas' && this.renderHV()}
              {accion === 'administrar' && this.pintarAdministrar()}
            </div>
            : <TareasTerminadas imagen={emma_w} mensaje='Cargando..' widthImg='7%' marginTop='7%' cargando={true}/>
          }
          <EnviarMensajeFecha modal={modalModPeriodo} mostrarModal={mostrarModalModPeriodo} enviar={(periodo, fecha) => this.modificarPeriodo(periodo, fecha)} label='Periodo' type='text' titulo={`Nuevo Periodo`} label_fecha='Fecha Vencimiento' />
        </div>
      </div >
    )
  }

  accionesAdjFaltantes = (data, callback) => {
    let { mostrarModalIniActa } = this.props;

    const onClickCargar = (data) => callback(data);
    const onClickDescargar = ({ archivo, nombre, codigo }) => archivo && window.open(`${api + archivo}`, '_blank');

    const onClickActa = ({ codigo, nombre, estudiante, valorf }) => {
      this.setState({ acta: { id: codigo, nombre, estudiante, valorf } })
      mostrarModalIniActa(true);
      // window.open(`practica/acta/${codigo}/${practica.id}`, '_blank');
    }


    let descargar = data.archivo && <BtnDescargar callback={() => onClickDescargar(data)} color='#01579b' texto='Abrir' />
    let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />
    let formato = <BtnEditar callback={() => onClickActa(data)} color='#00bfa5' texto='Diligenciar' />
    let cf = parseInt(data.tipo) === 1 ? formato : cargar;

    return <div>{descargar} {cf}</div>;
  }

  pintarInicioProceso() {
    let { accionProceso,cargando, tipoSolicitud, seleccionProc } = this.state;
    return (
      <>
      {
        !cargando ?
      <>
      
              {accionProceso === 'inicio' && this.renderInicio()}
              {accionProceso === tipoSolicitud && accionProceso !== 'inicio' && seleccionProc !== 'filtros' && this.pintarEstadosProcesos()}
              {accionProceso !== tipoSolicitud && accionProceso !== 'inicio' && this.renderPracticas()}
              {accionProceso === tipoSolicitud && accionProceso !== 'inicio' && seleccionProc == 'filtros' && this.renderPracticas()}

              {/* BOTÓN REGRESAR */}
              {accionProceso === tipoSolicitud && accionProceso !== 'inicio' && seleccionProc == 'filtros'
                ? <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ accionProceso: tipoSolicitud, seleccionProc: '', filtros_practica: [] })} /></div>
                : accionProceso !== tipoSolicitud && accionProceso !== 'inicio'
                  ? <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ accionProceso: tipoSolicitud, seleccionProc: '', filtros_practica: [] })} /></div> : accionProceso == tipoSolicitud && seleccionProc == '' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ accionProceso: 'inicio', seleccionProc: '', filtros_practica: [] })} /></div>}

      </>:<TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='7%' marginTop='7%' cargando={true} />
    }
    </>
    )

   
  }

  renderInicio() {
    let { grupos_practicas } = this.state
    return (
      <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 40 }}>
        {grupos_practicas.map((datos, i) =>
          <Grid item xs={12} sm={6} md={4} lg={4} style={{ padding: '2% 2% 0% 2%' }} key={i} onClick={() => this.setState({ accionProceso: datos.codigo, tipoSolicitud: datos.codigo})}>
            <Card style={{ maxWidth: "100%", height: '380px', cursor: 'pointer' }} elevation={1}>
              <CardHeader
                avatar={<Avatar aria-label="recipe" style={{ backgroundColor: coloresEmma.iconscolor }}></Avatar>}
                title={datos.nombre}
              />
              <CardContent >
                <Grid container direction={'row'} justifyContent={'center'}>
                  <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                    <img src={datos.archivo} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                    <Typography variant="body2" color="textSecondary" component="p" align='justify'>
                      {datos.descripcion}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    )
  }

  pintarEstadosProcesos = () => {
    let { estados_procesos, filtrarPeriodo, periodo_filtro, periodos, cargandoPeriodo,inputidentificacion,accionProceso,estudiante,buscarEstudiante, tipoSolicitudFiltro } = this.state;
    let { mostrarModalAddFiltros,mostrarModalFiltroId,modalfiltroid,actualizarMensaje } = this.props
    
    const onSubmit =  (e)=>{

          if(estudiante.id!==0){

          let filtros = [];
          accionProceso='Tod_Pra'
          this.setState({accionProceso})
          filtros.push({ 'llave': 'usuario_registro', 'valor': estudiante.id });
          // filtros.push({ 'llave': 'tipoSolicitud', 'valor': tipoSolicitudFiltro });
          // filtros.push({ 'llave': 'periodo', 'valor': periodo_filtro });

           this.obtenerPracticas(filtros)
          mostrarModalFiltroId(false)
         
        }
          else{
            actualizarMensaje({ titulo:'Digite el campo', tipo:'info', mostrar: true, tiempo: 6000 });
          }
          e.preventDefault();
        }

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Est_Pra_Env': { 'backgroundColor': '#EEEEEE', 'color': '#757575' },
        'Est_Pra_Ini': { 'backgroundColor': coloresEmma.solicitudAceptada, color },
        'Est_Pra_Rec': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
        'Est_Pra_Fin': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
        'Est_Pra_Rev': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
        'Est_Pra_Acep': { 'backgroundColor': coloresEmma.solicitudAceptada, color },
        'Est_Pra_Vis': { 'backgroundColor': coloresEmma.solicitudactiva, color },
        'Est_Pra_Vif': { 'backgroundColor': coloresEmma.solicitudactiva, color },
        'Est_Pra_Vio': { 'backgroundColor': coloresEmma.solicitudactiva, color },
        'Tod_Pra': { 'backgroundColor': coloresEmma.filtrotodos, color },
        'Est_Pra_Ig': {'backgroundColor': coloresEmma.solicitudactiva, color},
        'Est_Pra_Arl': {'backgroundColor': coloresEmma.solicitudenrevision, color},
        'Est_Pra_Pol': {'backgroundColor': coloresEmma.ReemplazaroReenviar, color},
        'Est_Pra_Ia': {'backgroundColor': coloresEmma.solicitudactiva, color},
        'Est_Pra_As': {'backgroundColor': coloresEmma.solicitudenrevision, color},
        'Est_Pra_Ie': {'backgroundColor': coloresEmma.solicitudactiva, color},
        'Est_Pra_EP': {'backgroundColor': coloresEmma.solicitudenrevision, color},
        'Est_Pra_EF': {'backgroundColor': coloresEmma.solicitudactiva, color}
      }
      return (colores[codigo])
    }

    const handleClick = (codigo) => {
      if (codigo != 'Tod_Pra') this.setState({ accionProceso: codigo })
      else {mostrarModalAddFiltros(true); 
      }
    };

    return (
      <>
        <div style={{ padding: '2% 2% 0% 2%' }} >
          <Grid container direction={'row'} spacing={1} justify={'center'} >
            <Grid item xs={12} sm={12} md={12} lg={10} >
              <Grid container direction={'row'} alignItems="center" spacing={1} style={{ width: "100%" }}>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                  <TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                
                 
                  <Tooltip title='Filtrar' aria-label="add" onClick={() => this.ModalfiltroPeriodo()} >
                    <IconButton edge={false} color="secondary" style={{ float: 'right' }}><FilterListIcon /></IconButton>
                  </Tooltip>
                  <Tooltip title='Buscar' aria-label="search" onClick={() => this.props.mostrarModalFiltroId(true)} >
                    <IconButton edge={false} color="secondary" style={{ float: 'right' }}><SearchIcon /></IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Paper elevation={0} square className="scroll contenido_fijo">
                { (estados_procesos.length === 0) ?
                <TareasTerminadas mensaje='Aún no hay solicitudes para este periodo.' marginTop='7%' imagen={emma_s1} widthImg="7%" /> :
                <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                  {estados_procesos.map(({ estado_actual__codigo: codigo, estado_actual__nombre: nombre, num_sol, nmodalidades, nvalidaciones }, i) => {
                    return (
                      <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                        <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => handleClick(codigo)}>
                          <AccionesRapidas imagen='' titulo={`¡ ${nombre} !`} sub_titulo='' des_sub_titulo='' avatar={obtenerColor(codigo)} avatar_name= {nombre.charAt(0).toUpperCase()} list_desc={Number.isInteger(num_sol) ? [`Numero de Solicitudes : ${num_sol}`, `Solicitudes por Modalidades: ${nmodalidades}`, `Solicitudes por Validaciones: ${nvalidaciones}`] :  ['Debe aplicar los filtros.']} altura={{ minHeight: '90px' }}/>
                        </Paper>
                      </Grid>
                    )
                  })}
                </Grid>
                }
              </Paper>
            </Grid>
          </Grid>
        </div>

        <Dialog fullWidth={true} maxWidth="xs" open={filtrarPeriodo} onClose={() => this.cambiarEstado({filtrarPeriodo : false})} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => this.cambiarEstado({filtrarPeriodo : false})} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            {!cargandoPeriodo ?
              <Grid container spacing={13} className='margin_cont' >
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="periodo_filtro">Seleccione Periodo</InputLabel>
                    <Select
                      inputProps={{
                        name: 'periodo_filtro',
                        id: 'periodo_filtro',
                      }}
                      value={periodo_filtro}
                      onChange={this.onChange}
                      required={true}>
                      {periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              : <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' cargando={true}/>
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.cambiarEstado({filtrarPeriodo : false})} />
            <BtnForm texto="FILTRAR" callback={() => this.filtrarEstadoSolicitudes()} />
          </DialogActions>
        </Dialog >
        <Dialog fullWidth={true} maxWidth="xs" open={modalfiltroid} onClose={() => mostrarModalFiltroId(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={mostrarModalFiltroId} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
              <Grid container spacing={13} className='margin_cont' >
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                    {/* es aqui */}
                  {/* <FormControl className='form-control'>
                  <InputLabel htmlFor="identificacion_filtro" >Número de Documento de Identidad</InputLabel>
                        <Input
                            id="filtroidentificacionestudiante"
                            type="number"
                            // value={inputidentificacion}
                            onChange={(e)=>this.cambiarEstado({inputidentificacion:e.target.value})}
                            color='secondary'
                        />
                  </FormControl> */}
                   <InputBuscar
                    id='estudiante'
                    label='Estudiante'
                    placeholder='Clic aquí para buscar'
                    value={estudiante.nombre}
                    callback={() => this.setState({ buscarEstudiante: true })} />
                </Grid>
              </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModalFiltroId(false)} />
            <BtnForm texto="FILTRAR" callback={onSubmit} />
          </DialogActions>
        </Dialog >


        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={buscarEstudiante} onClose={() => this.setState({ buscarEstudiante: false })}>
          <AppBarModal titulo={'¡ Buscar Estudiante !'} mostrarModal={() => this.setState({ buscarEstudiante: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarEstudiante: false })} />
          <DialogContent style={{ padding: '0' }} className='scroll'>
            <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
              estudiante: {
                id,
                nombre: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
              }, buscarEstudiante: false
            })} />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarEstudiante: false })} />
          </DialogActions>
        </Dialog>






        
      </>
    )
  }

  ModalfiltroPeriodo = async () => {
    let { cargarFiltroP, periodo } = this.state
    this.cambiarEstado({ filtrarPeriodo: true })
    if (cargarFiltroP) {
      this.cambiarEstado({ cargandoPeriodo: true })
      let periodos = await this.obtenerPeriodos();
      if(!(periodos.find(e => e.periodo == periodo))) periodos.push({'periodo': periodo})
      this.cambiarEstado({ periodos, cargandoPeriodo: false, cargarFiltroP: false })
    }
  }

  obtenerPeriodos = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/practicas/periodos`, null, null,
        (error, estado, resp) => {
          resolve(resp)
        }
      );
    })
  }

  filtrarEstadoSolicitudes = () => {
    let { periodo_filtro, tipoSolicitudFiltro } = this.state
    this.cambiarEstado({ filtrarPeriodo: false, cargando: true })
    this.obtenerEstadoSolicitudes(periodo_filtro, tipoSolicitudFiltro)
  }


  configFiltros = (accion_actual) => {
    console.log('CONFIGURACION FILTTO');
    console.log(accion_actual);
    let { periodo_filtro, tipoSolicitudFiltro, tipoSolicitud } = this.state
    this.setState({ seleccionMasiva: [] })
    switch (accion_actual) {
      case 'Tod_Pra':
        return [{ 'llave': 'periodo', 'valor': periodo_filtro }, { 'llave': 'tipoSolicitud', 'valor': tipoSolicitudFiltro }]
      case tipoSolicitud:
        return [{ 'llave': 'periodo', 'valor': periodo_filtro }, { 'llave': 'cancelados_no', 'valor': 'Est_Pra_Rec' }, { 'llave': 'tipoSolicitud', 'valor': tipoSolicitudFiltro }]
      default:
        return [{ 'llave': 'estado_actual__codigo', 'valor': accion_actual }, { 'llave': 'periodo', 'valor': periodo_filtro }, { 'llave': 'tipoSolicitud', 'valor': tipoSolicitudFiltro }]
    }
  }
  
  renderPracticas = () => {
    let { estadoGestionar,dato_buscar, adj_faltantes, acta, datos_acuerdos, cargandoPendientes, accionProceso, seleccionMasiva, estadoSeleccion } = this.state;
    let { modalAddValoresSucursal,mostrarModalAddSucursalPractica,mostrarModalAddEmpresaProceso, modalAddEmpresaProceso, modalAddValores, mostrarModalAddValores, mostrarModalAdmBuscarEmpresa, mostrarModalAdmBuscarEmpresaotravez,modalAdmBuscarEmpresa, modalAdmBuscarEmpresaotravez,modalIniActa, mostrarModalIniActa, actualizarMensaje, mostrarModalAddFiltros, modalAdjFaltantes, mostrarModalAdjFaltantes, modalDetallePractica, mostrarModalDetallePractica, mostrarModalAddAdjunto, actualizarDataEstado, modalGestionarPractica, mostrarModalGestionarPractica, practicas, mostrarModalAdmAjuntosPractica, actualizarDataPractica, actualizarDataAdjuntosPractica, modalAdmAdjuntosPractica, practica, estado, usuario, mostrarModalAccionesMasivasPractica, modalAccionesMasivas, mostrarModalMasivoFiltro,mostrarModalAddValoresSucursal } = this.props   
    return (
      <div>
        <Practica
          practica_id={practica ? practica.id : 0}
          vistaDetalle='practica'
          modalDetallePractica={modalDetallePractica}
          mostrarModalDetallePractica={mostrarModalDetallePractica}
          usuario={usuario.perfil}
          admin={true}
          adminuser={true}
          />

        <PracticasGestionar
          validarVisitasProgramadas={this.validarVisitasProgramadas}
          gestionarPractica={this.gestionarPractica}
          gestionarEstadoMasivos={this.gestionarEstadoMasivos}
          practica={practica}
          mostrarModalGestionarPractica={mostrarModalGestionarPractica}
          modalGestionarPractica={modalGestionarPractica}
          actualizarDataAdjuntosPractica={actualizarDataAdjuntosPractica} 
          actualizarMensaje={actualizarMensaje}
          id_coord={usuario.id}
          estadoSeleccion={estadoSeleccion}
          seleccionMasiva={seleccionMasiva}
          />

        <PracticasListar
          mostrarModalAdmBuscarEmpresa={mostrarModalAdmBuscarEmpresa}
          mostrarModalAdmBuscarEmpresaotravez={mostrarModalAdmBuscarEmpresaotravez}
          mostrarModalAddFiltros={mostrarModalAddFiltros}
          mostrarModalMasivoFiltro={mostrarModalMasivoFiltro}
          mostrarModalGestionarPractica={mostrarModalGestionarPractica}
          dato_buscar={dato_buscar} administra={true}
          mostrarModalDetallePractica={mostrarModalDetallePractica}
          practicas={practicas}
          estadoSeleccion={estadoSeleccion}
          seleccionMasiva={seleccionMasiva}
          mostrarModalAdmAjuntosPractica={mostrarModalAdmAjuntosPractica}
          mostrarModalAccionesMasivasPractica={mostrarModalAccionesMasivasPractica}
          actualizarDataEstado={actualizarDataEstado}
          actualizarDataPractica={actualizarDataPractica}
          actualizarDataAdjuntosPractica={actualizarDataAdjuntosPractica}
          mostrarModalAddEmpresaProceso={mostrarModalAddEmpresaProceso}
          refrescar={() => this.obtenerPracticas(this.state.filtros_practica)}
          limpiar={() => { this.obtenerPracticas(this.configFiltros(accionProceso)); this.setState({estadoSeleccion: false})}}
        />

        <PracticasPendientes
          cargando={cargandoPendientes}
          practica={practica}
          modalAdjFaltantes={modalAdjFaltantes}
          mostrarModalAdjFaltantes={mostrarModalAdjFaltantes}
          guardarAdjuntosNuevos={this.guardarAdjuntosNuevos}
          adj_faltantes={adj_faltantes}
          mostrarModalIniActa={mostrarModalIniActa}
          setActa={({ codigo, nombre, estudiante, valorf }) => this.setState({acta: { id: codigo, nombre, estudiante, valorf }})}
          admin={true}
        />
  
        <EnviarMultiplesArchivos
          modalMulArchivos={modalAdjFaltantes}
          acciones={this.accionesAdjFaltantes}
          titulo='¡ Requisitos Pendientes !'
          enviarArchivos={(archivos, callback) => this.guardarAdjuntosNuevos(archivos, callback)}
          mostrarModalMulArchivos={() => mostrarModalAdjFaltantes(false)}
          limite={2}
          mostrarRender={true}
          mostrar={true}
          enLista={true}
          lista={adj_faltantes}
        />
        <PracticasAdjuntos
          gestionarMasivos={this.gestionarMasivos}
          obtenerAdjFaltantes={this.obtenerAdjFaltantes}
          practica={practica}
          estado={estado}
          adj_faltantes={adj_faltantes}
          mostrarModalAddAdjunto={mostrarModalAddAdjunto}
          mostrarModalAdjFaltantes={mostrarModalAdjFaltantes}
          mostrarModalGestionarPractica={mostrarModalGestionarPractica}
          gestionarPractica={this.gestionarPractica}
          mostrarModalAdmAjuntosPractica={mostrarModalAdmAjuntosPractica}
          modalAdmAdjuntosPractica={modalAdmAdjuntosPractica}
          config={true}
          usuario={usuario.perfil}
          admin={true}
          />
        
        <PracticasAccionesMasivas
          mostrarModalAccionesMasivasPractica={mostrarModalAccionesMasivasPractica}
          modalAccionesMasivas={modalAccionesMasivas}
          practicas={practicas}
          seleccionMasiva={seleccionMasiva}
          mostrarModalGestionarPractica={mostrarModalGestionarPractica}
        />

        <PracticaIniciarActa
          modalIniActa={modalIniActa}
          mostrarModalIniActa={mostrarModalIniActa}
          practica={practica}
          enviarActa={this.enviarActa}
          acta={acta}
          coordinador={{correo: usuario.correo, nombre: `${usuario.primer_nombre} ${usuario.primer_apellido} ${usuario.segundo_apellido}`}}
        />

        < ConfirmarAccion
          dato={{ id: 0 }}
          titulo={'¿ Buscar empresa ?'}
          ejecutarAccion={() => this.modificarPractica(practica ? practica.id : 0, { busca_empresa: 1 }, () => mostrarModalAdmBuscarEmpresa(false))}
          modalConAccion={modalAdmBuscarEmpresa}
          mostrarModalConfAccion={mostrarModalAdmBuscarEmpresa}
        />

      < ConfirmarAccion
          dato={{ id: 0 }}
          titulo={'¿ Rotar hoja de vida a otra empresa ?'}
          ejecutarAccion={() => this.modificarPracticaotravez(practica ? practica.id : 0, { busca_empresa: 1 ,empresa:null}, () => mostrarModalAdmBuscarEmpresaotravez(false))}
          modalConAccion={modalAdmBuscarEmpresaotravez}
          mostrarModalConfAccion={mostrarModalAdmBuscarEmpresaotravez}
        />


        <PracticaEmpresa
          actualizarMensaje={actualizarMensaje}
          guardarEmpresa={this.guardarEmpresaFormatoSeleccion}
          obtenerValoresFiltros={obtenerValoresFiltros}
          obtenerValores={obtenerValores}
          modificarPractica={this.modificarPracticaEmpresa}
          practica_id={practica ? practica.id : 0}
          modalAddValores={modalAddValores}
          mostrarModalAddValores={mostrarModalAddValores}
          modalAddEmpresaProceso={modalAddEmpresaProceso}
          mostrarModalAddEmpresaProceso={mostrarModalAddEmpresaProceso}
          mostrarModalAddValoresSucursal={mostrarModalAddValoresSucursal}
          modalAddValoresSucursal={modalAddValoresSucursal}
          tipo='admin'
          acuerdos={datos_acuerdos}
          cambiar_empresa={true}
        />

      </div>
    )
  }

  renderVistaRapida() {
    return (
      <ListarDatos
        id='tbl_reportes_practica'
        descargar='EMMA - PRACTICAS GENERAL'
        datos={this.state.reportes}
        opciones={true}
        titulo={'Información Rapida'}
        buscar={true}
        avatar='estado_actual__valorc'
        color_avatar={({ estado_actual__codigo }) => this.obtenerColor(estado_actual__codigo)}
        fila_principal={({ tipo__nombre }) => tipo__nombre.toUpperCase()}
        filas={[
          {
            'mostrar': ({ estado_actual__nombre, count }) => `${estado_actual__nombre} : ${count}`,
            'id': 'total',
          },
        ]}
      />
    )
  }


  pintarInformes() {
    let { informes, informe }  = this.state
    let { modalInforme, mostrarModalInforme, actualizarMensaje }  = this.props

    const acciones = (data) => {      
      this.setState({ informe: {'nombre': data.nombre, 'codigo': data.codigo} }) 
      mostrarModalInforme(true)
    }

    return (
      <>
        <ListarTarjetas
          datos={informes}
          titulo={'Informes Practicas'}
          acciones={(data) => acciones(data)}
          avatar={({ nombre }) => nombre.toUpperCase().charAt(0)}
          color_avatar={({valorc}) => ({'backgroundColor': ((valorc) ? valorc : '#EEEEEE'), 'color': ((valorc) ? 'white' : '#757575') })}
          orden={'valorb'}
        />
        { (informe.codigo !='Inf_Emp' && informe.codigo !='Inf_Seg') &&
          
        <PracticaInforme
          informe={informe}
          modalInforme={modalInforme}
          mostrarModalInforme={mostrarModalInforme}
          actualizarMensaje={actualizarMensaje}
        />
         }
         {
          informe.codigo =='Inf_Emp' &&
          <PracticaInformeEmpresa
          informe={informe}
          modalInforme={modalInforme}
          mostrarModalInforme={mostrarModalInforme}
          actualizarMensaje={actualizarMensaje}/>
         } 
         {
          informe.codigo =='Inf_Seg' &&
          <PracticaInformeSeguimiento
          informe={informe}
          modalInforme={modalInforme}
          mostrarModalInforme={mostrarModalInforme}
          actualizarMensaje={actualizarMensaje}/>
         } 
      </>
    )
  }

  pintarTraslado() {
    let { coordinadores, modalTraslado, coordinador } = this.state;
    let { actualizarMensaje } = this.props;

    return (
      <PracticaTraslado
        modalTraslado={modalTraslado}
        mostramodalTraslado={(valor) => this.setState({ modalTraslado: valor })}
        datosCoordinador={(data) => this.setState({ coordinador: data })}
        coordinador={coordinador}
        coordinadores={coordinadores}
        actualizarMensaje={actualizarMensaje}
      />
    )
  }

  pintarInicioAdm = () => {
    let { periodo, fecha_periodo } = this.state;
    return (
      <div style={{ padding: '2% 2% 0% 2%' }} >
        <Grid container direction={'row'} spacing={1} justify={'center'} >
          <Grid item xs={12} sm={12} md={10} lg={8} >
            <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
            <Paper elevation={0} square className="scroll contenido_fijo">
              <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdm: 'empresas' })}>
                    <AccionesRapidas imagen={empresasIcon} titulo='¡ Administrar Empresas !' sub_titulo='' des_sub_titulo='Crea, modifica y elimina empresas, y asigna sucursales.' />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.props.mostrarModalModPeriodo(true)}>
                    <AccionesRapidas imagen={fechaIcon} titulo={`¡ Actualizar Período !`} sub_titulo='' des_sub_titulo={`El periodo actual ${periodo} esta disponible hasta ${fecha_periodo}.`} />
                  </Paper>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdm: 'sucursales' })}>
                    <AccionesRapidas imagen={sucursalesIcon} titulo='¡ Gestionar Sucursales !' sub_titulo='' des_sub_titulo='Verifica, agrega, modifica y elimina sucursales.' />
                  </Paper>
                </Grid> */}

                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdm: 'permisos' })}>
                    <AccionesRapidas imagen={permisosIcon} titulo='¡ Controlar Permisos !' sub_titulo='' des_sub_titulo='Verifica, asigna y retira permisos a los gestores.' />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdm: 'traslado' })}>
                    <AccionesRapidas imagen={trasladoIcon} titulo={`¡ Trasladar Solicitudes !`} sub_titulo='' des_sub_titulo={`Puedes trasladar solicitudes a otros coordinadores.`} />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdm: 'informes' })}>
                    <AccionesRapidas imagen={reportIcon} titulo={`¡ Informes !`} sub_titulo='' des_sub_titulo={`Genera reporte de los procesos de practicas.`} />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdm: 'vista' })}>
                    <AccionesRapidas imagen={vistaIcon} titulo={`¡ Información Rapida !`} sub_titulo='' des_sub_titulo={`Obtén  una vista general de como van los procesos.`} />
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )

  }
// admin
  pintarAdministrar() {
    let { accionAdm, administrador } = this.state;
    if (!administrador) return <TareasTerminadas imagen={emma_s1} mensaje='No tiene los permisos asignados' widthImg='7%' marginTop='7%' />
    return (
      <>
        {accionAdm === 'inicio' && this.pintarInicioAdm()}
        {accionAdm !== 'inicio' && <div className={accionAdm === 'empresas' || accionAdm === 'sucursales' ? 'regresar_alt' : 'regresar'} ><BtnRegresar callback={() => this.setState({ accionAdm: 'inicio' })} /></div>}
        {accionAdm === 'empresas' && this.renderEmpresas()}
        {accionAdm === 'permisos' && this.renderCoordinadores()}
        {accionAdm === 'sucursales' && this.renderSucursales()}
        {accionAdm === 'administrar' && this.pintarInicioAdm()}
        {accionAdm === 'vista' && this.renderVistaRapida()}
        {accionAdm === 'informes' && this.pintarInformes() }
        {accionAdm === 'traslado' &&  this.pintarTraslado()}
      </>
    )

  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }


  renderEmpresas = () => {

    let {
      mostrarModalDelEmpresa,
      mostrarModalModEmpresa,
      mostrarModalAddEmpresa,
      actualizarDataEmpresa,
      empresas,
      empresa,
      modalAddEmpresa,
      modalDelEmpresa,
      modalModEmpresa,
      mostrarModalAddPermisoValores,
      actualizarDataPermisos,
      modalAddPermisosValores,
      permisos,
      modalDetValores,
      mostrarModalDetValores,
      modalAddSucursal,
      modalAddSucursalPractica,
      mostrarModalAddSucursal,
      mostrarModalAddSucursalPractica,
    } = this.props;

    let { input_empresa } = this.state;
    return (
      <div>
        <InputBuscarText
          onChange={this.onChange}
          dato_buscar={input_empresa}
          obtenerData={this.obtenerEmpresas}
          placeholder='Ingrese Nombre de la empresa'
          name='input_empresa'
        />

        < PracticasEmpresas
          modalDetValores={modalDetValores}
          mostrarModalDetValores={mostrarModalDetValores}
          modalAddPermisosValores={modalAddPermisosValores}
          permisos={permisos}
          mostrarModalAddPermisoValores={mostrarModalAddPermisoValores}
          actualizarDataPermisos={actualizarDataPermisos}
          modalModEmpresa={modalModEmpresa}
          modalDelEmpresa={modalDelEmpresa}
          modalAddEmpresa={modalAddEmpresa}
          actualizarDataEmpresa={actualizarDataEmpresa}
          mostrarModalAddEmpresa={mostrarModalAddEmpresa}
          mostrarModalModEmpresa={mostrarModalModEmpresa}
          mostrarModalDelEmpresa={mostrarModalDelEmpresa}
          empresa={empresa}
          empresas={empresas}
          guardarEmpresa={this.guardarEmpresa}
          modificarEmpresa={this.modificarEmpresa}
          eliminarEmpresa={this.eliminarEmpresa}
          modalAddSucursal={modalAddSucursal}
          modalAddSucursalPractica={modalAddSucursalPractica}
          mostrarModalAddSucursalPractica={mostrarModalAddSucursalPractica}
          mostrarModalAddSucursal={mostrarModalAddSucursal}
          guardarSucursalPermiso={this.guardarSucursalPermiso}
          actualizarMensaje={this.actualizarMensaje}
        />
      </div>
    )
  }

  renderSucursales = () => {
    let {
      mostrarModalDelSucursal,
      mostrarModalModSucursal,
      mostrarModalAddSucursal,
      actualizarDataSucursal,
      sucursales,
      modalAddSucursal,
      modalDelSucursal,
      modalModSucursal,
      sucursal,
      modalDetValores,
      mostrarModalDetValores,
    } = this.props;

    let { input_sucursal } = this.state;
    return (<div>
      <InputBuscarText
        onChange={this.onChange}
        dato_buscar={input_sucursal}
        obtenerData={this.obtenerSucursales}
        placeholder='Ingrese Nombre de la sucursal'
        name='input_sucursal'
      />
      < PracticasSucursales
        modalDetValores={modalDetValores}
        mostrarModalDetValores={mostrarModalDetValores}
        modificarSucursal={this.modificarSucursal}
        eliminarSucursal={this.eliminarSucursal}
        guardarSucursal={this.guardarSucursal}
        sucursales={sucursales}
        sucursal={sucursal}
        modalModSucursal={modalModSucursal}
        modalDelSucursal={modalDelSucursal}
        modalAddSucursal={modalAddSucursal}
        actualizarDataSucursal={actualizarDataSucursal}
        mostrarModalAddSucursal={mostrarModalAddSucursal}
        mostrarModalModSucursal={mostrarModalModSucursal}
        mostrarModalDelSucursal={mostrarModalDelSucursal}
      />
    </div>
    )
  }

  renderCoordinadores = () => {
    let { coordinadores, coordinador } = this.state;
    let { modalProParticipante, mostrarModalProParticipante, mostrarModalPermisoEstado, modalPermisoEstado, modalPermisoModalidad, mostrarModalPermisoModalidad } = this.props;
    return (
      <div>
        <PracticasCoordinadores
          personas={coordinadores}
          mostrarModalProParticipante={mostrarModalProParticipante}
          mostrarModalPermisoEstado={mostrarModalPermisoEstado}
          mostrarModalPermisoModalidad={mostrarModalPermisoModalidad}
          callback={coordinador => this.cambiarEstado({ coordinador })
          } />

        <PracticasProgramas
          coordinador={coordinador}
          modalProParticipante={modalProParticipante}
          mostrarModalProParticipante={mostrarModalProParticipante}
        />

        <PracticasModalidadPermisos
          coordinador={coordinador}
          modalPermisoModalidad={modalPermisoModalidad}
          mostrarModalPermisoModalidad={mostrarModalPermisoModalidad}
        />

        <PracticasEstadosPermisos
          mostrarModalAddPermiso={() => alert("add")}
          coordinador={coordinador}
          modalPermisoEstado={modalPermisoEstado}
          mostrarModalPermisoEstado={mostrarModalPermisoEstado}
        />

      </div>
    )
  }

  renderEstudiantes = () => <PracticasEstudiantes estudiantes={this.state.estudiantes} />


  renderHV = () => {
    let { actualizarMensaje,practica,modalDetallePractica,mostrarModalDetallePractica,usuario ,modalEnviarHV, mostrarModalEnviarHV, mostrarModalCanPracticas, mostrarModalAddFiltrosHv, modalAddFiltrosHv, mostrarModalEmpresasHV, modalEmpresasHv } = this.props;
    let { estudianteshv } = this.state
    return( <PracticasHV practica_id={practica ? practica.id : 0} vistaDetalle='practica' modalDetallePractica={modalDetallePractica} mostrarModalDetallePractica={mostrarModalDetallePractica} usuario={usuario.perfil} adminuser={true} modalAddFiltrosHv={modalAddFiltrosHv} mostrarModalAddFiltrosHv={mostrarModalAddFiltrosHv} actualizarDataHV={this.actualizarDataHV} configurarAccionConf={this.configurarAccionConf} mostrarModalGestionar={mostrarModalCanPracticas} gestionarHV={this.gestionarHV} enviarHV={this.enviarHV} modalEnviarHV={modalEnviarHV} mostrarModalEnviarHV={mostrarModalEnviarHV} estudianteshv={estudianteshv} actualizarMensaje={actualizarMensaje} mostrarModalEmpresasHV={mostrarModalEmpresasHV} modalEmpresasHv={modalEmpresasHv}/>)

  }

  cambiarEstado = (estados) => {
    this.setState(estados)
  }

  cambiarOpcion = (seleccion) => {
    this.cambiarEstado({ seleccion })
  }

  render() {
    let { data_confirmar: { titulo, ejecutarAccion, } } = this.state;
    let { modalCanPractica, mostrarModalCanPracticas } = this.props;

    return (
      <div>
        {this.pintar()}
        < ConfirmarAccion dato={{ id: 0 }} titulo={titulo} ejecutarAccion={() => ejecutarAccion()} modalConAccion={modalCanPractica} mostrarModalConfAccion={mostrarModalCanPracticas} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let {modalfiltroid,modalAddValoresSucursal,modalDetValoresPractica,modalAddPermisosValoresPractica,modalPermisoModalidad, modalAddFiltrosHv, modalAddEmpresaProceso, modalAdmBuscarEmpresa, modalAdmBuscarEmpresaotravez,modalIniActa, modalPermisoEstado, modalCanPractica, modalEnviarHV, modalAyuda, modalModPeriodo, modalAddFiltros, empresas, empresa, modalAddEmpresa, modalDelEmpresa, modalModEmpresa, sucursales, sucursal, modalAddSucursal, modalAddSucursalPractica,modalDelSucursal, modalModSucursal, modalProParticipante, practicas, modalAdmAdjuntosPractica, adjunto, adjuntos, practica, modalGestionarPractica, estado, modalDetallePractica, estados, modalEmpresasHv, modalInforme, modalAccionesMasivas, modalMasivoFiltro } = state.redPracticas;
  let { modalAddValores, modalAddPermisosValores, permisos, modalDetValores, valor } = state.redValores;
  let { mensajes, modalAdjFaltantes, usuario } = state.redGlobal;


  return {
    modalAddFiltros,
    practicas,
    modalAdmAdjuntosPractica,
    adjunto,
    adjuntos,
    practica,
    modalGestionarPractica,
    estado,
    modalDetallePractica,
    estados,
    modalProParticipante,
    sucursales,
    sucursal,
    modalAddSucursal,
    modalDelSucursal,
    modalModSucursal,
    empresas,
    empresa,
    modalAddEmpresa,
    modalDelEmpresa,
    modalModEmpresa,
    modalAddPermisosValores,
    permisos,
    modalModPeriodo,
    modalAyuda,
    modalEnviarHV,
    mensajes,
    modalCanPractica,
    modalDetValores,
    valor,
    modalAdjFaltantes,
    modalPermisoEstado,
    modalIniActa,
    modalAdmBuscarEmpresa,
    modalAdmBuscarEmpresaotravez,
    modalAddEmpresaProceso,
    modalAddValores,
    modalAddFiltrosHv,
    usuario,
    modalPermisoModalidad,
    modalEmpresasHv,
    modalInforme,
    modalAccionesMasivas,
    modalMasivoFiltro,
    modalAddSucursalPractica,
    modalDetValoresPractica,
    modalAddValoresSucursal,
    modalfiltroid
  }
}

const mapDispatchToProps = {
  actualizarDataPracticas,
  actualizarRenderPracticas,
  mostrarModalAdmAjuntosPractica,
  actualizarDataPractica,
  actualizarDataAdjuntosPractica,
  mostrarModulo,
  mostrarModalGestionarPractica,
  actualizarMensaje,
  actualizarDataEstado,
  mostrarModalAddAdjunto,
  mostrarModalDetallePractica,
  actualizarDataEstados,
  mostrarModalProParticipante,
  mostrarModalDelSucursal,
  mostrarModalModSucursal,
  mostrarModalAddSucursal,
  actualizarDataSucursal,
  actualizarDataSucursales,
  mostrarModalDelEmpresa,
  mostrarModalModEmpresa,
  mostrarModalAddEmpresa,
  actualizarDataEmpresa,
  actualizarDataEmpresas,
  mostrarModalAddPermisoValores,
  actualizarDataPermisos,
  mostrarModalAddFiltros,
  mostrarModalMasivoFiltro,
  mostrarModalModPeriodo,
  mostrarModalAyuda,
  mostrarModalEnviarHV,
  mostrarModalCanPracticas,
  mostrarModalAdd,
  mostrarModalDetValores,
  actualizarDataValor,
  mostrarModalAdjFaltantes,
  mostrarModalPermisoEstado,
  mostrarModalIniActa,
  mostrarModalAdmBuscarEmpresa,
  mostrarModalAdmBuscarEmpresaotravez,
  mostrarModalAddEmpresaProceso,
  mostrarModalAddValores,
  mostrarModalAddFiltrosHv,
  mostrarModalPermisoModalidad,
  mostrarModalEmpresasHV,
  mostrarModalInforme,
  mostrarModalAccionesMasivasPractica,
  mostrarModalAddValoresSucursal,
  mostrarModalFiltroId
  
}

PracticasAdministrar.propTypes = {
  //variables
  practicas: PropTypes.array.isRequired,
  modalCanPractica: PropTypes.bool.isRequired,
  modalAddFiltrosHv: PropTypes.bool.isRequired,
  modalPermisoEstado: PropTypes.bool.isRequired,
  modalAdjFaltantes: PropTypes.bool.isRequired,
  modalDetValores: PropTypes.bool.isRequired,
  modalDetValoresPractica: PropTypes.bool.isRequired,
  mensajes: PropTypes.array.isRequired,
  estados: PropTypes.array.isRequired,
  estado: PropTypes.object.isRequired,
  practica: PropTypes.object.isRequired,
  valor: PropTypes.object.isRequired,
  modalGestionarPractica: PropTypes.bool.isRequired,
  modalDetallePractica: PropTypes.bool.isRequired,
  modalProParticipante: PropTypes.bool.isRequired,
  sucursales: PropTypes.array.isRequired,
  sucursal: PropTypes.object.isRequired,
  modalAddSucursal: PropTypes.bool.isRequired,
  modalAddSucursalPractica: PropTypes.bool.isRequired,
  modalIniActa: PropTypes.bool.isRequired,
  modalAddValores: PropTypes.bool.isRequired,
  modalAddEmpresaProceso: PropTypes.bool.isRequired,
  modalDelSucursal: PropTypes.bool.isRequired,
  modalModSucursal: PropTypes.bool.isRequired,
  empresas: PropTypes.array.isRequired,
  empresa: PropTypes.object.isRequired,
  modalAddEmpresa: PropTypes.bool.isRequired,
  modalAdmBuscarEmpresa: PropTypes.bool.isRequired,
  modalAdmBuscarEmpresaotravez: PropTypes.bool.isRequired,
  modalDelEmpresa: PropTypes.bool.isRequired,
  modalPermisoModalidad: PropTypes.bool.isRequired,
  modalModEmpresa: PropTypes.bool.isRequired,
  modalAddValoresSucursal:PropTypes.bool.isRequired,
  modalfiltroid:PropTypes.bool.isRequired,
  //funciones
  actualizarDataPracticas: PropTypes.func.isRequired,
  actualizarRenderPracticas: PropTypes.func.isRequired,
  actualizarDataAdjuntosPractica: PropTypes.func.isRequired,
  mostrarModalGestionarPractica: PropTypes.func.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  actualizarDataEstado: PropTypes.func.isRequired,
  mostrarModalAddAdjunto: PropTypes.func.isRequired,
  mostrarModalDetallePractica: PropTypes.func.isRequired,
  actualizarDataEstados: PropTypes.func.isRequired,
  mostrarModalProParticipante: PropTypes.func.isRequired,
  mostrarModalDelSucursal: PropTypes.func.isRequired,
  mostrarModalModSucursal: PropTypes.func.isRequired,
  mostrarModalAddSucursal: PropTypes.func.isRequired,
  actualizarDataSucursal: PropTypes.func.isRequired,
  actualizarDataSucursales: PropTypes.func.isRequired,
  mostrarModalDelEmpresa: PropTypes.func.isRequired,
  mostrarModalModEmpresa: PropTypes.func.isRequired,
  mostrarModalAddEmpresa: PropTypes.func.isRequired,
  actualizarDataEmpresa: PropTypes.func.isRequired,
  actualizarDataEmpresas: PropTypes.func.isRequired,
  mostrarModalAddPermisoValores: PropTypes.func.isRequired,
  mostrarModalAddPermisoValoresPractica: PropTypes.func.isRequired,
  mostrarModalAddSucursalPractica: PropTypes.func.isRequired,
  actualizarDataPermisos: PropTypes.func.isRequired,
  mostrarModalAddFiltros: PropTypes.func.isRequired,
  mostrarModalMasivoFiltro: PropTypes.func.isRequired,
  mostrarModalModPeriodo: PropTypes.func.isRequired,
  mostrarModalAyuda: PropTypes.func.isRequired,
  mostrarModalEnviarHV: PropTypes.func.isRequired,
  mostrarModalCanPracticas: PropTypes.func.isRequired,
  mostrarModalAdd: PropTypes.func.isRequired,
  actualizarDataValor: PropTypes.func.isRequired,
  mostrarModalDetValores: PropTypes.func.isRequired,
  mostrarModalAdjFaltantes: PropTypes.func.isRequired,
  mostrarModalPermisoEstado: PropTypes.func.isRequired,
  mostrarModalAdmBuscarEmpresa: PropTypes.func.isRequired,
  mostrarModalAdmBuscarEmpresaotravez: PropTypes.func.isRequired,
  mostrarModalAddEmpresaProceso: PropTypes.func.isRequired,
  mostrarModalAddValores: PropTypes.func.isRequired,
  mostrarModalAddFiltrosHv: PropTypes.func.isRequired,
  mostrarModalPermisoModalidad: PropTypes.func.isRequired,
  mostrarModalAddValoresSucursal: PropTypes.func.isRequired,
  mostrarModalFiltroId: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(PracticasAdministrar)
