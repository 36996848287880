import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import emma_w from '../../../global/imagenes/emma_w.png';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from "../../general/AppBarModal";
import TareasTerminadas from "../../general/TareasTerminadas";
import CreditoAdjuntarArchivo from "./CreditoAdjuntarArchivo";
import EncuestaSatisfaccion  from './CreditoEncuesta';
import ListarDatos from "../../general/ListarDatos";
import { BtnDetalle } from "../../general/BotonesAccion";
import { CreditoMenu } from "./CreditoMenu";
import { CreditoDetalleCompleto } from "./CreditoDetalle";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@material-ui/core';
import { consulta, generarFiltros, mostrarError, formulario } from "../../../global/js/funciones";
import moment from 'moment'
import { connect } from "react-redux";
class Credito extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      seleccion: '',
      detalle: null,
      estados: [],
      pagos: [],
      encuestas: [],
      adjuntos: [],
      administrador: false,
    }
  }

  async componentDidUpdate({ modalDetalleCredito, modalEncuestaCredito}, { seleccion }) {
    let { seleccion: seleccionNew } = this.state;
    let { credito_id, modalDetalleCredito: modalDetalleCreditoNew, vistaDetalle, modalEncuestaCredito: modalEncuestaCreditoNew, VistaEncuenta } = this.props;
    if (seleccionNew !== seleccion) {
      if (seleccionNew === 'credito') this.obtenerCredito(credito_id);
      else if (seleccionNew === 'estados') this.obtenerEstados(credito_id);
      else if (seleccionNew === 'adjuntos') this.obtenerAdjuntos(credito_id);
      else if (seleccionNew === 'pagos') this.obtenerPagos(credito_id);
      else if (seleccionNew== 'encuestas')this.obtenerEncuesta(credito_id);
    }

    if (modalDetalleCredito !== modalDetalleCreditoNew) this.setState({ seleccion: modalDetalleCreditoNew ? vistaDetalle : '' })
    if (modalEncuestaCredito !== modalEncuestaCreditoNew) this.setState({ seleccion: modalEncuestaCreditoNew ? VistaEncuenta:'' })

  }

  async componentDidMount() {
  this.esAdministrador();
  }

  esAdministrador() {
    let { usuario } = this.props;
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "Per_cred_n1")) {
      this.setState({ administrador: true })
    }
  }

  async obtenerCredito(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    consulta(`api/v1.0/credito?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, credito: resp });
    })
  }
  async obtenerEncuesta(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    consulta(`api/v1.0/obtener-encuesta?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) {
        this.setState({ cargando: false, encuestas: resp });
      } else {
        this.setState({ cargando: false });
        console.error('Error al obtener los datos de la encuesta:', error);
      }
    });
  }
  async obtenerEstados(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'credito', 'valor': id }]);
    consulta(`api/v1.0/credito/estados?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, estados: resp });
    })
  }
  async obtenerAdjuntos(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'credito', 'valor': id }]);
    consulta(`api/v1.0/credito/adjuntos?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, adjuntos: resp });
    })
  }
  async obtenerPagos(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'credito', 'valor': id }]);
    consulta(`api/v1.0/credito/pagos?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, pagos: resp });
    })

  }

  cargarArchivos(archivos) {
    this.setState({ cargando: true });
    let { actualizarMensaje, mostrarModalAdjuntoCredito, credito_id } = this.props;
    mostrarModalAdjuntoCredito(false);
    const data = new FormData();
    data.append(`total`, archivos.length);
    archivos.map((file, index) => data.append(`archivo${index}`, file));
    formulario(`api/v1.0/creditos/${credito_id}/adjuntar`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.obtenerAdjuntos(credito_id);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }


  pintarCredito = () => {
    let { credito } = this.state;
    if (credito) {
      return <CreditoDetalleCompleto credito={credito[0]} />;
    }
  }

  pintarEstados = () => {
    let { estados } = this.state;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Cre_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Cre_Rev': { 'backgroundColor': '#ffc107', color },
        'Cre_Rec': { 'backgroundColor': '#b71c1c', color },
        'Cre_Car': { 'backgroundColor': '#009688', color },
        'Cre_Val': { 'backgroundColor': '#0d47a1', color },
        'Cre_Pen': { 'backgroundColor': '#02A6F2', color },
        'Cre_Ege': { 'backgroundColor': '#e65100', color },
        'Cre_Pag': { 'backgroundColor': '#6200ea', color },
        'Cre_Ges': { 'backgroundColor': '#bdbdbd', color },
        'Cre_Pfac': { 'backgroundColor': '#F5951B', color },
        'Cre_Rech': { 'backgroundColor': '#b71c1c', color },
        'Cre_Afac': { 'backgroundColor': '#1B8FF5', color },
        'Cre_ProRea': { 'backgroundColor': '#06df3e', color }
      }
      return (colores[codigo])
    }

    return (
      <ListarDatos
        datos={estados}
        id='tbl_estados_credito'
        avatar={({ tipo_estado }) => tipo_estado.valora}
        color_avatar={({ tipo_estado }) => obtenerColor(tipo_estado.codigo)}
        fila_principal={({ tipo_estado }) => tipo_estado.nombre}
        filas={[
          { 'id': 'usuario_registro', 'mostrar': ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}` },
          { 'id': 'observaciones', 'mostrar': ({ mensaje }) => mensaje },
          { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha Registro : ' },
        ]}
      />
    )
  }

  pintarAdjuntos = () => {
    let { adjuntos } = this.state;
    let { mostrarModalAdjuntoCredito, modalAddAdjuntosCredito, actualizarMensaje, estado_actual_credito } = this.props;


    const acciones = ({ archivo }) => {
      let detalle = <a href={`${archivo}`} target="_blank" className='sin_decorador'><BtnDetalle callback={() => { }} color='#01579b' texto='Abrir' /></a>;
      return <div>{detalle}</div>;
    }

    return (
      <div>
        <CreditoAdjuntarArchivo actualizarMensaje={actualizarMensaje} modalAddAdjuntosCredito={modalAddAdjuntosCredito} mostrarModalAdjuntoCredito={mostrarModalAdjuntoCredito} cargarArchivos={(archivos) => this.cargarArchivos(archivos)} />

        <ListarDatos
          datos={adjuntos}
          id='tbl_adjuntos_credito'
          avatar={({ nombre_archivo }) => nombre_archivo.charAt(0)}
          titulo=''
          agregar={estado_actual_credito === 'Cre_Pen' || estado_actual_credito === 'Cre_Pag'}
          actAgregar={() => mostrarModalAdjuntoCredito(true)}
          acciones={(data) => acciones(data)}
          color_avatar={() => ({ 'backgroundColor': '#f5f5f5', 'color': '#757575' })}
          fila_principal='nombre_archivo'
          filas={[
            { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha Registro : ' },
            { 'id': 'usuario_registro', 'mostrar': ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}` },
          ]}
        />
      </div>
    )
  }

  pintarPagos = () => {
    let { pagos } = this.state;
    return (
      <ListarDatos
        datos={pagos}
        id='tbl_pagos_credito'
        avatar={({ solicitud }) => solicitud.nombre.charAt(0)}
        color_avatar={() => ({ 'backgroundColor': '#f5f5f5', 'color': '#757575' })}
        fila_principal={({ solicitud }) => solicitud.nombre}
        filas={
          [
            { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha Registro : ' },
            { 'id': 'usuario_registro', 'mostrar': ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}` },
          ]}
      />
    )
  }

  pintarEncuestas = () => {
    const { encuestas } = this.state;
    if (!encuestas || typeof encuestas !== 'object'|| Object.keys(encuestas).length === 0) {
        return (
            <TareasTerminadas 
                imagen={emma_w} 
                mensaje='El estudiante aún no ha realizado la encuesta' 
                widthImg='10%' 
                marginTop='10%' 
            />
        );
    }
    const { experiencia, aspectos_a_mejorar, comentario } = encuestas;
    return (
        <TableContainer component={Paper} style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
            <Typography variant="h5" gutterBottom align="center">
                Encuesta de Satisfacción
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Categoría</strong></TableCell>
                        <TableCell><strong>Detalle</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Calificación:</TableCell>
                        <TableCell>{experiencia}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Aspectos a Mejorar:</TableCell>
                        <TableCell>{aspectos_a_mejorar}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Comentario:</TableCell>
                        <TableCell>{comentario}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

pintar = () => {
    let { seleccion, cargando } = this.state;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <div>
        {seleccion === 'credito' && this.pintarCredito()}
        {seleccion === 'estados' && this.pintarEstados()}
        {seleccion === 'adjuntos' && this.pintarAdjuntos()}
        {seleccion === 'pagos' && this.pintarPagos()}
        {seleccion === 'encuestas' && this.pintarEncuestas()}
      </div>
    )

  }

  pintarEncuesta = () => {
    return <EncuestaSatisfaccion actualizarMensaje={this.props.actualizarMensaje} 
    mostrarModalEncuestaCredito= {this.mostrarModalEncuestaCredito} />
    
  }
  render() {
    const { seleccion, cargando, administrador } = this.state;
    const { modalDetalleCredito, mostrarModalDetalleCredito, modalEncuestaCredito, mostrarModalEncuestaCredito, tipo_proceso_actual, estudiante } = this.props;

    return (
      <div>
        {/* Diálogo de Detalle Crédito */}
        <Dialog open={modalDetalleCredito} maxWidth='sm' fullWidth={true} onClose={() => mostrarModalDetalleCredito(false)}>
          <AppBarModal titulo='¡ Detalle Proceso !' mostrarModal={mostrarModalDetalleCredito} titulo_accion='' />
          <CreditoMenu 
            administrador={administrador} 
            sele={seleccion} 
            seleccionar={seleccion => this.setState({ seleccion })} 
            pagos={(tipo_proceso_actual && tipo_proceso_actual === 'sol_cred') || tipo_proceso_actual === 'sol_enti'} 
            docs={((tipo_proceso_actual && tipo_proceso_actual === 'sol_dev') || tipo_proceso_actual === 'sol_enti') && estudiante}
            tipo_proceso_actual={tipo_proceso_actual}
          />
          <DialogContent style={{ padding: 0 }} className='scroll'>
            {cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" /> : this.pintar()}
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => mostrarModalDetalleCredito(false)} />
          </DialogActions>
        </Dialog>
  
        {/* Diálogo de Encuesta Crédito */}
        <Dialog open={modalEncuestaCredito} maxWidth='md' fullWidth={true} onClose={() => mostrarModalEncuestaCredito(false)}>
          <AppBarModal titulo='¡Encuesta!' mostrarModal={mostrarModalEncuestaCredito} titulo_accion='' />
          <DialogContent style={{ padding: 20 }} className='scroll'>
            {this.pintarEncuesta()}
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => mostrarModalEncuestaCredito(false)} />
          </DialogActions>
        </Dialog>
      </div>
    );
}
}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { usuario } = state.redGlobal;
  return {
    usuario,
    
  }
};
Credito.propTypes = {
  //variables
  credito_id: PropTypes.number.isRequired,
  vistaDetalle: PropTypes.string.isRequired,
  modalDetalleCredito: PropTypes.bool.isRequired,
  mostrarModalDetalleCredito: PropTypes.func.isRequired,
  modalEncuestaCredito:PropTypes.bool.isRequired,
  mostrarModalEncuestaCredito: PropTypes.func.isRequired,
}


export default connect(mapStateToProps)(Credito);