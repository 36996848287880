import React, { Component } from 'react'

// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField } from '@material-ui/core';
import { DialogActions, Grid, Dialog, DialogContent, ListSubheader, Stepper, Step, StepLabel, Paper } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText, MenuItem, Checkbox, Accordion, AccordionSummary, AccordionDetails, InputLabel, FormHelperText } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import green from '@material-ui/core/colors/green';
import Select from '@material-ui/core/Select';

// FUNCIONES GENERALES
import { BtnForm, BtnCargar } from "../../general/BotonesAccion";
import { obtenerEstadosProceso, obtenerPermisosValorAlt, obtenerValoresFiltros } from "../../../global/js/funciones";
import EnviarMultiplesArchivos from "../../general/EnviarMultiplesArchivos";
import AppBarModal from '../../general/AppBarModal';
import TareasTerminadas from "../../general/TareasTerminadas";
import AlertasSimple from '../../general/AlertasSimple';

// IMAGENES
import emma_w from '../../../global/imagenes/emma_w.png';

// HELPERS
import { ESTADOS, EVIDENCIA, EVALUACION } from '../Helpers'

const configStep = ['Datos Iniciales', 'Requisitos'];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
  const classes = useStyles();
  return (
    <List className={classes.root} subheader={
      <ListSubheader component="div" >
        Seleccione Estado
      </ListSubheader>
    }>

      {estados_siguiente.map(({ secundario }) => {
        let { codigo, nombre, id } = secundario;
        return (
          <ListItem key={codigo} role={undefined} dense button onClick={() => cambiarSeleccion({ codigo, id })} >
            <ListItemIcon>
              <Checkbox edge="start" checked={estado === codigo} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={codigo} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  );
}

function AttachComponent(component_props) {
  let { evidencias, submit, adjuntarArchivos, actualizarArchivos, estado } = component_props

  const trasnformarDataAdj = (data) => {
    let r = [];
    data.map(({ secundario }) => r.push({ nombre: secundario.nombre, descripcion: secundario.descripcion, codigo: secundario.id, archivo: secundario.archivo }))
    return r;
  }

  const acciones = (data, callback) => {
    const onClickCargar = (data) => callback(data);

    let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />
    return <div>{cargar}</div>;
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} square>
            <List component="nav" arial-label="main">
              <EnviarMultiplesArchivos
                actualizarArchivos={actualizarArchivos}
                acciones={acciones}
                enModal={false}
                enviarArchivos={(archivos) => submit(archivos)}
                limite={2}
                mostrarMensaje={false}
                enLista={adjuntarArchivos}
                lista={trasnformarDataAdj(evidencias)}
                estado={estado}
              />
            </List>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}


class RetosGestionar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      titulo_res: 'Cargando..',
      estado: { codigo: '', id: '' },
      calificacion_reto: '',
      retroalimentacion: '',
      motivo: 0,
      motivos: [],
      estados_siguiente: [],
      seleccion: 'gestion',
      activeStep: 0,
      evidencias: [],
      buscarCoordinador: false,
      coordinador: {
        id: 0,
        nombre_completo: '',
      },
      fecha_examen: null,
      hora_examen: null,
      lugar_examen: '',
      url: '',
      buscarProfesor: false,
      profesor: {
        id: 0,
        nombre_completo: '',
      },
      expanded: false,
      evaluacion_final: [],
      evaluacion_1: 0,
      evaluacion_2: 0,
      evaluacion_3: 0,
      evaluacion_4: 0,
      evaluacion_5: 0,
      calificacion_reto: 0,
      archivos: [],
      evaluacion1: 0,
      estado_evidencia: ''
    }
    this.actualizarArchivos = this.actualizarArchivos.bind(this);
  }

  actualizarArchivos(nuevoArchivos) {
    this.setState({ archivos: nuevoArchivos });
  }

  async componentDidMount() {
    let evaluacion_final = await obtenerValoresFiltros([{ llave: "generica", valor: EVALUACION }, { llave: "estado", valor: "1" },]);
    this.setState({ evaluacion_final })
    console.log(evaluacion_final);
  }


  async componentDidUpdate({ modalGestionRetos }, { estado, evaluacion_1, evaluacion_2, evaluacion_3, evaluacion_4, evaluacion_5 }) {
    let { modalGestionRetos: modalGestionRetosNew, reto } = this.props;
    let { estado: estadoNew, evaluacion_1: evaluacion_1New, evaluacion_2: evaluacion_2New, evaluacion_3: evaluacion_3New, evaluacion_4: evaluacion_4New, evaluacion_5: evaluacion_5New } = this.state;

    if (estadoNew.codigo !== estado.codigo && estadoNew.codigo) {

      this.obtenerEvidencias()
      let acuerdo_reto_acta_reto = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Ret_Info_Ret_Act' }])

      this.setState({ acuerdo_reto_acta_reto: acuerdo_reto_acta_reto.length > 0 ? acuerdo_reto_acta_reto[0].descripcion : '' });
    }

    if (evaluacion_1 != evaluacion_1New) {
      this.setState({ calificacion_reto: evaluacion_1New + evaluacion_2New + evaluacion_3New + evaluacion_4New + evaluacion_5New })
    }
    if (evaluacion_2 != evaluacion_2New) {
      this.setState({ calificacion_reto: evaluacion_1New + evaluacion_2New + evaluacion_3New + evaluacion_4New + evaluacion_5New })
    }
    if (evaluacion_3 != evaluacion_3New) {
      this.setState({ calificacion_reto: evaluacion_1New + evaluacion_2New + evaluacion_3New + evaluacion_4New + evaluacion_5New })
    }
    if (evaluacion_4 != evaluacion_4New) {
      this.setState({ calificacion_reto: evaluacion_1New + evaluacion_2New + evaluacion_3New + evaluacion_4New + evaluacion_5New })
    }
    if (evaluacion_5 != evaluacion_5New) {
      this.setState({ calificacion_reto: evaluacion_1New + evaluacion_2New + evaluacion_3New + evaluacion_4New + evaluacion_5New })
    }




    if (modalGestionRetos !== modalGestionRetosNew) {
      if (modalGestionRetosNew) {
        this.reiniciarEstado();
        let estados_siguiente = await obtenerEstadosProceso(reto.tipo_reto.id, [{ 'llave': 'principal', 'valor': reto.estado_actual.id }, { 'llave': 'secundario__generica', 'valor': ESTADOS }]);
        let mensaje = []

        switch (reto.estado_actual.codigo) {
          case 'Ret_Gru':
            mensaje = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Ret_Info_Grup_Ges' }])
            break;
          case 'Ret_Env':
            mensaje = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Ret_Info_Env_Ges' }])
            break;
          case 'Ret_Sel':
            mensaje = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Ret_Info_Sel_Ges' }])
            break;
          case 'Ret_Cal':
            mensaje = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Ret_Info_Cal_Ges' }])
            break;
          default:
            break;
        }

        this.setState({
          estados_siguiente,
          porcentaje: reto && reto.porcentaje ? reto.porcentaje : '',
          cargando: false,
          mensaje: mensaje.length > 0 ? mensaje[0].descripcion : ''
        });

      }
    }
  }

  obtenerEvidencias = async () => {
    this.setState({ cargando: true })
    let { estado } = this.state
    let evidencias = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': estado.id }, { 'llave': 'secundario__generica', 'valor': EVIDENCIA }, { 'llave': 'secundario__valora', 'valor': 'gestor' }])

    this.setState({ cargando: false, evidencias })
  }


  configStepContent = () => {
    let { actualizarMensaje } = this.props
    let { activeStep, initialTransition, archivos, evidencias, estado, acuerdo_reto_acta_reto } = this.state
    let informativo_acta_reto = [{ item: acuerdo_reto_acta_reto }]

    switch (activeStep) {
      case 0:
        return (
          this.pintar()
        );
      case 1:
        return (
          <>
            <AttachComponent
              {...{
                initialTransition,
                evidencias,
                archivos,
                actualizarMensaje,
                adjuntarArchivos: true,
                eliminarArchivo: this.eliminarArchivo,
                agregarArchivos: this.agregarArchivos,
                submit: this.submit,
                estado: estado
              }}
            />

            {
              estado.codigo == 'Ret_Act' &&
              <React.Fragment>
                <AlertasSimple
                  tipo='info'
                  titulo={'Estimado gestor, tener en cuenta: '}
                  lista={informativo_acta_reto}
                  margin='0px'
                />
              </React.Fragment>
            }
          </>
        )
      default:
        return <TareasTerminadas imagen={emma_w} mensaje='Esta bandeja esta vacía' widthImg='10%' marginTop='10%' />
    }
  }

  obtenerObservaciones = async () => {
    this.setState({ cargando: true });
    let { acuerdo_validaciones } = this.state
    let obs = await obtenerPermisosValorAlt([{ 'llave': 'codigo', 'valor': 'Val_Vol_Can' }])
    let acu_validaciones = [{ item: acuerdo_validaciones }]
    obs.map(({ secundario }) => acu_validaciones.push({ item: secundario.nombre }));
    this.setState({ acu_validaciones });
  }

  submit = (archivos) => {
    let { estado, retroalimentacion, calificacion_reto } = this.state;
    let { reto, gestionarRetos, actualizarMensaje } = this.props;
    
    if (!calificacion_reto && (estado.codigo === 'Ret_Env' || estado.codigo === 'Ret_Env_e2' || estado.codigo === 'Ret_Cal' || estado.codigo === 'Ret_Cal_e2' || estado.codigo === 'Ret_Env_e3' || estado.codigo === 'Ret_Cal_e3' || estado.codigo === 'Ret_Env_Fn' || estado.codigo === 'Ret_Cal_Fn') ) {
      actualizarMensaje({ titulo: 'Debe diligenciar todos los campos obligatorios para continuar', tipo: 'info', mostrar: true, tiempo: 6000 })
      return false
    }

    if (archivos.length > 0) {
      console.log(archivos.length)
      this.setState({ cargando: true, titulo_res: 'Enviando..' })
      let data = { calificacion_reto: calificacion_reto, estado: estado.codigo, total: archivos.length, retroalimentacion_estudiante: retroalimentacion, codigo: estado.id }
      gestionarRetos(reto.id, data, archivos, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }))
    
    } else if(estado.codigo === 'Ret_Sel' || estado.codigo === 'Ret_Dep' || estado.codigo === 'Ret_Ire' || estado.codigo === 'Ret_Gse' || estado.codigo === 'Ret_Act' || estado.codigo === 'Ret_Des' || estado.codigo ==='Ret_Des_Prof' ||  estado.codigo === 'Ret_Des_Depr' || estado.codigo === 'Ret_Des_Intr' || estado.codigo === 'Ret_Cer' || estado.codigo === 'Tod_Ret'){
      this.setState({ cargando: true, titulo_res: 'Enviando..' })

      let data = {calificacion_reto: '',  estado: estado.codigo, total: 1 , retroalimentacion_estudiante: '', codigo: estado.id }
      gestionarRetos(reto.id, data, archivos, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }))
    } else{ actualizarMensaje({ titulo: 'Debe adjuntar documentos antes de continuar.', tipo: 'info', mostrar: true, tiempo: 6000 })}
  }

  agregarArchivos = files => {
    let { actualizarMensaje } = this.props
    let { archivos, evidencias } = this.state
    files.map((archivo => {
      if (archivos.length === 10000) actualizarMensaje({ titulo: `Se ha superado el numero maximo de archivos ${evidencias.length}`, tipo: 'info', mostrar: true, tiempo: 6000 })
      else archivos.push(archivo)
    }))
    this.setState({ archivos })
  }

  eliminarArchivo = (i) => {
    let { actualizarMensaje } = this.props;
    let { archivos } = this.state;
    archivos.splice(i, 1)
    this.setState({ archivos })
    actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
  }

  reiniciarEstado() {
    this.setState({
      cargando: true,
      titulo_res: 'Cargando..',
      estado: { codigo: '', id: '' },
      estados_siguiente: [],
      seleccion: 'gestion',
      evidencias: [],
      activeStep: 0,
    })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  handleStep = direction => {
    let { actualizarMensaje } = this.props
    let { activeStep, estado } = this.state
    let sw = true

    if (!estado.codigo && activeStep == 0) {
      actualizarMensaje({ titulo: 'Debe diligenciar todos los campos obligatorios para continuar', tipo: 'info', mostrar: true, tiempo: 6000 });
      sw = false
    }
    if (sw) this.setState({ activeStep: direction ? activeStep + 1 : activeStep - 1 })
  }



  pintarEstados() {
    let { estado, estados_siguiente, calificacion_reto, retroalimentacion, expanded, evaluacion_final, evaluacion_1, evaluacion_2, evaluacion_3, evaluacion_4, evaluacion_5, resultado_final, evaluacion1, initialTransition, evidencias, actualizarMensaje } = this.state;

    const handleChange = (panel) => (event, isExpanded) => {
      this.setState({ expanded: isExpanded ? panel : false });

    };

    const handleChangeEvaluacion = (e) => {

      if (e.target.name == "evaluacion1") {
        this.setState({ evaluacion1: e.target.value, evaluacion_1: Number(e.target.value) * 30 / 100 });
      }

      if (e.target.name == "evaluacion2") {
        this.setState({ evaluacion2: e.target.value, evaluacion_2: Number(e.target.value) * 20 / 100 });
      }


      if (e.target.name == "evaluacion3") {
        this.setState({ evaluacion3: e.target.value, evaluacion_3: Number(e.target.value) * 10 / 100 });
      }

      if (e.target.name == "evaluacion4") {
        this.setState({ evaluacion4: e.target.value, evaluacion_4: Number(e.target.value) * 30 / 100 });
      }

      if (e.target.name == "evaluacion5") {
        this.setState({ evaluacion5: e.target.value, evaluacion_5: Number(e.target.value * 10 / 100) });
      }

    };


    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
            <RenderEstados cambiarSeleccion={(estado) => this.setState({ estado })} estado={estado.codigo} estados_siguiente={estados_siguiente} />
          </Grid>
          {estado.codigo == 'Ret_Cal' && (
            <>
              {
                <Grid item xs={12} md={12}>
                  <FormControl className="form-control">
                    <TextField
                      value={calificacion_reto}
                      id="txtMotivoNegado"
                      type="number"
                      label="Ingresar evaluación"
                      name="calificacion_reto"
                      placeholder="Ingrese evaluación"
                      multiline={true}
                      minRows={2}
                      onChange={this.onChange}
                    />
                  </FormControl>
                </Grid>
              }
              {/* {
                <Grid item xs={12} md={12}>
                  <FormControl className="form-control">
                    <TextField
                      value={retroalimentacion}
                      id="txtMotivoNegado"
                      label="Retroalimentación"
                      name="retroalimentacion"
                      placeholder={"Ingrese la retroalimentación"}
                      multiline={true}
                      minRows={5}
                      onChange={this.onChange}
                    />
                  </FormControl>
                </Grid>
              } */}

              {
                // Archivo de retroalimentacion de los profesores
                <Grid item xs={12}>
                  <AttachComponent
                    {...{
                      initialTransition,
                      gestionRegistro: this.setState,
                      evidencias,
                      actualizarMensaje,
                      adjuntarArchivos: false,
                      eliminarArchivo: this.eliminarArchivo,
                      agregarArchivos: this.agregarArchivos,
                      actualizarArchivos: this.actualizarArchivos,
                      submit: this.submit,
                      estado,
                    }}
                  />
                </Grid>
              }
            </>
          )}

          {estado.codigo == 'Ret_Cal_e2' && (
            <>
              {
                <Grid item xs={12} md={12}>
                  <FormControl className="form-control">
                    <TextField
                      value={calificacion_reto}
                      id="txtMotivoNegado"
                      type="number"
                      label="Ingresar evaluación"
                      name="calificacion_reto"
                      placeholder="Ingrese evaluación"
                      multiline={true}
                      minRows={2}
                      onChange={this.onChange}
                    />
                  </FormControl>
                </Grid>
              }
              {/* {
                <Grid item xs={12} md={12}>
                  <FormControl className="form-control">
                    <TextField
                      value={retroalimentacion}
                      id="txtMotivoNegado"
                      label="Retroalimentación"
                      name="retroalimentacion"
                      placeholder={"Ingrese la retroalimentación"}
                      multiline={true}
                      minRows={5}
                      onChange={this.onChange}
                    />
                  </FormControl>
                </Grid>
              } */}
              {
                // Archivo de retroalimentacion de los profesores
                <Grid item xs={12}>
                  <AttachComponent
                    {...{
                      initialTransition,
                      gestionRegistro: this.setState,
                      evidencias,
                      actualizarMensaje,
                      adjuntarArchivos: false,
                      eliminarArchivo: this.eliminarArchivo,
                      agregarArchivos: this.agregarArchivos,
                      actualizarArchivos: this.actualizarArchivos,
                      submit: this.submit,
                      estado,
                    }}
                  />
                </Grid>
              }
            </>
          )}

          {estado.codigo == 'Ret_Cal_e3' && (
            <>
              {
                <Grid item xs={12} md={12}>
                  <FormControl className="form-control">
                    <TextField
                      value={calificacion_reto}
                      id="txtMotivoNegado"
                      type="number"
                      label="Ingresar evaluación"
                      name="calificacion_reto"
                      placeholder="Ingrese evaluación"
                      multiline={true}
                      minRows={2}
                      onChange={this.onChange}
                    />
                  </FormControl>
                </Grid>
              }
              {/* {
                <Grid item xs={12} md={12}>
                  <FormControl className="form-control">
                    <TextField
                      value={retroalimentacion}
                      id="txtMotivoNegado"
                      label="Retroalimentación"
                      name="retroalimentacion"
                      placeholder={"Ingrese la retroalimentación"}
                      multiline={true}
                      minRows={5}
                      onChange={this.onChange}
                    />
                  </FormControl>
                </Grid>
              } */}
              {
                // Archivo de retroalimentacion de los profesores
                <Grid item xs={12}>
                  <AttachComponent
                    {...{
                      initialTransition,
                      gestionRegistro: this.setState,
                      evidencias,
                      actualizarMensaje,
                      adjuntarArchivos: false,
                      eliminarArchivo: this.eliminarArchivo,
                      agregarArchivos: this.agregarArchivos,
                      actualizarArchivos: this.actualizarArchivos,
                      submit: this.submit,
                      estado,
                    }}
                  />
                </Grid>
              }
            </>
          )}

          {estado.codigo == 'Ret_Cal_Fn' && (
            <>
              {
                <Grid item xs={12} md={12}>
                  <Accordion expanded={expanded === 'granp'} onChange={handleChange('granp')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                      <p>Rubrica</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        <ListItem>
                          <ListItemAvatar>
                            <CheckCircleIcon style={{ color: green[500] }} />
                          </ListItemAvatar>
                          <ListItemText
                            primary="PERTINENCIA: La solución presentada responde a las necesidades de la empresa y contribuye a resolver el problema planteado.
                            PONDERACIÓN: 30%"
                          />
                        </ListItem>
                        <ListItem>
                          <FormControl className="form-control">
                            <InputLabel id="select-evaluation">Evaluar Pertinencia</InputLabel>
                            <Select
                              labelId="select-evaluation"
                              id="evaluacion1"
                              name='evaluacion1'
                              onChange={handleChangeEvaluacion}
                            >
                              {evaluacion_final?.map((item) => (
                                <MenuItem value={item.nombre}>{item.nombre}</MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>Seleccionar una opción</FormHelperText>
                          </FormControl>
                        </ListItem>

                        <ListItem>
                          <ListItemAvatar>
                            <CheckCircleIcon style={{ color: green[500] }} />
                          </ListItemAvatar>
                          <ListItemText
                            primary="VIABILIDAD: La solución tiene muchas posibilidades de llevarse a cabo para resolver el problema y progresar.
                            PONDERACIÓN: 20%"
                          />
                        </ListItem>
                        <ListItem>
                          <FormControl className="form-control">
                            <InputLabel id="select-evaluation">Evaluar Viabilidad</InputLabel>
                            <Select
                              labelId="select-evaluation"
                              id="evaluacion2"
                              name='evaluacion2'
                              onChange={handleChangeEvaluacion}
                            >
                              {evaluacion_final?.map((item) => (
                                <MenuItem value={item.nombre}>{item.nombre}</MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>Seleccionar una opción</FormHelperText>
                          </FormControl>
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <CheckCircleIcon style={{ color: green[500] }} />
                          </ListItemAvatar>
                          <ListItemText
                            primary="FUNCIONALIDAD: La solución contiene usos y funciones que responden directamente al problema.
                            PONDERACIÓN: 10%"
                          />
                        </ListItem>
                        <ListItem>
                          <FormControl className="form-control">
                            <InputLabel id="select-evaluation">Evaluar Funcionalidad</InputLabel>
                            <Select
                              labelId="select-evaluation"
                              id="evaluacion3"
                              name='evaluacion3'
                              onChange={handleChangeEvaluacion}
                            >
                              {evaluacion_final?.map((item) => (
                                <MenuItem value={item.nombre}>{item.nombre}</MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>Seleccionar una opción</FormHelperText>
                          </FormControl>
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <CheckCircleIcon style={{ color: green[500] }} />
                          </ListItemAvatar>
                          <ListItemText
                            primary="INNOVACIÓN: La solución, ha creado o adaptado un modelo novedoso para abordar el problema; ha generado alternativas diferentes a las tradicionales para resolver el reto.
                            PONDERACIÓN: 30%"
                          />
                        </ListItem>
                        <ListItem>
                          <FormControl className="form-control">
                            <InputLabel id="select-evaluation">Evaluar Innovación</InputLabel>
                            <Select
                              labelId="select-evaluation"
                              id="evaluacion4"
                              name='evaluacion4'
                              onChange={handleChangeEvaluacion}
                            >
                              {evaluacion_final?.map((item) => (
                                <MenuItem value={item.nombre}>{item.nombre}</MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>Seleccionar una opción</FormHelperText>
                          </FormControl>
                        </ListItem>

                        <ListItem>
                          <ListItemAvatar>
                            <CheckCircleIcon style={{ color: green[500] }} />
                          </ListItemAvatar>
                          <ListItemText
                            primary="COMUNICACIÓN DE LA SOLUCIÓN: La solución se
                            sustenta con claridad y apropiación, permitiendo que los actores la comprendan y analicen.
                            PONDERACIÓN: 10%"
                          />
                        </ListItem>
                        <ListItem>
                          <FormControl className="form-control">
                            <InputLabel id="select-evaluation">Evaluar Comunicación</InputLabel>
                            <Select
                              labelId="select-evaluation"
                              id="evaluacion5"
                              name='evaluacion5'
                              onChange={handleChangeEvaluacion}
                            >
                              {evaluacion_final?.map((item) => (
                                <MenuItem value={item.nombre}>{item.nombre}</MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>Seleccionar una opción</FormHelperText>
                          </FormControl>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              }
              {
                <Grid item xs={12} md={12}>
                  <FormControl className="form-control">
                    <TextField
                      value={calificacion_reto.toFixed(1)}
                      id="txtMotivoNegado"
                      type="number"
                      label="Ingresar evaluación"
                      name="calificacion_reto"
                      placeholder="Ingrese evaluación"
                      multiline={true}
                      minRows={2}
                      onChange={this.onChange}
                    />
                  </FormControl>
                </Grid>
              }
              {/* {
                <Grid item xs={12} md={12}>
                  <FormControl className="form-control">
                    <TextField
                      value={retroalimentacion}
                      id="txtMotivoNegado"
                      label="Retroalimentación"
                      name="retroalimentacion"
                      placeholder={"Ingrese la retroalimentación"}
                      multiline={true}
                      minRows={5}
                      onChange={this.onChange}
                    />
                  </FormControl>
                </Grid>
              } */}
              {
                // Archivo de retroalimentacion de los profesores
                <Grid item xs={12}>
                  <AttachComponent
                    {...{
                      initialTransition,
                      gestionRegistro: this.setState,
                      evidencias,
                      actualizarMensaje,
                      adjuntarArchivos: false,
                      eliminarArchivo: this.eliminarArchivo,
                      agregarArchivos: this.agregarArchivos,
                      actualizarArchivos: this.actualizarArchivos,
                      submit: this.submit,
                      estado,
                    }}
                  />
                </Grid>
              }
            </>
          )}
        </Grid>
      </>
    )
  }

  pintar() {
    let { seleccion } = this.state;
    return (<div>
      {seleccion === 'gestion' && this.pintarEstados()}
    </div>)
  }

  render() {
    let { modalGestionRetos, mostrarGestionRetos } = this.props;
    let { estado, cargando, titulo_res, seleccion, accepted, activeStep, evidencias, mensaje, archivos } = this.state;
    let mensaje_reto = mensaje ? [{ item: mensaje }] : []

    return (
      <Dialog fullWidth={true} maxWidth="sm" open={modalGestionRetos} onClose={() => mostrarGestionRetos(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo={'¿ Gestionar  ?'} mostrarModal={mostrarGestionRetos} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          {
            !cargando ?
              <Grid container>
                <Grid item xs={12} md={12}>
                  {
                    evidencias && evidencias[0] &&
                    <Stepper activeStep={activeStep}>
                      {
                        configStep.map(label => (
                          <Step key={label}>
                            <StepLabel><p>{label}</p></StepLabel>
                          </Step>
                        ))
                      }
                    </Stepper>
                  }
                </Grid>
                {
                  <Grid item xs={12} style={{ marginTop: "30px" }}>
                    {this.configStepContent()}
                    {
                      mensaje &&
                      <AlertasSimple
                        tipo='info'
                        titulo={'Estimado gestor, tener en cuenta: '}
                        lista={mensaje_reto}
                      />
                    }
                  </Grid>
                }
              </Grid> :
              <TareasTerminadas mensaje={titulo_res} marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
        </DialogContent>
        <DialogActions>
          {
            evidencias && evidencias[0] && !cargando ?
              activeStep > 0 ?
                <div>
                  <BtnForm texto="ATRAS" callback={() => this.handleStep(false)} />
                  <BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_multi_archivo').click() }} type="submit" />
                </div>
                : <BtnForm texto="SIGUIENTE" callback={() => this.handleStep(true)} type="submit" />
              :
              <div>
                {
                  !cargando &&
                  <BtnForm texto="GUARDAR" callback={(estado.codigo !== 'Ret_Sel' && estado.codigo !== 'Ret_Dep' && estado.codigo !== 'Ret_Ire' && estado.codigo !== 'Ret_Gse' && estado.codigo !== 'Ret_Des' && estado.codigo !== 'Ret_Des_Prof' && estado.codigo !== 'Ret_Des_Depr' && estado.codigo !== 'Ret_Des_Intr' && estado.codigo !== 'Ret_Act' && estado.codigo !=='Ret_Cer' && estado.codigo !== 'Tod_Ret') ? () => { document.getElementById('enviar_form_multi_archivo').click() } : () => { this.submit([]) }} type="submit"/>
                }
              </div>
          }

        </DialogActions>
      </Dialog>
    )
  }
}

export default RetosGestionar;