import React, { Component, forwardRef } from 'react'
import { Dialog, DialogContent, DialogActions, Grid, InputLabel, Select, MenuItem, FormControl,Checkbox,ListItemText, IconButton, Tooltip } from '@material-ui/core';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import { consulta, obtenerValoresFiltros, transformar, generarFiltros } from '../../../global/js/funciones'
import TareasTerminadas from "./../../general/TareasTerminadas";
import emma_w from './../../../global/imagenes/emma_w.png';
import emma_s from './../../../global/imagenes/emma_s1.png';
import BuscarSelect from '../../general/BuscarSelect';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListarTabla from '../../general/ListarTabla';
import PracticaInformeEmpresa from '../administrar/PracticaInformeEmpresa';

class PracticaInforme extends Component {
   constructor(props) {
      super(props)
      this.state = {
         columnas: [],
         data_informe: [],
         cargando: false,
         periodo: '',
         programa: '',
         periodos: [],
         programas: [],
         mostrar : 'filtros',
         maxWidth : 'sm',
         fechaActual:'',
         fecha:'',
         cargar: true,
         modalidad: '',
         modalidades: [], 
         trabaja: '',
         busca_empresa: '',
         programasSelect: [],
         programasMulti: [],
         periodosMulti: [],
         modalidadesMulti: [],
         camposMostrar: [],
         todoMulti: [],
         practicas: [],
         programaDetalle: '',
         contratosMulti: [],
         contratos: [],
         estadoSolicitud: '',
         meses: [],
         mes: ''
      };
   }

   async componentDidUpdate({ modalInforme }, { mostrar }) {
      let { mostrar: mostrarNew, cargar } = this.state;
      let { modalInforme: modalInformeNew, informe } = this.props;
      if (modalInforme !== modalInformeNew) {
         if (modalInformeNew && cargar) {
            this.setState({ cargando: true })
            const periodos = await this.obtenerPeriodos();
            const programas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 5 }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valorc', 'valor': 'pregrado' }]);
            const modalidades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 11 }, { 'llave': 'estado', 'valor': 1 }]);
            const contratos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 60 }, { 'llave': 'estado', 'valor': 1 }]);

            this.setState({ periodos, cargando: false, cargar: false, 'programas': transformar(programas), 'programasSelect': programas, modalidades, contratos })
         }
         else this.resetState()
      }
   }

   obtenerPeriodos = () => {
      return new Promise(resolve => {
         consulta(`api/v1.0/practicas/periodos`, null, null,
            (error, estado, resp) => {
               resolve(resp)
            }
         );
      })
   }

   obtenerMeses = (periodo, programa) => {
      
         consulta(`api/v1.0/practicas/meses/${periodo}/${programa}`, null, null,
            (error, estado, resp) => {
               if (estado === 200) this.setState({ meses: resp });
            }
         );
      
   }

   obtenerPracticas = async () => {
      let { periodo, modalidad, trabaja, busca_empresa, programaDetalle, programasMulti, periodosMulti, modalidadesMulti, estadoSolicitud, mes } = this.state
      let filtros = []

      if (periodo !== '') filtros.push({ 'llave': 'periodo', 'valor': periodo });
      if (programaDetalle.codigo !== '') filtros.push({ 'llave': 'programa', 'valor': programaDetalle.codigo });
      if (modalidad !== '') filtros.push({ 'llave': 'tipo', 'valor': modalidad });
      if (trabaja !== '') filtros.push({ 'llave': 'trabaja', 'valor': trabaja });
      if (busca_empresa !== '') filtros.push({ 'llave': 'busca_empresa', 'valor': busca_empresa });

      if (estadoSolicitud !== '' && estadoSolicitud !== null) filtros.push({ 'llave': 'estadoSolicitud', 'valor': estadoSolicitud });
      if (mes !== '' && mes !== null) filtros.push({ 'llave': 'mes', 'valor': mes }); 

      if (programasMulti.length>0) filtros.push({ 'llave': 'programa_in', 'valor': programasMulti });
      if (periodosMulti.length>0) filtros.push({ 'llave': 'periodo_in', 'valor': periodosMulti });
      if (modalidadesMulti.length>0) filtros.push({ 'llave': 'modalidad_in', 'valor': modalidadesMulti });
      
      let f = await generarFiltros(filtros);
      this.setState({ cargando: true });
      consulta(`api/v1.0/practicas/detalleinforme?${f}`, null, null,
        (error, estado, resp) => {
          if (estado === 200) this.setState({ mostrar: 'detalle', cargando: false, practicas: resp, maxWidth: 'lg' });
        }
      );
   }

   onChange = ({ target }) => {
      let { value, name } = target
      this.setState({ [name]: value })
   }

   resetState = () => {
      let { mostrar } = this.state

      if(mostrar==='filtros') this.setState({ data_informe: [], columnas: [], mostrar: 'filtros', maxWidth: 'sm', periodo: '', programa: '', modalidad: '',todoMulti: [], trabaja: '', busca_empresa: '', programasMulti: [], periodosMulti: [], modalidadesMulti: [], camposMostrar: [], contratosMulti: [], practicas: [], estadoSolicitud: '', mes: '', cargando: false })
      else if(mostrar === 'informe') this.setState({ mostrar:'filtros', maxWidth: 'sm', columnas: [], todoMulti: [],cargando: false })
      else if(mostrar === 'filtroDetalle') this.setState({ mostrar:'informe', maxWidth: 'lg', practicas: [], cargando: false })
      else this.setState({ mostrar:'filtroDetalle', maxWidth: 'sm', cargando: false })
   }

   onSubmit = (e) => {
      let { periodo, programa, modalidad, trabaja, busca_empresa, programasMulti, periodosMulti, modalidadesMulti, contratosMulti } = this.state
      let { actualizarMensaje, informe } = this.props
      let filtros = [], titulo='';

      if(informe.codigo=="inf_din"){
         filtros.push({ 'llave': 'informe', 'valor': informe.codigo });
         if (programasMulti.length>0) filtros.push({ 'llave': 'programa_in', 'valor': programasMulti });
         if (periodosMulti.length>0) filtros.push({ 'llave': 'periodo_in', 'valor': periodosMulti });
         if (modalidadesMulti.length>0) filtros.push({ 'llave': 'modalidad_in', 'valor': modalidadesMulti });
         if (contratosMulti.length>0) filtros.push({ 'llave': 'contrato_in', 'valor': contratosMulti });
         
      }else if(informe.codigo=="Inf_Not"){
         filtros.push({ 'llave': 'informe', 'valor': informe.codigo });
         if (periodosMulti.length>0) filtros.push({ 'llave': 'periodos', 'valor': periodosMulti });
         else titulo = 'Por favor, seleccione el periodo.'
      }else{
         if(periodo !== ''){
            filtros.push({ 'llave': 'informe', 'valor': informe.codigo }); 
            filtros.push({ 'llave': 'periodo', 'valor': periodo });

            if (programa !== '') filtros.push({ 'llave': 'programa', 'valor': programa.value });
            if (modalidad !== '') filtros.push({ 'llave': 'tipo', 'valor': modalidad });
            if (trabaja !== '') filtros.push({ 'llave': 'trabaja', 'valor': trabaja });
            if (busca_empresa !== '') filtros.push({ 'llave': 'busca_empresa', 'valor': busca_empresa });

            if ((informe.codigo=="Inf_Pro" || informe.codigo=="Inf_Eval") && programa=='') titulo = 'Por favor, seleccione el programa.'
         }else titulo = 'Por favor, seleccione el periodo.'
      }


      if(titulo) actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
      else this.obtenerInforme(filtros)
      e.preventDefault();
   }
   
   obtenerInforme = async (filtros = []) => {
      let { actualizarMensaje } = this.props
      let f = await generarFiltros(filtros);
      this.setState({ cargando: true });
      consulta(`api/v1.0/practicas/informe?${f}`, null, null,
        (error, estado, resp) => {
         if (estado === 200) {
            if(resp.error){
               actualizarMensaje({ titulo: resp.error, tipo: 'info', mostrar: true, tiempo: 6000 });
               this.setState({ cargando: false });
            } else {
               this.setState({ cargando: false, data_informe: resp, mostrar: 'informe', maxWidth: 'lg' });
            }
         } else actualizarMensaje({ titulo: 'Ha ocurrido un error, contacte con el administrador.', tipo: 'info', mostrar: true, tiempo: 6000 });
        }
      );

   }

   pintarNombres = (selected, listado) => {
      let resp = [];

      let a = listado.filter(({id}) => selected.indexOf(id)!=-1)
      a.map(({nombre}) =>resp.push(nombre))

      return resp.join(", ")
   }

   pintarNombresPeriodo = (selected, listado) => {
      let resp = [];

      let a = listado.filter((e) => selected.indexOf(e)!=-1)
      a.map((e)=>resp.push(e))

      return resp.join(", ")
   }

   pintar = () => {
      let { cargando, mostrar } = this.state;
      if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
      return (
         <div>
            {mostrar === 'filtros' && this.pintarFiltros()}
            {mostrar === 'informe' && this.pintarInforme()}
            {mostrar === 'filtroDetalle' && this.pintarFiltroDetalle() }
            {mostrar === 'detalle' && this.pintarDetalle()}
         </div>
      )
   }

   pintarFiltros = () => {
      let { informe } = this.props
      let { periodo, periodos, programa, programas, modalidad, modalidades, trabaja, busca_empresa, programasSelect, programasMulti, periodosMulti, modalidadesMulti, camposMostrar, contratosMulti, contratos } = this.state
      console.log(informe)
      if(informe.codigo!="inf_din" && informe.codigo!="Inf_Emp" && informe.codigo!='Inf_Not'){
        
         return(
            <Grid container spacing={3} className='margin_cont'>
               <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className='form-control'>
                     <InputLabel htmlFor="periodo">Seleccione Periodo</InputLabel>
                     <Select
                        inputProps={{
                           name: 'periodo',
                           id: 'periodo',
                        }}
                        value={periodo}
                        onChange={this.onChange}
                        required={true}>
                        {periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
                     </Select>
                  </FormControl>
               </Grid>
               <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <BuscarSelect 
                     datos={programas} 
                     cambiarEstado={(value) => this.setState({ programa: value })} 
                     valor={programa} 
                     id='programa' 
                     nombre='Programa' 
                     placeholder='Seleccione Programa' />
               </Grid>
               { informe.codigo != 'Inf_Pro' && 
               <>
              
               <Grid item xs={12} xl={12} sm={12} md={6} lg={6} >
                  <FormControl className='form-control'>
                     <InputLabel htmlFor="modalidad">Seleccione Modalidad</InputLabel>
                     <Select
                        inputProps={{
                        name: 'modalidad',
                        id: 'modalidad',
                        }}
                        value={modalidad}
                        onChange={this.onChange}>
                        {
                           modalidades.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                        }
                     </Select>
                  </FormControl>
               </Grid>
               <Grid item xs={12} xl={12} sm={12} md={6} lg={6} >
                  <FormControl className='form-control'>
                     <InputLabel htmlFor="trabaja">Trabajan</InputLabel>
                     <Select
                        inputProps={{
                        name: 'trabaja',
                        id: 'trabaja',
                        }}
                        value={trabaja}
                        onChange={this.onChange}
                        required={true}>
                        <MenuItem value={1}>SI</MenuItem>
                        <MenuItem value={0}>NO</MenuItem>
                        <MenuItem value={-1}>No Aplica</MenuItem>
                     </Select>
                  </FormControl>
               </Grid>
               <Grid item xs={12} xl={12} sm={12} md={6} lg={6} >
                  <FormControl className='form-control'>
                     <InputLabel htmlFor="busca_empresa">Requieren Ubicación</InputLabel>
                     <Select
                        inputProps={{
                        name: 'busca_empresa',
                        id: 'busca_empresa',
                        }}
                        value={busca_empresa}
                        onChange={this.onChange}>
                        <MenuItem value={1}>SI</MenuItem>
                        <MenuItem value={0}>NO</MenuItem>
                        <MenuItem value={-1}>No Aplica</MenuItem>
                     </Select>
                  </FormControl>
               </Grid>
               </>
               }
            </Grid> 
         )
      }
    
      else if (informe.codigo==="inf_din"){
         console.log(informe.codigo)
         const camposSelect = [
            { id: 'matriculados',  nombre: 'N° MATRICULADOS' },
            { id: 'nreconocimiento',  nombre: 'N° VALIDACION POR COMPETENCIA' },
            { id: 'nsinreconocimineto',  nombre: 'TOTAL ESTUDIANTES SIN INCLUIR VALIDACION' },
            { id: 'diplomado',  nombre: 'N° DIPLOMADOS' },
            { id: 'nsindiplomado',  nombre: 'TOTAL ESTUDIANTES SIN DIPLOMADO' },
            { id: 'ncolocados',  nombre: 'N° ESTUDIANTES COLOCADOS (SI)' },
            { id: 'tiempoubicacion',  nombre: 'TIEMPO UBICACION' },
            { id: 'ubicacionestudiante',  nombre: 'UBICACION POR ESTUDIANTE' },
            { id: 'gradosatisfacion',  nombre: 'GRADO DE SATISFACCION' }
         ]
         

         const selecionarTodo = () => this.setState({ camposMostrar : camposSelect.map( ({id})=> id )})
         const selecionarCampo = (value)=>{
            this.setState({ camposMostrar: (camposMostrar.indexOf(value)===-1) ? [...camposMostrar, value] : camposMostrar.filter(item => item!=value) })
         }

        

         return(
            <>
               <Grid container spacing={3} className='margin_cont'> 
                  <InputLabel>Campos a Filtrar</InputLabel>       
                  <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                     <FormControl className='form-control'>
                        <InputLabel htmlFor="periodosMulti">Seleccione Periodo</InputLabel>
                        <Select
                           inputProps={ { name: 'periodosMulti', id: 'periodosMulti' }}
                           multiple
                           value={periodosMulti}
                           onChange={this.onChange}
                           renderValue={selected => { return this.pintarNombresPeriodo(selected, periodosMulti)}}
                           required={true}>
                           { periodos.map(({ periodo }, i) => 
                              <MenuItem key={i} value={periodo}>
                                 <Checkbox checked={periodosMulti.indexOf(periodo)>-1}/>
                                 <ListItemText primary={periodo} />
                              </MenuItem>
                           ) }
                        </Select>
                     </FormControl>
                  </Grid>
                  <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                     <FormControl className='form-control'>
                        <InputLabel htmlFor="programasMulti">Seleccione programa</InputLabel>
                        <Select
                           inputProps={{ name: 'programasMulti',id: 'programasMulti' }}
                           multiple
                           value={programasMulti}
                           onChange={this.onChange}
                           renderValue={selected => { return this.pintarNombres(selected, programasSelect)}}
                           required={true}>
                           { programasSelect.map(({ id, nombre }, i) => 
                              <MenuItem key={i} value={id}>
                                 <Checkbox checked={programasMulti.indexOf(id)>-1}/>
                                 <ListItemText primary={nombre} />
                              </MenuItem>
                           )}
                        </Select>
                     </FormControl>
                  </Grid>
                  <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                     <FormControl className='form-control'>
                        <InputLabel htmlFor="modalidadesMulti">Seleccione Modalidad</InputLabel>
                        <Select
                           inputProps={{ name: 'modalidadesMulti', id: 'modalidadesMulti' }}
                           multiple
                           value={modalidadesMulti}
                           onChange={this.onChange}
                           renderValue={selected => { return this.pintarNombres(selected, modalidades)}}
                           required={true}>
                           { modalidades.map(({ id, nombre }, i) => 
                              <MenuItem key={i} value={id}>
                                 <Checkbox checked={modalidadesMulti.indexOf(id)>-1}/>
                                 <ListItemText primary={nombre} />
                              </MenuItem>
                           )}
                        </Select>
                     </FormControl>
                  </Grid>
                  <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                     <FormControl className='form-control'>
                        <InputLabel htmlFor="contratosMulti">Seleccione Tipo de contrato</InputLabel>
                        <Select
                           inputProps={{ name: 'contratosMulti', id: 'contratosMulti' }}
                           multiple
                           value={contratosMulti}
                           onChange={this.onChange}
                           renderValue={selected => { return this.pintarNombres(selected, contratos)}}
                           required={true}>
                           { contratos.map(({ id, nombre }, i) => 
                              <MenuItem key={i} value={id}>
                                 <Checkbox checked={contratosMulti.indexOf(id)>-1}/>
                                 <ListItemText primary={nombre} />
                              </MenuItem>
                           )}
                        </Select>
                     </FormControl>
                  </Grid>
               </Grid> 
               <Grid container spacing={3} className='margin_cont'>
                  <FormLabel component="legend">Campos a Mostrar</FormLabel>
                  <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                     <Box sx={{ display: 'flex' }}>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                           <FormGroup>
                              <FormControlLabel disabled={camposSelect.filter(({id})=>camposMostrar.indexOf(id)===-1).length==0} control={<Checkbox checked={camposSelect.filter( ({id}) => camposMostrar.indexOf(id)===-1 ).length==0} onChange={ () => selecionarTodo() } />} label="Seleccionar todo" />
                              { camposSelect.map(({id, nombre }, i) => 
                                 <FormControlLabel key={i} control={ <Checkbox checked={camposMostrar.indexOf(id) !== -1} onChange={ () => selecionarCampo(id) } /> } label={nombre} />
                              )}
                           </FormGroup>
                        </FormControl>
                     </Box>
                  </Grid>
               </Grid>
            </>
         )
      }else if(informe.codigo =='Inf_Emp') {
         return(
         <>
          <Grid container spacing={3} className='margin_cont'> 
                  <InputLabel>Campos a Filtrar</InputLabel>       
                  <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                     <FormControl className='form-control'>
                        <InputLabel >Seleccione Periodo</InputLabel>
                       
                     </FormControl>
                  </Grid>
                  </Grid>
         
         </>
         )
      }else if (informe.codigo =='Inf_Not') {
         return(
         <>
            <Grid container spacing={3} className='margin_cont'>
               <InputLabel>Campos a Filtrar</InputLabel>
               <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className='form-control'>
                     <InputLabel htmlFor="periodosMulti">Seleccione Periodo</InputLabel>
                     <Select
                        inputProps={{ name: 'periodosMulti', id: 'periodosMulti' }}
                        multiple
                        value={periodosMulti}
                        onChange={this.onChange}
                        renderValue={selected => { return this.pintarNombresPeriodo(selected, periodosMulti) }}
                        required={true}>
                        {periodos.map(({ periodo }, i) =>
                           <MenuItem key={i} value={periodo}>
                              <Checkbox checked={periodosMulti.indexOf(periodo) > -1} />
                              <ListItemText primary={periodo} />
                           </MenuItem>
                        )}
                     </Select>
                  </FormControl>
               </Grid>
            </Grid>

         </>
            )
      }
   }

   pintarInforme = () => {
      let { data_informe, periodo, columnas, modalidadesMulti,todoMulti,modalidades } = this.state
      let { informe } = this.props
      let ordenar = false;
      let Total = true;
      

      modalidades.map((registro) => {
         modalidadesMulti.map(mult => {
            if (mult == registro.id) todoMulti.push(registro.codigo);
         })
      });

      if(data_informe.length>0){
         if(columnas.length==0){
            let codigo = informe.codigo

            let campos={}
            if (codigo != 'Inf_Not' && codigo != 'Inf_Eval') {
               columnas = [
                  { title: 'Programa académico', field: 'programa_academico' },
                  { title: 'Matriculados', field: 'matriculados' },
               ]
            } else columnas = []
      
            if (codigo === 'Inf_Fin'){
               let proximo = periodo[4]=='2' ? (parseInt(periodo.substring(0,4))+1)+'1' : periodo.substring(0,4)+'2'
               let meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre', 'Enero2', 'Febrero2', 'Marzo2', 'Abril2', 'Mayo2', 'Junio2']

               columnas.push(
                  { title: 'Pendientes por Ubicar', field: 'pendiente_ubi' },
                  { title: 'Sin Fecha Finalización', field: 'sin_fecha'},
                  { title: 'Pendientes por Finalizar', field: 'pendiente_fin' },
                  { title: 'Finalizados', field: 'finalizados' },  
                  { title: 'Enero', field: 'Enero' },
                  { title: 'Febrero', field: 'Febrero' },
                  { title: 'Marzo', field: 'Marzo' },
                  { title: 'Abril', field: 'Abril' },
                  { title: 'Mayo', field: 'Mayo' },
                  { title: 'Junio', field: 'Junio' },
                  { title: 'Julio', field: 'Julio' },
                  { title: 'Agosto', field: 'Agosto' },
                  { title: 'Septiembre', field: 'Septiembre' },
                  { title: 'Octubre', field: 'Octubre' },
                  { title: 'Noviembre', field: 'Noviembre' },
                  { title: 'Diciembre', field: 'Diciembre'  },
                  { title: 'Enero - '+proximo, field: 'Enero2' },
                  { title: 'Febrero - '+proximo, field: 'Febrero2' },
                  { title: 'Marzo - '+proximo, field: 'Marzo2' },
                  { title: 'Abril - '+proximo, field: 'Abril2' },
                  { title: 'Mayo - '+proximo, field: 'Mayo2' },
                  { title: 'Junio - '+proximo, field: 'Junio2' },
                  { title: 'Si Nota', field: 'si_nota' },
                  { title: 'No Nota', field: 'no_nota' },
               )
               
      
               // for (let clave in Total){
               //    if(meses.indexOf(clave)!=-1){
               //       if(Total[clave] === 0) columnas = columnas.filter( item => item.field!=clave )
               //    }
               // }
      
            }else if(codigo === 'Inf_Col'){
     
               columnas.push(
                  { title: 'COLOCADOS', field: 'colocados' },
                  { title: 'NO COLOCADOS', field: 'no_colocados' },
               )
            
               data_informe.map((e)=>{
                  let porcentaje = ((parseInt(e.colocados)/parseInt(e.matriculados))*100)
                  e.colocados += '  - ('+porcentaje.toFixed(2)+'%)'
      
                  porcentaje = ((parseInt(e.no_colocados)/parseInt(e.matriculados))*100)
                  e.no_colocados += ' - ('+porcentaje.toFixed(2)+'%)'
               })
            }else if(codigo === 'Inf_Sat'){
               columnas.push(
                  { title: 'SATISFACCIÓN', field: 'satisfaccion' },
               )
            }else if(codigo === 'Inf_Gen'){
      
               columnas.push(
                  { title: 'Validaciones Competencias', field: 'validaciones' },
                  { title: 'Empresarial', field: 'empresarial' },
                  { title: 'Investigativa', field: 'investigativa' },
                  { title: 'Emprendimiento', field: 'emprendimiento' },
                  { title: 'Proyecto', field: 'proyecto' },
                  { title: "Diplomado", field: "diplomado" },
                  { title: 'Si Labora', field: 'si_labora' },
                  { title: 'No Labora', field: 'no_labora' },
                  { title: 'Estudiantes Por Ubicar', field: 'eubicar' },
                  { title: 'Tiempo Colocación', field: 'tiempo_colocacion' },
               )
            }else if(codigo === 'Inf_Mod'){
               columnas.push(
                  { title: 'EMPRESARIAL', field: 'empresarial' },
                  { title: 'INVESTIGATIVA', field: 'investigativa' },
                  { title: 'EMPRENDIMIENTO', field: 'emprendimiento' },
                  { title: 'PROYECTO', field: 'proyecto' },
               )
            }else if(codigo === 'Inf_Pro'){
               Total = false
                  let a = {
                     'programa_academico':'Programa Académico',
                     'estudiantes':'Estudiantes matriculados',
                     'validaciones':'Estudiantes Validaciones de Competencias',
                     'empresarial':'Estudiantes Práctica Empresarial',
                     'investigativa':'Estudiantes Práctica Investigativa',
                     'emprendimiento':'Estudiantes Práctica de Emprendimiento',
                     'proyecto':'Estudiantes Práctica por Proyecto',
                     'diplomado': "Estudiantes Práctica por Diplomado",
                     'colocados':'Estudiantes Colocados',
                     'nocolocados':'Estudiantes No Colocados',
                     'porc_def':'% Notas Deficientes',
                     'porc_ace':'% Notas Aceptables',
                     'porc_bue':'% Notas Buenas',
                     'porc_exc':'% Notas Excelentes',
                  }
      
                  let data = []
      
                  columnas = [{ field: 'nombre', title: 'Nombre' }, { field: 'valor', title: ''}]
                  let info = data_informe[0]
                  info.estudiantes+=info.validaciones
                  Object.entries(info).map((inf) => { if(inf[0]!='codigo') data.push({ 'nombre': a[inf[0]], 'valor': inf[1]}) } )
      
                  data_informe=data
      
      
            }else if(codigo === 'inf_din'){
               let { camposMostrar, } = this.state
               ordenar=true

               columnas = [
                  {title: "Programa académico",field: "programa_academico",orden: 1,},
      
                  {title: "Periodos", field: "periodo", orden: 2 },
                ];
                

                let modalidades_m = [
                  {
                    title: "Empresarial",
                    field: "empresarial",
                    orden: 9,
                    codigo: "Mod_Emp_Pra",
                  },
                  {
                    title: "Investigativa",
                    field: "investigativa",
                    orden: 10,
                    codigo: "Mod_Inv_Pra",
                  },
                  {
                    title: "Emprendimiento",
                    field: "emprendimiento",
                    orden: 11,
                    codigo: "Mod_Emt_Pra",
                  },
                  {
                    title: "Proyecto",
                    field: "proyecto",
                    orden: 12,
                    codigo: "Mod_Epr_Pra",
                  },
                  {
                    title: "Diplomado",
                    field: "diplomado",
                    orden: 13,
                    codigo: "Mod_Dip_Pra",
                  },
                ];

               let dinamicos = [

                  { title: 'Matriculados', field: 'matriculados', orden: 3 },

                  { title: 'N° Validación por competencia', field: 'nreconocimiento', orden: 4 },
                  { title: 'Total estudiantes sin incluir validación', field: 'nsinreconocimineto', orden: 5 },
                  { title: 'Diplomado', field: 'diplomado', orden: 15 },
                  { title: 'Total estudiantes sin diplomado', field: 'nsindiplomado', orden: 10 },
                  { title: 'N° Estudiantes colocados (Si)', field: 'ncolocados', orden: 6 },
                  { title: 'Tiempo ubicación', field: 'tiempoubicacion', orden: 7 },
                  { title: 'Ubicación por estudiante', field: 'ubicacionestudiante', orden: 8 },

                  { title: 'Grado de satisfacción', field: 'gradosatisfacion', orden: 13 },
               
               ]
            
               const completo = [ 'matriculados', 'nreconocimiento', 'nsinreconocimineto', 'nsindiplomado','diplomado','ncolocados', 'tiempoubicacion', 'ubicacionestudiante', 'gradosatisfacion' ]
               let no = completo.filter( (item) => camposMostrar.indexOf(item)===-1 )
      
               let si = []

               camposMostrar.map( (camp) => {
                  dinamicos.map( (dina)=>{
                     if(camp==dina.field) columnas.push(dina)
                  })
               })
               if (todoMulti.length > 0) {
                  todoMulti.map((camp) => {
                    modalidades_m.map((dina) => {
                      if (camp == dina.codigo) columnas.push(dina);
                    });
                  });
                } else if(todoMulti.length == 0){
                  modalidades_m.map((dina) => {
                  columnas.push(dina);
                })}
            }else if(codigo === 'Inf_Not'){
               columnas.push(
                  { title: 'Modalidad', field: 'modalidad' },
                  { title: 'Identificacion', field: 'identificacion' },
                  { title: 'Estudiante', field: 'estudiante' },
                  { title: 'Requisito', field: 'requisito_nombre' },
                  { title: 'Programa', field: 'programa' },
                  { title: "Calificacion", field: "calificacion_nombre" },
                  { title: 'Periodo', field: 'periodo_nombre' },
               )
            }else if(codigo === 'Inf_Eval'){
               columnas.push(
                  { title: 'Modalidad', field: 'modalidad' },
                  { title: 'Identificacion', field: 'identificacion' },
                  { title: 'Estudiante', field: 'estudiante' },
                  { title: 'Requisito', field: 'requisito_nombre' },
                  { title: 'Paso', field: 'paso_nombre' },
                  { title: 'Pregunta', field: 'pregunta_nombre' },
                  { title: 'Respuesta', field: 'respuesta' },
                  // { title: 'Periodo', field: 'periodo_nombre' },
               )
            }
            
         }

         const titulo_informe = (periodo) ? `${informe.nombre} - ${periodo}` : informe.nombre

         const acciones = (informe.codigo != 'Inf_Pro' && informe.codigo != 'Inf_Eval') ? [
            rowData => ((rowData.programa_academico!='TOTAL') ? {
               icon: forwardRef((props, ref) => <IconButton style={{ color: '#01579b' }} aria-label="delete"><ImportContactsIcon fontSize="small" /></IconButton>),
               tooltip: 'Detalles',
               onClick: (event, rowData) => {
                  this.obtenerMeses(periodo, rowData.codigo);
                  this.setState({ mostrar: 'filtroDetalle', programaDetalle: rowData, maxWidth: 'sm', estadoSolicitud: '', mes: '' })
               }
            } : null)
         ]: [];
   
         return (
            <ListarTabla titulo={titulo_informe} columnas={columnas} datos={data_informe} acciones={acciones} orden={ordenar} total={Total} />
         )
      }else{
         return(
            <TareasTerminadas mensaje='No se encontraron resultados' marginTop='7%' imagen={emma_s} widthImg="7%" />
         )
      }
   }

   pintarFiltroDetalle = () => {
      let { estadoSolicitud, meses, mes } = this.state
      if(mes!=='' && (estadoSolicitud!=1 && estadoSolicitud!=0)) this.setState({ mes: '' })
      return (
         <Grid container spacing={3} className='margin_cont'>
            <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
               <FormControl className='form-control'>
                  <InputLabel htmlFor="estadoSolicitud">Estado solicitud</InputLabel>
                  <Select
                     inputProps={{
                        name: 'estadoSolicitud',
                        id: 'estadoSolicitud',
                     }}
                     value={estadoSolicitud}
                     onChange={this.onChange}
                     required={true}>
                     <MenuItem value={null}>N/A</MenuItem>
                     <MenuItem value={-1}>Pendientes Por Ubicar</MenuItem>
                     <MenuItem value={2}>Sin Fecha Finalización</MenuItem>
                     <MenuItem value={0}>Pendientes Por Finalizar</MenuItem>
                     <MenuItem value={1}>Finalizados</MenuItem>
                  </Select>
               </FormControl>
            </Grid>
            { (estadoSolicitud==1 || estadoSolicitud==0) ? 
            <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
               <FormControl className='form-control'>
                  <InputLabel htmlFor="mes">Mes de Finalización</InputLabel>
                  <Select
                     inputProps={{
                        name: 'mes',
                        id: 'mes',
                     }}
                     value={mes}
                     onChange={this.onChange}
                     required={true}>
                     {meses.map(({ mes, mestext }, i) => <MenuItem key={i} value={mes}>{mestext}</MenuItem>)}
                  </Select>
               </FormControl>
            </Grid>
            :''
            }
         </Grid>

      )
   }

   pintarDetalle = () => {
      let { programaDetalle, practicas } = this.state
      const columns = [
         { title: 'Apellidos', field: 'apellidos' },
         { title: 'Nombres', field: 'nombres' },
         { title: 'N° Documento', field: 'identificacion' },
         { title: 'Modalidad', field: 'tipo' },
         { title: 'Estado', field: 'estado_actual' },
         { title: 'Requiere Ubicación', field: 'requiere_ubicacion' },
         { title: 'Empresa', field: 'nombre_empresa' },
         { title: 'Tipo Contrato', field: 'contrato' },
         { title: 'Fecha Inicio', field: 'fecha_inicio' },
         { title: 'Mes Finalización', field: 'mes_finalizacion' },
         { title: 'Duración (Meses)', field: 'duracion' },
         { title: 'Nota', field: 'estado_nota' },
      ]
      const filtro = () => this.setState({ practicas: [], mostrar: 'filtroDetalle', maxWidth: 'sm' })

      return(
         <ListarTabla 
            titulo={`${programaDetalle.programa_academico} - Mostrando ${practicas.length} registros`} 
            columnas={columns} 
            datos={practicas} 
            filtrar={true} 
            actFiltrar={filtro}
         />
      )
   }

   render() {
      let { cargando, maxWidth, mostrar } = this.state;
      let { modalInforme, mostrarModalInforme, informe } = this.props
      return (
         <Dialog fullWidth={true} maxWidth={maxWidth} PaperProps={ (informe.codigo === 'Inf_Pro') ? {style: {overflowY: 'visible'}} : {} } open={modalInforme} onClose={() => mostrarModalInforme(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <AppBarModal titulo={informe.nombre} mostrarModal={() => mostrarModalInforme(false)} titulo_accion={  (mostrar === 'filtros') ? "Limpiar" : 'Volver'} accion={ this.resetState } />
            <DialogContent className='scroll' style={mostrar === 'informe' ? { padding: 0 } : ((informe.codigo === 'Inf_Pro') ? { overflowY: 'visible' }:{})}>
               {!cargando ? this.pintar() : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />}
            </DialogContent>
            <DialogActions>
               <BtnForm texto="Cerrar" callback={() => mostrarModalInforme(false)} />
               {!cargando && mostrar === 'filtros' && <BtnForm texto="GENERAR" callback={this.onSubmit} />}
               {!cargando && mostrar === 'filtroDetalle' && <BtnForm texto="FILTRAR" callback={this.obtenerPracticas} />}
            </DialogActions>
         </Dialog>
      );
   }
}

export default PracticaInforme