import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {
  AppBar,
  Avatar,
  Badge,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  Hidden,
  Icon,
  IconButton,
  List,
  Toolbar,
  Tooltip,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  Link,
  CardActionArea,
  CardMedia
} from '@material-ui/core/';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Copyright from './Copyright';
import ConfirmarAccion from '../ConfirmarAccion';
import Mensajes from './Mensajes';
import Cargando from './Cargando';
import AppBarModal from './../AppBarModal';
import { BtnForm } from './../BotonesAccion';
import {
  RutasPrincipales,
  RutasInicio,
  menuPrincipal,
  menuSecundario,
} from './Rutas';
import {
  actualizarAuth,
  actualizarDataUsuarioAuth,
  mostrarModalCerrar,
  actualizarActividades,
  actualizarMensaje,
  mostrarModalMensajes,
  actualizarCargandoMenu,
  mostrarModalNotificaciones,
  mostrarModalRegistrar,
  mostrarModalVideos
} from "../../../redux/actions/actGlobal";
import {
  validarUsuarioApp,
  CerrarApp,
  obtenerActividadesPerfil,
  obtenerValoresFiltros,
  consulta,
  api,
  mostrarError,
  getFotoMs
} from "../../../global/js/funciones";
import emma_logo from "../../../global/imagenes/emma_logo.svg";
import Perfil from './Perfil';
import Preguntas from "../../preguntas/Preguntas"
import Videos from './Videos';
//import Movilidad from '../../movilidades/Movilidad';
import { coloresEmma } from '../../../global/js/funciones';

const drawerWidth = 270;
const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: coloresEmma.secondarycolor,
    color: 'white'
  },
  avatar: {
    margin: 0,
    width: 30,
    height: 30,
  },
  avatar_text: {
    margin: 0,
    width: 50,
    height: 50,
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'Hidden',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  root: {
    display: 'flex',
  },
  rootCard: {
    maxWidth: 345,
  },
  logo: {
    width: "80%",
  },
  logocuc: {
    marginTop: "5%",
    width: 70,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 50,
    textTransform: "uppercase",
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
}));

function Modulo({ modulo, cerrarSesion, modalCerrar, mostrarModalCerrar, usuario, actividades, mensajes, mostrarModalMensajes, mostrarModalNotificaciones, modalNotificaciones, notificationes, guardarConfirmacion, mostrarModalVideos, modalVideos }) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const drawer = (
    <div style={{ height: "100%", overflow: 'auto' }} className='scroll'>
      <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 10 }}>
        <img src={emma_logo} alt="Logo emma_logo" className={classes.logo} />
      </Grid>
      <br />
      <List>
        {menuPrincipal(modulo.ruta, actividades)}
      </List>
      <Divider />
      <List>
        {menuSecundario(mostrarModalCerrar)}
      </List>
    </div>
  );

  const cardNoti = (imagen, nombre, descripcion) => (
    <Card>
      <CardActionArea>
        <CardMedia //CARD AQUI IMG
          component="img"
          alt="Imagen Notificacion"
          height="150"
          image={imagen}
          title="Imagen Notificacion"
        />
        <CardContent>
          <Typography align='left' gutterBottom variant="h5" component="h2">
            {nombre}
          </Typography>
          <Typography align='left' variant="body2" color="textSecondary" component="p">
            {descripcion}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <Toolbar >
          <IconButton
            className={classes.color_amr}
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <Icon>menu</Icon>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {`${modulo.nombre}`}
          </Typography>
          <Tooltip title="Vídeos" aria-label="add">
            <IconButton color="inherit" className={classes.color_amr} onClick={() => mostrarModalVideos(true)}>
              <Badge badgeContent={0} color="secondary">
                <Icon>video_library</Icon>
              </Badge>
            </IconButton>
          </Tooltip>
          {/* <Tooltip title="Notificaciones" aria-label="add">
            <IconButton color="inherit" className={classes.color_amr}>
              <Badge badgeContent={0} color="secondary">
                <Icon>notifications</Icon>
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip title="Mensajes" aria-label="add" className='tiembla'>
            <IconButton color="inherit" className={classes.color_amr} onClick={() => mostrarModalMensajes(true)}>
              <Badge badgeContent={mensajes ? mensajes.length : 0} color="secondary">
                <Icon>email</Icon>
              </Badge>
            </IconButton>
          </Tooltip> */}
          <Perfil perfiles={[]} usuario={usuario} cerrarSesion={mostrarModalCerrar} />
        </Toolbar>
      </AppBar>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}

        >
          {drawer}
        </Drawer>
      </Hidden>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div id="seccion-vista">
          {/* {RutasPrincipales(actividades)} */}
          {/* <Mensajes /> */}
          {
            modalVideos &&
          <Videos modalVideos={modalVideos} mostrarModalVideos={mostrarModalVideos} />
          }
          <RutasPrincipales actividades={actividades} />
          <Copyright />
        </div>
        <ConfirmarAccion mensaje1={`Se cerrará la sesión actual, `} ejecutarAccion={cerrarSesion} mostrarModalConfAccion={mostrarModalCerrar} modalConAccion={modalCerrar} dato={{ id: 0 }} titulo={'¿ Cerrar Sesión ?'} />
      </main>

      <Dialog open={modalNotificaciones} maxWidth="md" fullWidth={true} onClose={false} >
        <AppBarModal titulo='¡ Notificaciones !' mostrarModal={mostrarModalNotificaciones} titulo_accion='' />
        <DialogContent className='scroll text-center'>
          <Grid container direction={'column'} spacing={3} style={{ marginTop: 10, marginBottom: 10 }} alignItems="center">
            {notificationes.length != 0 &&
              notificationes.map(({ id, nombre, descripcion, valorc, archivo }) => (
                <Grid item xs={11} sm={11} md={11} lg={11} key={id}>
                  <Paper elevation={1}>
                    {valorc ?
                      <Link
                        href={valorc}
                        target="_blank"
                        underline='none'
                        className="pointer">
                          {cardNoti(api + archivo, nombre, descripcion)}
                      </Link>
                      : 
                      cardNoti(api + archivo, nombre, descripcion)
                    }
                  </Paper>
                </Grid>
              ))
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Aceptar" callback={() => guardarConfirmacion()} />
        </DialogActions>
      </Dialog>

    </div>
  );
}

class Principal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      render: false,
      notificationes: [],
      correoV: '',
      identificacion: '',
      urlParams:'',
      codeVerifier:'',
      sessionState:''
    }
  }
  componentDidMount() { 
    let { urlParams, sessionState,codeVerifier } = this.state;
    urlParams = new URLSearchParams(window.location.search);
    sessionState = urlParams.get('code');
    codeVerifier = localStorage.getItem('code_verifier');
    if (sessionState) this.handleAuth(sessionState,codeVerifier);
    else this.isAuth();
  }
  componentDidUpdate({ auth}, { notificationes, sessionState }) {
    let { notificationes: notificationesNew, sessionState: sessionStateNew } = this.state;
    let { auth: authNew, mostrarModalNotificaciones } = this.props;
    if (authNew !== auth) {
      this.isAuth();
    }
    if (notificationesNew.length != notificationes.length && notificationesNew.length != 0) {
      mostrarModalNotificaciones(true)
    }
  }

  changeState = (render) => {
    this.setState({
      render,
    })
  }

  onChange = ({ target }) => {// evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  handleAuth = async (sessionState,codeVerifier) => {
    try {
      if (sessionState) {
        const requestOptions = {
          method: 'POST',
          cache: 'no-cache',
          mode: 'cors',
          body: JSON.stringify({ sessionState: sessionState, code_verifier: codeVerifier }),
          headers: {
            'Content-Type': 'application/json'
          }
        };
        try {
          const response = await fetch(`${api}/api/v1.0/microsoft/token/validate`, requestOptions);
          if (!response.ok) {
            throw new Error(`Error al realizar la solicitud: ${response.statusText}`);
          }
          const resp = await response.json();
          if (resp.token) {
            localStorage.setItem('token', resp.token);
            getFotoMs().then(function (data) {
              if (data.estado) {
                localStorage.setItem('fotoMs', data.data.foto);
                window.location.href = '/';
              }
            });
          } else {
            console.error("Error: No se recibió el token en la respuesta del servidor.");
          }
        } catch (error) {
          console.error("Error al realizar la solicitud:", error);
        }
      }
    } catch (error) {
      console.error('Error al procesar la autenticación:', error);
    }
  }

  isAuth = async () => {
    let { actualizarAuth, actualizarDataUsuarioAuth, actualizarActividades, actualizarCargandoMenu } = this.props;
    actualizarCargandoMenu(true)
    let { estado, resp } = await validarUsuarioApp();
    if (estado === 200) {
      let actividades = await obtenerActividadesPerfil(resp.id);
      // let notificationes = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 1 }, { 'llave': 'estado', 'valor': '1' }, { 'llave': 'valora', 'valor': 'si' }]);
      consulta(
        'api/v1.0/poll/GetNotifications',
        null,
        'get',
        (err, status, data) => {
            this.setState({ notificationes: status === 200 && !err ? data : [] })
        }
      )
      actualizarActividades(actividades);
      actualizarDataUsuarioAuth(resp);
      actualizarAuth(true);
    } else {
      actualizarAuth(false);
      this.setState({ notificationes: [] })
    }
    actualizarCargandoMenu(false)
    this.changeState(true);
  }

  cerrarSesion = async () => {
    let { mostrarModalCerrar } = this.props;
    mostrarModalCerrar(false);
    let resp = await CerrarApp();
    if (resp) {
      this.setState({ urlParams: '', codeVerifier: '', sessionState: '' });
      this.isAuth();
    }
  };

  guardarConfirmacion = async () => {
    let { mostrarModalNotificaciones, actualizarMensaje } = this.props
    let { notificationes } = this.state
    let array_encuestas = notificationes.filter(x => x.valore != 'confirm_ms').map(x => x.id)
    consulta(`api/v1.0/poll/confirmar`, {'encuestas': array_encuestas}, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalNotificaciones(false)
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    }
    )
  }

  render() {
    let { modulo, auth, modalCerrar, mostrarModalCerrar, usuario, actividades, mensajes, mostrarModalMensajes, mostrarModalNotificaciones, modalNotificaciones, modalRegistrar, mostrarModalRegistrar, mostrarModalVideos, modalVideos } = this.props;
    let { render, notificationes, correoV, identificacion } = this.state;
    if (render) {
      if (auth) return (<Modulo mostrarModalMensajes={mostrarModalMensajes} mensajes={mensajes} changeProfile={this.changeProfile} modulo={modulo} cerrarSesion={this.cerrarSesion} modalCerrar={modalCerrar} mostrarModalCerrar={mostrarModalCerrar} usuario={usuario} actividades={actividades} mostrarModalNotificaciones={mostrarModalNotificaciones} modalNotificaciones={modalNotificaciones} notificationes={notificationes} guardarConfirmacion={this.guardarConfirmacion} mostrarModalVideos={mostrarModalVideos} modalVideos={modalVideos} />);
      else return (<RutasInicio modalRegistrar={modalRegistrar} mostrarModalRegistrar={mostrarModalRegistrar} correoV={correoV} identificacion={identificacion} onChange={this.onChange} />)
    } else return (<Cargando />)
  }
}

const mapStateToProps = state => {
  let { modulo, auth, modalCerrar, usuario, actividades, mensajes, modale, modalNotificaciones, modalRegistrar, modalVideos } = state.redGlobal;

  return {
    modulo,
    auth,
    modalCerrar,
    usuario,
    actividades,
    mensajes,
    modalNotificaciones,
    modalRegistrar,
    modalVideos
  }
};

const mapDispatchToProps = {
  actualizarAuth,
  actualizarDataUsuarioAuth,
  mostrarModalCerrar,
  actualizarActividades,
  actualizarMensaje,
  mostrarModalMensajes,
  actualizarCargandoMenu,
  mostrarModalNotificaciones,
  mostrarModalRegistrar,
  mostrarModalVideos
}

//Props del componente
Principal.propTypes = {
  //variables
  auth: PropTypes.bool.isRequired,
  modalCerrar: PropTypes.bool.isRequired,
  modulo: PropTypes.object.isRequired,
  usuario: PropTypes.object.isRequired,
  actualizarAuth: PropTypes.func.isRequired,
  actualizarDataUsuarioAuth: PropTypes.func.isRequired,
  mostrarModalCerrar: PropTypes.func.isRequired,
  actividades: PropTypes.array.isRequired,
  actualizarActividades: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  mostrarModalMensajes: PropTypes.func.isRequired,
  mostrarModalVideos: PropTypes.func.isRequired,
}
//Props del componente
Modulo.propTypes = {
  //variables
  modulo: PropTypes.object.isRequired,
  modalCerrar: PropTypes.bool.isRequired,
  usuario: PropTypes.object.isRequired,
  mostrarModalCerrar: PropTypes.func.isRequired,
  cerrarSesion: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Principal);