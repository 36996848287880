import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { DialogActions, Grid, Dialog, DialogContent, TextField, Button, Table, TableBody, TableCell, TableRow, InputLabel, Select, MenuItem, FormControl, TableHead, FormGroup, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { List, ListItem, ListItemIcon, ListItemText, Checkbox, ListSubheader } from '@material-ui/core';
import { BtnForm, BtnEditar, BtnEliminar } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal';
import BuscarPersona from '../../general/BuscarPersona';
import CustomDropzone from "../../general/CustomDropzone";
import { MenuGestionar } from "./MenuPracticas";
import { consulta, generarFiltros, obtenerEstadosProceso, obtenerValoresFiltros, mostrarError, obtenerValores } from "../../../global/js/funciones";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment'
import emma_w from '../../../global/imagenes/emma_w.png';
import TareasTerminadas from '../../general/TareasTerminadas';
import PracticaCalendarioVisitas from "./PracticaCalendarioVisitas";
import { HelpOutlineOutlined } from '@material-ui/icons';
import { PERIODO_PRACTICAS, ARLS } from '../helper';
import AlertasSimple from '../../general/AlertasSimple';
import FestivosColombia from '../../general/festivos_colombia/FestivosColombia';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));


function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
  const classes = useStyles();
  return (
    <List className={classes.root} subheader={<ListSubheader component="div">Seleccione Estado</ListSubheader>}>
      {estados_siguiente.map(({ secundario }) => {
        let { codigo, nombre } = secundario;
        return (
          <ListItem key={codigo} role={undefined} dense button onClick={() => cambiarSeleccion(secundario)}>
            <ListItemIcon>
              <Checkbox edge="start" checked={estado === codigo} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={codigo} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  );
}

class PracticasGestionar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      estado: {
        codigo: '',
        valora: -1,
      },
      mensaje: '',
      nota70: '',
      nota30:'',
      notafinal:'',
      fecha_visita: null,
      horario_salida: null,
      horario_entrada: null,
      buscarTutor: false,
      cargando: false,
      notas: [],
      tutor: {
        id: 0,
        nombre_completo: 'Clic aquí para buscar',
      },
      seleccion: 'gestion',
      titulo_car: 'Cargando..',
      cargar_visitas: true,
      estados_siguiente : [],
      visitas : [],
      archivos_adicional: [],
      periodos: [],
      periodo: '',
      cargar_induccion: true,
      inducciones: [],
      enlace: '',
      observaciones: '',
      editarI: false,
      itemEditar: [],
      listaARL: [],
      ARL: '',
      requiereReunion: false,
    }
  }

  // async componentDidMount(){
  //   let { practica } = this.props;
  
  //   console.log('imprimiendo practica',practica)
  //   if (practica && practica.estado_actual.codigo === 'Est_Pra_Vif') {
  //     this.setState({ cargando: true });
  //     let notas = await this.obtenerAdjuntos();
  //     this.calcularNota(notas);
  //     this.setState({ notas, cargando: false });
  //   }
  // }

  async componentDidUpdate({ modalGestionarPractica }, { estado, seleccion }) {
    let { modalGestionarPractica: modalGestionarPracticaNew, practica } = this.props;
    let { estado: estadoNew, seleccion: seleccionNew } = this.state;
    if (estado !== estadoNew) {
      if (practica && estadoNew.codigo === 'Est_Pra_Fin' ) {
        this.setState({ cargando: true });
        let notas = await this.obtenerAdjuntos();
        this.calcularNota(notas);
        this.setState({ notas, cargando: false });
      }

      if(practica && ((estadoNew.codigo === 'Est_Pra_Ini' && (practica.tipo.valora === 'modalidad' || practica.tipo.valora === 'diplomado' || practica.tipo.valora === 'diplomado_uab')) || (estadoNew.codigo === 'Est_Pra_Rev' && (practica.tipo.valora === 'reconocimiento' || practica.tipo.codigo === 'Mod_Epr_Pra')))){
        this.setState({ cargando: true })
        let periodos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PERIODO_PRACTICAS }, { 'llave': 'estado', 'valor': 1 }])
        this.setState({ periodos, periodo: practica.periodo, cargando: false })
      }

      if (estadoNew.codigo=='Est_Pra_Arl'){
        this.obtenerARL();
      }
    }
    if (modalGestionarPractica !== modalGestionarPracticaNew) {
      if (modalGestionarPracticaNew) {
        this.changeState(
          {
            estado: {
              codigo: '',
              valora: -1,
            },
            mensaje: '',
            nota70: '',
            nota30:'',
            notafinal:'',
            fecha_visita: null,
            buscarTutor: false,
            horario_salida: null,
            horario_entrada: null,
            cargando: true,
            tutor: {
              id: 0,
              nombre_completo: 'Clic aquí para buscar',
            },
            seleccion: 'gestion',
            titulo_car: 'Cargando..',    
            estados_siguiente : [],
            cargar_visitas: true,
            visitas : [],
            archivos_adicional: [],
            periodo: '',
            cargar_induccion: true,
            inducciones: [],
            enlace: '',
            observaciones: '',
            editarI: false,
            itemEditar: [],
            listaARL: [],
            ARL: ''
          }
        );
        let estados_siguiente = await obtenerEstadosProceso(practica.tipo.id, [{ 'llave': 'principal', 'valor': practica.estado_actual.id }, { 'llave': 'secundario__generica', 'valor': 4 }]);
        this.setState({ cargando: false, estados_siguiente });
      }
    }

    if (seleccionNew !== seleccion && this.state.cargar_visitas) {
      if (seleccionNew === 'visitas') {
        this.obtenerEstados();
        this.setState({cargar_visitas: false})
      }
    }
  }

  deshabilitarDias = (date) => {
    const festivosColombia = new FestivosColombia();
    const festivos = festivosColombia.obtenerListaFestivos();
    
    return festivos.some(
      (festivo) => festivo.toDateString() === date.toDateString()
    );
  };

  async obtenerEstados() {
    let { id_coord } = this.props
    this.setState({ cargando: true });
    let f = await generarFiltros([{'llave': 'tipo_estado__valora', 'valor' : '1'}, {'llave' : 'usuario_registro', 'valor': id_coord }, {'llave' : 'limite', 'valor': moment().subtract(1, 'M').format('YYYY-MM-DD')}, {'llave': 'cancelado', 'valor': '0'}]);
    consulta(`api/v1.0/practicas/estados?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, visitas: resp });
    })
  }

  async obtenerARL(){
    const listaArlDB = await obtenerValores(ARLS);
    this.setState({ listaARL: listaArlDB });
  }

  calcularNota(notas) {
    let suma = 0;
    notas.map(({ calificacion }) => suma = suma + calificacion);
    if (suma) {
      let nota70 = suma / notas.length;
      this.setState({ nota70 })
    }
  }

  obtenerAdjuntos = async () => {
    let { practica } = this.props;
    let filtros = [{ 'llave': 'estado_pr__practica__id', 'valor': practica.id }, { 'llave': 'con_calificacion', 'valor': false }, {'llave': 'validacion', 'valor': "2" }];
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`api/v1.0/practicas/adjuntos?${f}`, null, null,
        (error, estado, resp) => {
          resolve(resp);
        }
      )
    })
  }

  changeState = (estados) => {
    this.setState(estados)
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  setHorarioSalida = (horario_salida) => {
    this.setState({
      horario_salida
    })
  }
  setHorarioEntrada = (horario_entrada) => {
    this.setState({
      horario_entrada
    })
  }

  masivo = () => {
    let { seleccionMasiva, gestionarEstadoMasivos } = this.props;
    let { estado, mensaje, archivos_adicional, inducciones } = this.state;
    this.setState({ cargando: true, titulo_car: 'Enviando..' });

    inducciones.map((item) => {
      item[0]=moment(item[0]).format('YYYY-MM-DD')
      item[1]=moment(item[1]).format('HH:mm:ss')
      item[2]=moment(item[2]).format('HH:mm:ss')
    })
    
    seleccionMasiva = JSON.stringify(seleccionMasiva)
    inducciones = JSON.stringify(inducciones)
    let data = { seleccionMasiva, estado : estado.codigo, mensaje, archivos_adicional, inducciones, total: archivos_adicional.length }

    gestionarEstadoMasivos(data, () => this.setState({ cargando: false, titulo_res: 'Cargando..', inducciones: [] }));
  }

  onSubmit = e => {
    let { practica } = this.props;
    let { estado, mensaje, fecha_visita, nota70,nota30,notafinal, tutor, horario_entrada, horario_salida, archivos_adicional, periodo, inducciones, ARL, requiereReunion } = this.state;
    if(nota30!=='' && estado.codigo==='Est_Pra_Fin' && (practica.tipo.codigo!=='Mod_Rci_Pra' && practica.tipo.codigo!=='Mod_Rce_Pra' && practica.tipo.codigo!=='Mod_Cli_Pre')){
      this.setState({ cargando: true, titulo_car: 'Enviando..' });
    inducciones.map((item, key) => {
      item[0]=moment(item[0]).format('YYYY-MM-DD')
      item[1]=moment(item[1]).format('HH:mm:ss')
      item[2]=moment(item[2]).format('HH:mm:ss')
    })
    inducciones = JSON.stringify(inducciones);
    fecha_visita = fecha_visita ? moment(fecha_visita).format('YYYY-MM-DD') : '';
    horario_entrada = horario_entrada ? moment(horario_entrada).format('HH:mm:ss') : '';
    horario_salida = horario_salida ? moment(horario_salida).format('HH:mm:ss') : '';
    nota70=Number(nota70)
    nota30=Number(nota30)
    notafinal=(nota70*0.70)+(nota30*0.30)
    notafinal = notafinal.toFixed(2);
    
    let data = { estado : estado.codigo, mensaje, notafinal, fecha_visita, tutor: tutor.id, horario_entrada, horario_salida, periodo, archivos_adicional, total: archivos_adicional.length, ARL, inducciones,nota70:nota70,nota30:nota30 }
    this.props.gestionarPractica(practica.id, data, () => this.setState({ cargando: false, titulo_res: 'Cargando..', inducciones: [] }));
    e.preventDefault();
    }else if(notafinal!==''&& estado.codigo==='Est_Pra_Fin' && (practica.tipo.codigo==='Mod_Rci_Pra' || practica.tipo.codigo==='Mod_Rce_Pra' || practica.tipo.codigo==='Mod_Cli_Pre' || practica.tipo.codigo==='Mod_Rcem_Pra' || practica.tipo.codigo==='Mod_Rpro_Pra')){
      this.setState({ cargando: true, titulo_car: 'Enviando..' });

      inducciones.map((item, key) => {
        item[0]=moment(item[0]).format('YYYY-MM-DD')
        item[1]=moment(item[1]).format('HH:mm:ss')
        item[2]=moment(item[2]).format('HH:mm:ss')
      })
      inducciones = JSON.stringify(inducciones);
      fecha_visita = fecha_visita ? moment(fecha_visita).format('YYYY-MM-DD') : '';
      horario_entrada = horario_entrada ? moment(horario_entrada).format('HH:mm:ss') : '';
      horario_salida = horario_salida ? moment(horario_salida).format('HH:mm:ss') : '';
      let data = { estado : estado.codigo, mensaje, notafinal, fecha_visita, tutor: tutor.id, horario_entrada, horario_salida, periodo, archivos_adicional, total: archivos_adicional.length, ARL, inducciones }
      this.props.gestionarPractica(practica.id, data, () => this.setState({ cargando: false, titulo_res: 'Cargando..', inducciones: [] }));
      e.preventDefault();    
  }else if((nota30==='' || notafinal==='') && estado.codigo==='Est_Pra_Fin'){
    this.props.actualizarMensaje({ titulo: `Ingrese la nota.`, tipo: 'info', mostrar: true, tiempo: 6000 })
  
  }else {
    this.setState({ cargando: true, titulo_car: 'Enviando..' });

    inducciones.map((item, key) => {
      item[0]=moment(item[0]).format('YYYY-MM-DD')
      item[1]=moment(item[1]).format('HH:mm:ss')
      item[2]=moment(item[2]).format('HH:mm:ss')
    })
    inducciones = JSON.stringify(inducciones);
    fecha_visita = fecha_visita ? moment(fecha_visita).format('YYYY-MM-DD') : '';
    horario_entrada = horario_entrada ? moment(horario_entrada).format('HH:mm:ss') : '';
    horario_salida = horario_salida ? moment(horario_salida).format('HH:mm:ss') : '';
    let data = { estado : estado.codigo, mensaje, notafinal, fecha_visita, tutor: tutor.id, horario_entrada, horario_salida, periodo, archivos_adicional, total: archivos_adicional.length, ARL, inducciones, requiereReunion }
    this.props.gestionarPractica(practica.id, data, () => this.setState({ cargando: false, titulo_res: 'Cargando..', inducciones: [] }));
    e.preventDefault();
  }
  }

  setFechaVisita = (fecha_visita) => {
    this.setState({
      fecha_visita
    })
  }

  handleCheckboxChange = (e) => {
    this.setState({ requiereReunion: e.target.checked });
  }

  renderInputs = ({ codigo, valora }, { tipo }) => {
    let { tutor, horario_entrada, horario_salida, fecha_visita, notas, periodo, periodos,nota70,notafinal} = this.state;
    if (parseInt(valora) === 1) {
      return (
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
              <Grid container>
                <KeyboardDatePicker
                  required={true}
                  style={{ marginTop: 3, width: '100%' }}
                  minDate={Date("YYYY-MM-dd")}
                  margin="normal"
                  label="Fecha Visita"
                  format="yyyy-MM-dd"
                  value={fecha_visita}
                  onChange={this.setFechaVisita}
                  shouldDisableDate={this.deshabilitarDias}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  minDateMessage={`La fecha debe ser mayor que la fecha actual.`}
                  invalidDateMessage='Formato Invalido'
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
              <Grid container>
                <KeyboardTimePicker
                  required={true}
                  style={{ marginTop: 3, width: '100%' }}
                  margin="normal"
                  label="Hora Entrada"
                  value={horario_entrada}
                  onChange={this.setHorarioEntrada}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  invalidDateMessage='Formato Invalido'
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
              <Grid container >
                <KeyboardTimePicker
                  required={true}
                  style={{ marginTop: 1, width: '100%' }}
                  margin="normal"
                  label="Hora Salida"
                  value={horario_salida}
                  onChange={this.setHorarioSalida}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  invalidDateMessage='Formato Invalido'
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormGroup>
              <FormControlLabel control={ <Checkbox 
              name={'requiere_reunion'} checked={this.state.requiereReunion} onChange={this.handleCheckboxChange} 
              /> } label={ <span> ¿Desea programar una reunión? </span> } />
            </FormGroup>
          </Grid>
        </Grid>
      );
    }else if(parseInt(valora) === 2){
      let { cargar_induccion, inducciones, editarI } = this.state;
      
      return (
        <Grid container spacing={1} alignItems="flex-end">
          <Button variant="contained" color="primary" style={{ color: 'white' }} startIcon={<AddIcon />} onClick={()=>this.ModalagregarInduccion()}>Nueva Inducción</Button>
          { inducciones.length>0 && 
          <Table>
              <TableHead>
                <TableRow>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Horas</TableCell>
                    <TableCell>Enlace</TableCell>
                    <TableCell colSpan={2}>Observaciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { inducciones.map((item, key) => (
                  <TableRow key={key}>
                    <TableCell>
                      { moment(item[0]).format('YYYY-MM-DD') }
                    </TableCell>
                    <TableCell>
                      { moment(item[1]).format('h:mm A') } a { moment(item[2]).format('h:mm A') }
                    </TableCell>
                    <TableCell>
                      { item[3] }
                    </TableCell>
                    <TableCell>
                      { item[4] }
                    </TableCell>
                    <TableCell>
                      <BtnEditar callback={() => { this.ModaleditarInduccion(item) } } />
                      <BtnEliminar callback={() => { this.eliminarInduccion(item) } } />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
          </Table>
          }
          <Dialog fullWidth={true} maxWidth="xs" open={cargar_induccion} onClose={() => this.setState({cargar_induccion : false})} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <AppBarModal titulo='¡ Agregar Inducción !' mostrarModal={() => this.setState({cargar_induccion : false})} titulo_accion="" accion="" />
            <DialogContent className='scroll'>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <Grid container>
                      <KeyboardDatePicker
                        required={true}
                        style={{ marginTop: 3, width: '100%' }}
                        minDate={Date("YYYY-MM-dd")}
                        margin="normal"
                        label="Fecha Inducción"
                        format="yyyy-MM-dd"
                        value={fecha_visita}
                        onChange={this.setFechaVisita}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        minDateMessage={`La fecha debe ser mayor que la fecha actual.`}
                        invalidDateMessage='Formato Invalido'
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <Grid container>
                      <KeyboardTimePicker
                        required={true}
                        style={{ marginTop: 3, width: '100%' }}
                        margin="normal"
                        label="Hora Inicial"
                        value={horario_entrada}
                        onChange={this.setHorarioEntrada}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        invalidDateMessage='Formato Invalido'
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <Grid container >
                      <KeyboardTimePicker
                        required={true}
                        style={{ marginTop: 1, width: '100%' }}
                        margin="normal"
                        label="Hora Final"
                        value={horario_salida}
                        onChange={this.setHorarioSalida}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        invalidDateMessage='Formato Invalido'
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField multiline value={this.state.enlace} onChange={this.onChange} name="enlace" id="enlace" label="Enlace" fullWidth />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField multiline value={this.state.observaciones} onChange={this.onChange} name="observaciones" id="observaciones" label="Observaciones" fullWidth />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <BtnForm texto="CANCELAR" callback={() => this.setState({cargar_induccion : false })} />
              { (!editarI) ?
              <BtnForm texto="AGREGAR" callback={() => this.agregarInduccion()} /> 
              : <BtnForm texto="GUARDAR" callback={() => this.editarInduccion()} />
              }
            </DialogActions>
          </Dialog >

        </Grid>
      )
    } else if(codigo==='Est_Pra_Arl'){
      let { listaARL, ARL } = this.state;
      return(
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl className='form-control'>
              <InputLabel id="demo-simple-select-label">Seleccione la ARL a la cual ha sido afiliado</InputLabel>
              <Select id="arl" value={ARL} onChange={(e) => this.changeState({ ARL: e.target.value })}  >
                {
                    listaARL.map(( item, key ) => {
                      return <MenuItem key={key} value={item.id}>{item.nombre}</MenuItem>
                    })
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )
    } else if (codigo === 'Est_Pra_Ini' && (tipo.codigo === 'Mod_Inv_Pra' || tipo.codigo === 'Mod_Epr_Pra' || tipo.codigo === 'Mod_Emt_Pra' )) {
      return (
        <Grid container spacing={1} alignItems="flex-end" onClick={() => this.setState({ buscarTutor: true })} className='pointer' style={{ marginBottom: '10px' }}>
          <Grid item xs={11} xl={11} sm={11} md={11} lg={11}>
            <TextField label="Tutor" disabled={true} type="text" value={tutor.nombre_completo} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={1} xl={1} sm={1} md={1} lg={1}>
            <SearchIcon style={{ color: '#c79e32' }} />
          </Grid>
        </Grid>
      )
    } else if (codigo === 'Est_Pra_Ini' && (tipo.valora === 'modalidad' || tipo.valora === 'diplomado' || tipo.valora === 'diplomado_uab' ) || codigo === 'Est_Pra_Rev' && (tipo.valora === 'reconocimiento' || tipo.codigo === 'Mod_Epr_Pra')){
      return (
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
            <FormControl className='form-control'>
              <InputLabel htmlFor="periodo">Seleccione el Periodo</InputLabel>
              <Select
                inputProps={{
                  name: 'periodo',
                  id: 'periodo',
                }}
                value={periodo}
                onChange={this.onChange}
                required={true}>
                {periodos.map(({ nombre }, i) => <MenuItem key={i} value={nombre}>{nombre}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid>)
    } else if (codigo === 'Est_Pra_Fin' && (tipo.codigo !=='Mod_Rci_Pra' && tipo.codigo !=='Mod_Rce_Pra' && tipo.codigo !=='Mod_Cli_Pre' && tipo.codigo !=='Mod_Rcem_Pra' && tipo.codigo !=='Mod_Rpro_Pra' )) {
      return (
      <>
      
        <AlertasSimple tipo='warnig' titulo='Nota 70%' sub_titulo={`Nota del 70% : ${nota70} `} conCallback={false} />
        <TextField type='number' value={this.state.nota30} disabled={this.props.practica.tipo.valorc === '1'} onChange={this.props.practica.tipo.valorc === '1' ? () => { } : this.onChange} name="nota30" id="nota30" label="Nota 30 %" fullWidth />
        
        {/* {(this.props.practica.tipo.valorc === '1' && notas.length > 0) &&
          <Grid container >
            <Grid item xs={12} md={12} className={`alert_info_titulo`}>
              <Button variant="text" size="small" color="inherit" >
                <NotificationImportantIcon style={{ height: 15 }} /> Calificaciones</Button>
            </Grid>
            <Grid item xs={12} md={12} className={`alert_info`}>
              <Table>
                <TableBody>
                  {
                    notas.map(({ id, calificacion, requisito }) => {
                      return (
                        <TableRow key={id}>
                          <TableCell component="th" scope="row">{requisito.nombre}</TableCell>
                          <TableCell align="left">{calificacion}</TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        } */}
      </>

      )
    } else if(codigo === 'Est_Pra_Fin' && (tipo.codigo==='Mod_Rci_Pra' || tipo.codigo==='Mod_Rce_Pra' || tipo.codigo==='Mod_Cli_Pre' || tipo.codigo==='Mod_Rcem_Pra' || tipo.codigo==='Mod_Rpro_Pra')){
      return (
        <TextField type='number' value={this.state.notafinal} disabled={this.props.practica.tipo.valorc === '1'} onChange={this.props.practica.tipo.valorc === '1' ? () => { } : this.onChange} name="notafinal" id="notafinal" label="Nota Final" fullWidth />
        )

    }else return ''
  }

  pintar = () => {
		let { seleccion } = this.state;
		return (
			<div>
				{seleccion === 'gestion' && this.pintarEstados()}
				{seleccion === 'visitas' && this.pintarVisitas()}
				{seleccion === 'documentos' && this.pintarAdjuntos()}
			</div>
		);
  }

  eliminarArchivo = (i) => {
    let { actualizarMensaje } = this.props;
    let { archivos_adicional } = this.state;
    archivos_adicional.splice(i, 1)
    this.setState({ archivos_adicional })
    actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
  }

  agregarArchivos = (arc) => {
    let { archivos_adicional } = this.state;
    archivos_adicional = archivos_adicional.concat(arc);
    this.setState({ archivos_adicional })
  }

  pintarAdjuntos() {
    return <CustomDropzone archivos={this.state.archivos_adicional} addFunction={this.agregarArchivos} deleteFunction={this.eliminarArchivo} actualizarMensaje={this.props.actualizarMensaje} />
  }
  
  pintarEstados = () => {
    let { practica } = this.props;
    let { estados_siguiente } = this.state;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          <RenderEstados cambiarSeleccion={(estado) => this.changeState({ estado })} estado={this.state.estado.codigo} estados_siguiente={estados_siguiente} />
        </Grid>
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          {(practica && practica.id > 0) && this.renderInputs(this.state.estado, practica)}
        </Grid>
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          { (parseInt(this.state.estado.valora)!=2 && this.state.estado.codigo!='Est_Pra_Arl') &&
          <TextField multiline value={this.state.mensaje} onChange={this.onChange} name="mensaje" id="mensaje" label="¿Desea Guardar un mensaje?" fullWidth />
          }
          </Grid>
      </Grid>
    )
  }

  resetInducciones = () =>{
    this.setState({ fecha_visita: null, horario_entrada: null, horario_salida: null, enlace: '', observaciones: '' });
  }

  validarInducciones = ({fecha_visita, horario_entrada, horario_salida}) =>{
    let { actualizarMensaje } = this.props;
    let titulo = ''

    if(!fecha_visita) titulo='Ingrese la fecha de inducción.'
    else if(!horario_entrada) titulo='Ingrese la hora inicial.'
    else if(!horario_salida) titulo='Ingrese la hora final.'
    else if(horario_entrada>horario_salida) titulo='La hora final no puede se menor que la hora final.'
    else{
      return true
    }

    if(titulo){
      actualizarMensaje({ titulo: titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
    }
  }

  ModalagregarInduccion = () =>{
    this.setState({ cargar_induccion: true, editarI: false });
    this.resetInducciones()
  }

  agregarInduccion = () => {
    let { fecha_visita, horario_entrada, horario_salida, inducciones, enlace, observaciones } = this.state;
    
    if(this.validarInducciones({fecha_visita, horario_entrada, horario_salida})){
      const nuevaInduccion = [fecha_visita, horario_entrada, horario_salida, enlace, observaciones]
      this.setState({cargar_induccion : false, inducciones: [...inducciones, nuevaInduccion ] });
      this.resetInducciones()
    }
  }

  eliminarInduccion = (ind) => {
    let {inducciones} = this.state;
    const arrayFiltrado = inducciones.filter(item => item !== ind)
    this.setState({ inducciones: arrayFiltrado })
  }

  ModaleditarInduccion = (ind) => {
    this.setState({ fecha_visita: ind[0], horario_entrada: ind[1], horario_salida: ind[2], enlace: ind[3], observaciones: ind[4], cargar_induccion: true, editarI: true, itemEditar: ind});
  }

  editarInduccion = () => {
    let { fecha_visita, horario_entrada, horario_salida, inducciones, enlace, observaciones, itemEditar } = this.state;

    if(this.validarInducciones({fecha_visita, horario_entrada, horario_salida})){
      const editInduccion = [fecha_visita, horario_entrada, horario_salida, enlace, observaciones]
      const array = inducciones.map(item => item==itemEditar ? editInduccion : item)
      this.setState({cargar_induccion : false, inducciones: array, itemEditar: [] });
      this.resetInducciones()
    }
  }

  pintarVisitas = () => {
    let { visitas } = this.state;
    const transformar = data => {
      return data.map(e => ({
        Id: e.id,
        Subject: e.tipo_estado.nombre,
        // StartTime: new Date(2021, 26, 2, 15, 0 ,0),
        // EndTime: new Date(2021, 26, 2, 18, 0 ,0)
        StartTime: e.fecha_visita + 'T' + e.horario_entrada,
        EndTime: e.fecha_visita + 'T' + e.horario_salida,
        Description: e.mensaje
      }));
    }
    return (
      <PracticaCalendarioVisitas datos={transformar(visitas)}></PracticaCalendarioVisitas>
    )
  }

  render() {
    let { modalGestionarPractica, mostrarModalGestionarPractica, practica, estadoSeleccion } = this.props;
    let { buscarTutor, cargando, seleccion, titulo_car } = this.state; 
    return (
      <div>
        <Dialog fullWidth={true} maxWidth="sm" open={modalGestionarPractica} onClose={mostrarModalGestionarPractica.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo={`¿ Gestionar Practica ? ${practica && practica.usuario_registro && !estadoSeleccion ? `${` - ${practica.usuario_registro.primer_apellido + ' ' + practica.usuario_registro.primer_nombre}`}` : ''} ${ (estadoSeleccion) ? ' - Acción Masiva' : '' }`} mostrarModal={mostrarModalGestionarPractica} titulo_accion="" accion="" />
          <MenuGestionar seleccionar={(seleccion) => this.setState({ seleccion })} sele={seleccion}	practica={practica}	/>
          <DialogContent style={{ padding: '1' }} className='scroll'>
            {
              !cargando ? this.pintar()
                : <TareasTerminadas imagen={emma_w} mensaje={titulo_car} widthImg='7%' marginTop='7%' />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModalGestionarPractica(false)} />
            { estadoSeleccion ?
              !cargando && <BtnForm texto="ACEPTAR" callback={this.masivo} />  
            :
              !cargando && <BtnForm texto="ACEPTAR" callback={this.onSubmit} />
            }
          </DialogActions>
        </Dialog>

        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={buscarTutor} onClose={() => this.setState({ buscarTutor: false })}>
          <AppBarModal titulo={'¡ Buscar Tutor !'} mostrarModal={() => this.setState({ buscarTutor: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarTutor: false })} />
          <DialogContent style={{ padding: '0' }} className='scroll'>
            < BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
              tutor: {
                id,
                nombre_completo: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
              }, buscarTutor: false
            })} />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarTutor: false })} />
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

//Props del componente
PracticasGestionar.propTypes = {
  //variables
  modalGestionarPractica: PropTypes.bool.isRequired,
  practica: PropTypes.object.isRequired,
  id_coord: PropTypes.number.isRequired,
  estadoGestionar: PropTypes.number.isRequired,
  //funciones
  mostrarModalGestionarPractica: PropTypes.func.isRequired,
  gestionarPractica: PropTypes.func.isRequired,
}


export default PracticasGestionar;
